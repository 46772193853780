import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Empty, Input, Menu } from "antd5";
import { escapeRegExp } from "lodash";

import { DetailsContent, DetailsHeader } from "components/app_layout/DetailsLayout";
import { PageHeader } from "components/app_layout/Typography";
import UserInitials from "components/comments/UserInitials";
import { AddNewListButton } from "components/user_lists/AddNewListButton";
import { numberSort, stringSort } from "lib/columnSort";
import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import SkeletonTable from "lib/core_components/SkeletonTable";
import { Table } from "lib/core_components/Table";
import { ColumnType } from "lib/core_components/Table/ColumnTypes";
import { GetRecordListsResponseResult } from "lib/generated/app-api";
import { useNoticeLists } from "lib/hooks/api/notices/lists/useNoticeLists";
import { EventNames, TrackingProvider, useTracking } from "lib/tracking";
import { EditListMenu } from "./EditListMenu";
import { generateNoticeSearchUrl } from "./utils";

import css from "./NoticeLists.module.scss";

const columns: ColumnType<GetRecordListsResponseResult>[] = [
  {
    title: "List name",
    dataIndex: "name",
    key: "name",
    sizeConfig: { small: 200, medium: 250, large: 400, xlarge: 450 },
    ellipsis: true,
    render: (_, list) => (
      <div className={css.nameCell}>
        <EllipsisTooltipTextLink
          fullText={list.name}
          linkText={<b>{list.name}</b>}
          linkProps={{ to: generateNoticeSearchUrl({ noticeLists: [list.id.toString()] }) }}
        />
        <EditListMenu list={list} />
      </div>
    ),
    sorter: stringSort((list) => list.name),
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Notices",
    dataIndex: "recordCount",
    key: "record_count",
    sizeConfig: { small: 80, medium: 80, large: 120, xlarge: 160 },
    render: (_, { recordCount }) => recordCount,
    sorter: numberSort((list) => list.recordCount),
    defaultSortOrder: "descend",
    sortDirections: ["descend", "ascend", "descend"],
  },
  // TODO: add once we have this functionality
  // {
  //   title: "Shared with",
  //   dataIndex: "visibility",
  //   key: "visibility",
  //   sizeConfig: { small: 200, medium: 250, large: 400, xlarge: 450 },
  // },
  {
    title: "Owner",
    key: "owner_name",
    render: (_, { user }) => (
      <div className={css.ownerCell}>
        <UserInitials firstName={user.firstName} lastName={user.lastName} /> {user.firstName}{" "}
        {user.lastName}
      </div>
    ),
    sorter: stringSort((list) => `${list.user.firstName} ${list.user.lastName}`),
  },
];

// Copy of BuyerLists.tsx and modified to fit the data structure needed
function NoticeLists() {
  const { data: lists, isLoading, isFetching, isError } = useNoticeLists();
  const [searchValue, setSearchValue] = React.useState("");
  const { logEvent } = useTracking();

  const [tab, setTab] = React.useState<"allNoticeLists" | "myNoticeLists">("allNoticeLists");

  const onTabChange = React.useCallback(
    (key: "allNoticeLists" | "myNoticeLists") => {
      logEvent(EventNames.tabChanged, {
        "Tab selected": key === "allNoticeLists" ? "All notice lists" : "My notice lists",
      });
      setTab(key);
    },
    [logEvent],
  );

  const displayedLists: GetRecordListsResponseResult[] = React.useMemo(() => {
    const seletedlists = tab === "allNoticeLists" ? lists?.allLists : lists?.userLists;
    return (
      seletedlists?.filter((list) => list.name.match(new RegExp(escapeRegExp(searchValue), "i"))) ||
      []
    );
  }, [lists?.allLists, lists?.userLists, searchValue, tab]);

  /**
   * Want to display the search box and menu tabs when loading or when data exists. Just so there's as little as possible
   * disruption when user is loading the screen most of the time. We only obfuscate these CTAs (if that's the right word for it)
   * when there is no data
   */
  const dataExists = React.useMemo(
    (): boolean => (lists && lists.allLists.length > 0) || isLoading,
    [isLoading, lists],
  );

  const displayedTable = React.useMemo(() => {
    if (isLoading) {
      return <SkeletonTable columns={columns} pageSize={10} active />;
    }

    return (
      <TrackingProvider data={{ "Context source": "In-row" }}>
        <Table
          columns={columns}
          loading={isFetching}
          isError={isError}
          dataSource={displayedLists}
          rowKey={(r) => r.id}
          locale={{
            emptyText: (
              <Empty className={css.emptyState}>
                <h2>No notice lists created yet</h2>
              </Empty>
            ),
          }}
          pagination={displayedLists.length < 20 ? false : undefined}
          onRow={(record, _) => {
            return {
              onClick: () => {
                logEvent(EventNames.noticeListClicked, {
                  "List type": "Notice list",
                  "List name": record.name,
                  "List ID": record.id,
                });
              },
            };
          }}
        />
      </TrackingProvider>
    );
  }, [displayedLists, isError, isFetching, isLoading, logEvent]);

  return (
    <>
      <DetailsHeader className={css.noticeListHeader}>
        <div className={css.titleAndBtn}>
          <PageHeader>Notice lists</PageHeader>
          <AddNewListButton modal />
        </div>
        {dataExists ? (
          <Menu
            mode="horizontal"
            selectedKeys={[tab]}
            className={css.menuTabs}
            items={[
              {
                key: "allNoticeLists",
                onClick: () => onTabChange("allNoticeLists"),
                className: css.menuTabItem,
                label: (
                  <span key="allNoticeLists">
                    All notice lists{" "}
                    {lists?.allLists.length !== undefined && `(${lists.allLists.length})`}
                  </span>
                ),
              },
              {
                key: "myNoticeLists",
                onClick: () => onTabChange("myNoticeLists"),
                className: css.menuTabItem,
                label: (
                  <span key="myNoticeLists">
                    My notice lists{" "}
                    {lists?.userLists.length !== undefined && `(${lists.userLists.length})`}
                  </span>
                ),
              },
            ]}
          />
        ) : (
          // Just having this padding for no data to make header look a bit cleaner when tabs above aren't displayed
          <span style={{ paddingBottom: "16px" }} />
        )}
      </DetailsHeader>

      <div className={css.searchContainer}>
        {dataExists && (
          <Input
            placeholder="Search lists"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className={css.searchInput}
            prefix={<SearchOutlined />}
            allowClear
          />
        )}
      </div>
      <DetailsContent>{displayedTable}</DetailsContent>
    </>
  );
}

export default NoticeLists;
