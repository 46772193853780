import React from "react";
import styled from "@emotion/styled";
import { User } from "@sentry/react";
import { Button, Mentions } from "antd5";
import { Flex, Text } from "styles/utility-components";

import TextButton from "components/actions/TextButton";
import UserInitials from "components/comments/UserInitials";
import { extractLinkRegions } from "lib/comment_utils";
import {
  TextHighlighterMatch,
  TextMatchHighlighter,
} from "lib/core_components/TextMatchHighlighter";
import { useUsers } from "lib/hooks/api/useUsers";
import { DateGranularity, toHumanRelativeTimeFromNow } from "lib/utils/relative_time";

type BasicComment = {
  id: string;
  content: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  author: {
    guid: string;
    firstName: string;
    lastName: string;
  };
};

type CommentComponentProps = {
  comment: BasicComment;
  user: User;
  enabledEdit: (id: string) => void;
  handleDelete: (id: string) => void;
  editCommentId: string;
  editedComment: string;
  setEditedComment: (comment: string) => void;
  handleUpdate: (id: string) => void;
  setEditCommentId: (id: string) => void;
};

export function CommentComponent({
  comment,
  user,
  enabledEdit,
  handleDelete,
  editCommentId,
  editedComment,
  setEditedComment,
  handleUpdate,
  setEditCommentId,
}: CommentComponentProps) {
  const { data: users } = useUsers();

  const userOptions = users?.map((user) => ({
    value: `${user.firstName} ${user.lastName}`,
    label: `${user.firstName} ${user.lastName}`,
  }));

  const parseContentForUserMentions = (content: string) => {
    const possibleMentions = users?.map((u) => {
      return { tag: `@${u.firstName} ${u.lastName}`, guid: u.guid };
    });

    let editableContent = content;

    /**
     * I've done the check this way round, because we can't consistently rely on @"FirstName LastName"
     * as a regex pattern to pull users from the content.
     *
     * We don't validate the user input, so it's possible for the first or last name to be multiple words.
     */
    possibleMentions?.forEach((mention) => {
      const re = new RegExp(mention.tag, "g");
      const match = content?.match(re);
      if (match) {
        editableContent = editableContent.replace(
          mention.tag,
          `@[${mention.tag}](${mention.guid})`,
        );
      }
    });

    let matches: TextHighlighterMatch[] = [];
    if (editableContent) {
      matches = extractLinkRegions(editableContent);
    }

    return <TextMatchHighlighter text={editableContent} matches={matches} newCommentStyle={true} />;
  };

  return (
    <Flex key={comment.id} flexDirection="row" gap="8px">
      <UserInitials firstName={comment.author.firstName} lastName={comment.author.lastName} />
      <Flex flexDirection="column" width="100%">
        <Flex flexDirection="row" width="100%">
          <Flex flexDirection="row" width="100%" gap="8px">
            <Text>
              {comment.author.firstName} {comment.author.lastName}
            </Text>
            {comment.createdAt && (
              <Text>{toHumanRelativeTimeFromNow(comment.createdAt, DateGranularity.second)}</Text>
            )}
          </Flex>
          {user.guid === comment.author.guid && (
            <CommentControls>
              <StyledTextButton onClick={() => enabledEdit(comment.id)} deleteButton={false}>
                Edit
              </StyledTextButton>
              <StyledTextButton onClick={() => handleDelete(comment.id)} deleteButton={true}>
                Delete
              </StyledTextButton>
            </CommentControls>
          )}
        </Flex>
        <Flex flexDirection="column" gap={"4px"}>
          {editCommentId === comment.id ? (
            <>
              <Mentions
                options={userOptions}
                placeholder="Edit comment"
                value={editedComment}
                onChange={(e) => setEditedComment(e)}
              />
              <Flex flexDirection="row" gap={"4px"}>
                <Button onClick={() => handleUpdate(comment.id)} type="primary">
                  Update
                </Button>
                <Button onClick={() => setEditCommentId("")}>Cancel</Button>
              </Flex>
            </>
          ) : (
            <Text>{parseContentForUserMentions(comment.content)}</Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

const CommentControls = styled.div({
  display: "flex",
  flexDirection: "row",
  marginLeft: "auto",
  gap: "8px",
});

const StyledTextButton = styled(TextButton)<{ deleteButton?: boolean }>(
  ({ deleteButton, theme }) => ({
    color: deleteButton ? theme.colors.sysDestructiveDefault : theme.colors.sysInfoDefault,
  }),
);
