import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { TreeSelect } from "antd5"; // good luck, might be easy though :)
import { CustomTagProps } from "rc-select/lib/BaseSelect";

import { findRegexMatches, SimpleMatchHighlighter } from "lib/core_components/TextMatchHighlighter";
import { cpvDimensions } from "lib/data/optionItems";
import { CpvCodeTreeSection } from "../../lib/StotlesApi";
import { useStotlesApi } from "../../lib/stotlesApiContext";
import { CpvDimension } from "../../lib/types/models";
import Tag from "../ui/tag/Tag";

import css from "./CpvCodeSelector.module.scss";

type Props = {
  inputId?: string;
  selectedCodes: string[];
  onChange(newCodes: string[]): void;
  placeholder?: React.ReactNode;
  disableTopLevelCodeCheck?: boolean;
  disableClear?: boolean;
};

type CPVCodeTreeData = {
  //cpv dimension
  id: string;
  pId: string | null; //parent cpv code
  value: string; //same as id
  isLeaf?: boolean;

  // The label to show in the selector ("CPV name - CPV code")
  label: string;

  // The react node that ant Tree will use, this is precalculated by us and might include match highlighting
  title: React.ReactNode;

  // As we need to check for text match when generating the highlighted label,
  // we cache the `match` flag in the node to reuse it in the filter function
  isMatch?: boolean;
  disableCheckbox?: boolean | undefined;
};

function getInitialTreeData(
  cpv_dimensions: CpvDimension[],
  disableTopLevelCodeCheck: boolean | undefined,
) {
  const data: TreeDataByCode = {};
  cpv_dimensions.forEach((value) => {
    const fullCode = `${value.code}000000`;
    const label = `${value.name} - ${fullCode}`;
    data[fullCode] = {
      id: value.code,
      pId: null,
      value: value.code,
      title: label,
      label: label,
      disableCheckbox: disableTopLevelCodeCheck,
    };
  });
  return data;
}

function populateCPVChildren(
  list: CPVCodeTreeData[],
  parentId: string,
  treeSelection: CpvCodeTreeSection,
) {
  const isLeaf = !treeSelection.children;
  const label = `${treeSelection.name} - ${treeSelection.code}`;
  list.push({
    id: treeSelection.code,
    pId: parentId,
    value: treeSelection.code,
    title: label,
    isLeaf: isLeaf,
    label: label,
  });

  if (treeSelection.children) {
    treeSelection.children.forEach((child) => {
      populateCPVChildren(list, treeSelection.code, child);
    });
  }
}

function genTreeNode(pId: string, rootSector: CpvCodeTreeSection): CPVCodeTreeData[] {
  const children: CPVCodeTreeData[] = [];

  rootSector.children?.forEach((child) => {
    populateCPVChildren(children, pId, child);
  });

  return children;
}

// Returns null if the search string is too short
function getFilterRegex(searchText: string): RegExp | null {
  if (searchText.match(/$[0-9]+^/)) {
    if (searchText.length < 2) {
      return null;
    }
    return new RegExp(`\\b(${searchText})`, "ig");
  }
  if (searchText.length < 3) {
    return null;
  }

  return new RegExp(`\\b(${searchText})`, "ig");
}

type TreeDataByCode = Record<string, CPVCodeTreeData>;

function CpvCodeSelector(props: Props): React.ReactElement {
  const { inputId, selectedCodes, onChange, placeholder, disableTopLevelCodeCheck } = props;

  const api = useStotlesApi();

  const [treeData, setTreeData] = React.useState<TreeDataByCode>(() => {
    return getInitialTreeData(cpvDimensions, disableTopLevelCodeCheck);
  });

  const [searchText, setSearchText] = React.useState("");

  const loadCpvTree = React.useCallback(
    async (ids: string[]) => {
      const value = await api.getCpvCodesBySector(ids);
      const newTreeData: TreeDataByCode = {};
      for (const t of value.tree) {
        for (const node of genTreeNode(t.sector_code, t)) {
          if (!newTreeData[node.value]) newTreeData[node.value] = node;
        }
      }
      setTreeData((oldTreeData) => ({ ...oldTreeData, ...newTreeData }));
    },
    [api],
  );

  React.useEffect(() => {
    void loadCpvTree(cpvDimensions.map((dim) => dim.code));
  }, [loadCpvTree]);

  const nodes = React.useMemo(() => {
    return Object.values(treeData).sort((a, b) =>
      (a.title as string).localeCompare(b.title as string),
    );
  }, [treeData]);

  const highlightedTreeData = React.useMemo(() => {
    const re = getFilterRegex(searchText);
    if (!re) {
      return nodes;
    }
    return nodes.map((td) => {
      const title = td.title as string;
      const matches = findRegexMatches(title, re);
      if (matches.length === 0) {
        return td;
      } else {
        return {
          ...td,
          title: <SimpleMatchHighlighter text={title} matches={matches} />,
          isMatch: true,
        };
      }
    });
  }, [nodes, searchText]);

  const TagRender = (tagProps: CustomTagProps) => {
    const label = tagProps.label;

    const handleClick = () => {
      onChange(selectedCodes.filter((value) => value !== tagProps.value));
    };

    return <Tag label={label} icon={<CloseOutlined />} onClick={handleClick} />;
  };

  return (
    <TreeSelect<string[]>
      id={inputId}
      className={css.select}
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      treeDataSimpleMode
      value={selectedCodes}
      placeholder={placeholder || "Select a CPV code"}
      onChange={onChange}
      searchValue={searchText}
      onSearch={(newSearchText: string) => {
        setSearchText(newSearchText);
      }}
      treeData={highlightedTreeData}
      treeCheckable
      filterTreeNode={(_, treeNode) => treeNode.isMatch}
      onDropdownVisibleChange={(open) => {
        if (!open) {
          setSearchText("");
        }
      }}
      autoClearSearchValue={false}
      multiple
      dropdownStyle={{ maxHeight: 400 }}
      allowClear={!props.disableClear}
      tagRender={TagRender}
      // This dropdownPopupAlign exists on all selects but ant has decided not to include it in
      // props because they didn't want to explain it in the docs...
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dropdownPopupAlign={{ overflow: { adjustY: 0, adjustX: 0 } }}
    />
  );
}

export default CpvCodeSelector;
