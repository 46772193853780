import React, { useEffect } from "react";
import { Button, Progress } from "antd5";

import { useUploadNoticeDocument } from "../../../lib/hooks/api/bid/useUploadNoticeDocument";

type FileUploadProps = {
  file: File;
  procurementStageId: string;
  onComplete: (fileName: string, isError: boolean, documentId?: string) => void;
};

export function FileUpload({ file, procurementStageId, onComplete }: FileUploadProps) {
  const { status, mutate, uploadProgress, isError, error } = useUploadNoticeDocument({
    onSettled: (data, error) => {
      onComplete(file.name, !!error, data?.documentId);
    },
  });

  useEffect(() => {
    if (status === "idle") {
      mutate({ file, procurementStageId });
    }
  }, [file, procurementStageId, status, mutate]);

  return (
    <div>
      <h4>
        {file.name} {error && ` - ${error.message}`}
      </h4>
      <Progress percent={uploadProgress} status={isError ? "exception" : undefined} />
      {isError && <Button onClick={() => mutate({ file, procurementStageId })}>Retry</Button>}
    </div>
  );
}
