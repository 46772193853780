import * as React from "react";
import { CloseOutlined, CopyOutlined } from "@ant-design/icons";
import { Button } from "antd5";

import { CandidateItem, CandidateListPanel } from "components/organisation_clean/OrgListPanel";
import {
  CandidateOperation,
  OrgWithStats,
  QualifiedCandidate,
} from "components/organisation_clean/types";
import { FilterType, OrgSearchFilters } from "components/organisation_clean/useOrgSearch";
import {
  SearchOrganisationsRequest,
  SearchOrganisationsRequest_PrimaryRoleEnum_1 as OrgPrimaryRole,
  SearchOrganisationsRequest_SortOrderEnum_1 as SortOrder,
} from "lib/generated/app-service-gql/graphql";
import {
  convertToOrgWithStats,
  useSearchOrganisations,
} from "lib/hooks/api/organisations/useSearchOrganisations";
import { useDialogManager } from "lib/providers/DialogManager";
import OperationDropdown from "./OperationDropdown";
import TextInputModal from "./TextInputModal";

import css from "./FindDuplicatesPage.module.scss";

type Props = {
  qualifiedCandidates: Record<string, QualifiedCandidate>;
  onCandidateChange: (org: OrgWithStats, qualification: CandidateOperation, note?: string) => void;
  anchorOrg: OrgWithStats;
  orgPrimaryRole: "Buyer" | "Supplier";
};

const DEFAULT_PAGINATION = { current: 1, pageSize: 500 };

function FindDuplicatesPage({
  qualifiedCandidates,
  onCandidateChange,
  anchorOrg,
  orgPrimaryRole,
}: Props): JSX.Element {
  const [filterState, setFilterState] = React.useState<FilterType>({
    textSearch: anchorOrg.name,
    countryCode: ["UK"],
    primaryRole: orgPrimaryRole,
  });

  const [filters, setFilters] = React.useState<SearchOrganisationsRequest>({
    textSearch: filterState.textSearch,
    primaryRole: OrgPrimaryRole[orgPrimaryRole],
    limit: DEFAULT_PAGINATION.pageSize,
    page: DEFAULT_PAGINATION.current,
    sortOrder: SortOrder.Asc,
  });

  React.useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      textSearch: filterState.textSearch,
    }));
  }, [filterState.textSearch]);

  const searchOrgsResult = useSearchOrganisations(
    {
      ...filters,
      page: DEFAULT_PAGINATION.current,
      limit: DEFAULT_PAGINATION.pageSize,
    },
    true,
    false,
  );

  const searchResults: OrgWithStats[] = React.useMemo(
    () => convertToOrgWithStats(searchOrgsResult),
    [searchOrgsResult],
  );

  const dialogManager = useDialogManager();
  const qualifiedList = React.useMemo(
    () => Object.values(qualifiedCandidates).map((c) => c.buyer),
    [qualifiedCandidates],
  );

  const filteredResultList = React.useMemo(() => {
    const selected = Object.keys(qualifiedCandidates);
    return searchResults.filter((r) => !selected.includes(r.guid) && r.guid !== anchorOrg.guid);
  }, [searchResults, qualifiedCandidates, anchorOrg]);

  const handleCandidateSelect = React.useCallback(
    (b: OrgWithStats, q: CandidateOperation) => {
      if (q === CandidateOperation.UNSURE) {
        void dialogManager.openDialog(TextInputModal, {
          onSubmit: (note: string) => {
            onCandidateChange(b, CandidateOperation.UNSURE, note);
          },
          modalTitle: `Mark ${b.name} as unsure`,
          title: 'Are you sure you want to mark this candidate as "Unsure"? ',
          description: "Please submit a note on why you're not sure",
        });
      } else {
        onCandidateChange(b, q);
      }
    },
    [onCandidateChange, dialogManager],
  );

  const renderQualificationButtons = React.useCallback(
    (b: OrgWithStats) => {
      return (
        <div className={css.qualificationButtons}>
          <Button
            danger
            size="small"
            onClick={() => handleCandidateSelect(b, CandidateOperation.NOT_RELATED)}
          >
            <CloseOutlined /> Anchor not mentioned
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => handleCandidateSelect(b, CandidateOperation.DUPLICATE)}
          >
            <CopyOutlined /> Duplicate of anchor
          </Button>
          {
            // For now, suppliers can only be direct duplicates; hierarchies are not supported yet
            orgPrimaryRole !== "Supplier" && (
              <Button
                size="small"
                onClick={() => handleCandidateSelect(b, CandidateOperation.MULTIPLE_ENTITIES)}
              >
                Contains anchor and others
              </Button>
            )
          }
          <Button
            className={css.unsureButton}
            size="small"
            onClick={() => handleCandidateSelect(b, CandidateOperation.UNSURE)}
          >
            Unsure
          </Button>
        </div>
      );
    },
    [handleCandidateSelect, orgPrimaryRole],
  );

  const renderOperationDropdown = React.useCallback(
    (b: OrgWithStats) => {
      return (
        <OperationDropdown
          onSelect={(q) => handleCandidateSelect(b, q)}
          value={qualifiedCandidates[b.guid].qualification}
        />
      );
    },
    [qualifiedCandidates, handleCandidateSelect],
  );

  return (
    <div className={css.findDuplicatesPage}>
      <header className={css.searchContainer}>
        <h1>Step 2. Find and categorise Candidate Organisations</h1>
        <OrgSearchFilters
          className={css.searchContainer}
          filters={filterState}
          setFilters={setFilterState}
        />
      </header>

      <div className={css.anchorContainer}>
        <div>
          <h2 className={css.anchorLabel}>In comparison to the anchor: </h2>

          <CandidateItem org={anchorOrg} orgPrimaryRole={orgPrimaryRole} />
        </div>
      </div>
      {/* search results */}
      <CandidateListPanel
        orgPrimaryRole={orgPrimaryRole}
        orgs={filteredResultList}
        renderAdditionalContent={renderQualificationButtons}
      />
      {/* Qualified duplicates */}
      <CandidateListPanel
        orgs={qualifiedList}
        orgPrimaryRole={orgPrimaryRole}
        renderCTA={renderOperationDropdown}
        hideCount
      />
    </div>
  );
}

export default FindDuplicatesPage;
