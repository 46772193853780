import React from "react";
import { Tooltip } from "antd5";

import { SendNoticeToCRM } from "components/integrations/SendNoticeToCRMButton";
import { NoticeFilters } from "components/notices/utils";
import { NoticeSeenBy } from "components/record_details/SeenBy";
import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import { NEW_HIGHLIGHT_LOGIC, useVariableValue } from "lib/featureFlags";
import { useMarkAsSeen } from "lib/hooks/api/records/useMarkAsSeen";
import { useRestricedViewNotice } from "lib/hooks/useRestrictedRowClick";
import { ProHelperDataTypes } from "lib/providers/ProHelper";
import { TrackingProvider, useTracking } from "lib/tracking";
import { getSignalTypes } from "lib/types/models";
import { allowCrmIntegrationFlag } from "lib/utils/allowCrmIntegrationFlag";
import CommentIcon from "../../lib/core_components/CommentIcon";
import { NoticesDto } from "../../lib/hooks/api/notices/useNotices";
import { joinToEnglishList, recordDetailsUrl } from "../../lib/utils";
import BookmarkIcon from "../actions/Bookmark";
import RedactData from "../ui/redact_data/RedactData";
import BuyersContainer from "./BuyersContainer";

import css from "./TitleCell.module.scss";

export function NewTitleCell({
  notice,
  requiredDataType,
  filters,
}: {
  notice: NoticesDto;
  requiredDataType: ProHelperDataTypes | undefined;
  filters: NoticeFilters;
}) {
  const { trackingData } = useTracking();
  const { viewRecord } = useRestricedViewNotice("Title click", requiredDataType, trackingData);
  const { viewRecord: showPaywallModal } = useRestricedViewNotice("Row-click", "AWARDS");

  const { mutate: markAsSeen } = useMarkAsSeen();
  const isNewHighlightingLogicEnabled = useVariableValue(NEW_HIGHLIGHT_LOGIC, false);

  const signalTypes = getSignalTypes(notice.signals ?? []);

  return (
    <div className={css.titleContainer}>
      <div className={css.titleCell}>
        <EllipsisTooltipTextLink
          fullText={notice.name || "--"}
          linkProps={{
            to: recordDetailsUrl(notice.guid, isNewHighlightingLogicEnabled ? filters : undefined),
            targetType: "new-tab",
            onClick: (e) => {
              e.stopPropagation();
              e.preventDefault();
              if (notice.redacted) {
                showPaywallModal(notice.guid);
              } else {
                viewRecord(notice.guid);
              }
            },
            className: css.titleText,
          }}
          linkText={notice.name || "--"}
          tooltipProps={{ overlayClassName: css.tooltip }}
        />
        <RedactData isRedacted={notice.redacted} bullets={16}>
          <BuyersContainer
            handleClick={() => markAsSeen({ recordGuid: notice.guid })}
            recordName={notice.name || "--"}
            buyers={notice.buyers.map((b) => ({ id: b.id, name: b.name }))}
            showAsLinks={false}
          />
        </RedactData>
      </div>
      <div className={css.recordStatus}>
        {notice.lists.length > 0 && (
          <Tooltip title={`Saved to ${joinToEnglishList(notice.lists.map((l) => l.name))}`}>
            <div>
              <BookmarkIcon filled colour="grey" />
            </div>
          </Tooltip>
        )}
        <CommentIcon
          commentCount={notice.comments?.length || 0}
          procurementStageId={notice.procurementStage.id}
        />
        <NoticeSeenBy seenByUsers={notice.seenByUsers} />
        {!window.guestUser && (
          <TrackingProvider
            data={{
              "Context source": "In-row",
              "Record GUID": notice.guid,
              "Signal score": notice.score ?? "0",
              Stage: notice.stage,
              "Signal types": signalTypes ? Object.keys(signalTypes) : [],
              Signals: signalTypes,
            }}
          >
            {!notice.redacted && allowCrmIntegrationFlag() && (
              <SendNoticeToCRM
                notice={{
                  guid: notice.guid,
                  name: notice.name ?? "",
                  value: notice.value ?? undefined,
                  url: notice.url,
                  closeDate: notice.closeDate ?? "",
                  type: notice.entityType ?? undefined,
                }}
                displayInRow
              />
            )}
          </TrackingProvider>
        )}
      </div>
    </div>
  );
}
