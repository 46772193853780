import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  CreateBidDocumentMutation,
  CreateBidDocumentMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const CREATE_BID_DOCUMENT_QUERY = graphql(`
  mutation createBidDocument($request: CreateBidDocumentRequest!) {
    createBidDocument(CreateBidDocumentRequest: $request) {
      bidId
      fileName
    }
  }
`);

export function useCreateBidDocument(
  options?: UseMutationOptions<
    CreateBidDocumentMutation,
    unknown,
    CreateBidDocumentMutationVariables,
    unknown
  >,
) {
  const queryClient = useQueryClient();

  const { data, ...rest } = useGraphQlMutation(CREATE_BID_DOCUMENT_QUERY, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      void queryClient.invalidateQueries(["bid"]);
    },
  });
  return { data: data, ...rest };
}
