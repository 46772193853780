import React from "react";
import styled from "@emotion/styled";
import { Flex, Text } from "styles/utility-components";

import { numberFormatLong } from "components/app_layout/Typography";
import { generateNoticeSearchUrl } from "components/notices/utils";
import {
  Qualification,
  QUALIFICATIONS,
} from "components/record_qualifications/QualificationStatus";
import EmptyState from "components/ui/empty_state/EmptyState";
import SkeletonText from "components/ui/skeleton/SkeletonText";
import { useQualificationStages } from "lib/hooks/api/notices/useQualificationStages";
import { Box } from "./util";

function Pipelines() {
  return (
    <Flex column gap={24} flexWrap="wrap">
      <Text h2>Your public sector pipeline</Text>
      <Flex gap={16} flexWrap="wrap" width="100%">
        <CustomBox>
          <PipelineBoxContent type="upcoming" />
        </CustomBox>
        <CustomBox>
          <PipelineBoxContent type="open" />
        </CustomBox>
      </Flex>
    </Flex>
  );
}

const HIDDEN_STATES: Qualification[] = [
  "unqualified",
  "not_relevant",
  "pre_engage_not_relevant",
  "no_bid",
  "lost",
];

const PipelineBoxContent = ({ type }: { type: "upcoming" | "open" }) => {
  const { data, isLoading } = useQualificationStages();

  const searchUrl = generateNoticeSearchUrl({
    relevanceScore: ["Low"],
    expiryDate: {
      filter: {
        relativeFrom: "PT0S",
      },
    },
    stage: [type === "upcoming" ? "PRE_TENDER" : "OPEN"],
  });

  if (isLoading) {
    return (
      <Flex column gap={40}>
        <Text h3>{type === "upcoming" ? "Expiring contract" : "Open tenders"}</Text>
        <Flex column gap={32}>
          {Array.from({ length: type === "upcoming" ? 2 : 4 }).map((_, index) => (
            <Flex key={index} gap={8} justifyContent="space-between">
              <SkeletonText width="160px" />
              <SkeletonText width="24px" />
            </Flex>
          ))}
        </Flex>
      </Flex>
    );
  }

  const tableData = Object.entries(
    type === "upcoming" ? QUALIFICATIONS.preEngage : QUALIFICATIONS.tender,
  )
    .filter(([key]) => !HIDDEN_STATES.includes(key as Qualification))
    .map(([key, value]) => ({
      status: value,
      stage: key as Qualification,
      count: data
        ? (type === "upcoming" ? data.preEngagement : data.tenders)?.find((q) => q.stage === key)
            ?.count ?? 0
        : 0,
    }));

  if (tableData.every(({ count }) => count === 0)) {
    return (
      <Flex column gap={24}>
        <Text h3>{type === "upcoming" ? "Upcoming contract" : "Open tenders"}</Text>
        <EmptyState
          type="userRequiredContent"
          heading="Pipeline empty"
          description="Mark a contract award as pre-engage and it will appear here"
          width="100%"
          action={
            <ButtonLink href={searchUrl}>
              Find {type === "upcoming" ? "upcoming contract" : "open tenders"}
            </ButtonLink>
          }
        />
      </Flex>
    );
  }

  return (
    <Flex column gap={40}>
      <Text h3>{type === "upcoming" ? "Upcoming contract" : "Open tenders"}</Text>
      <Flex column gap={32}>
        {tableData.map((item) => (
          <ButtonTableRow
            key={item.stage}
            href={generateNoticeSearchUrl({
              procurementStageQualifications: [item.stage],
            })}
          >
            <Flex gap={4} alignItems="center">
              {item.status.icon}
              <div />
              <Text>
                {item.status.textPrefix && (
                  <PrefixText inline>{item.status.textPrefix} </PrefixText>
                )}
                {item.status.text}
              </Text>
            </Flex>
            <Text bold>{numberFormatLong(item.count)}</Text>
          </ButtonTableRow>
        ))}
      </Flex>
    </Flex>
  );
};

const CustomBox = styled(Box)({
  flexBasis: "0", // Makes boxes equal width
  minWidth: "min(260px, 100%)", // Need to force to 1 column to avoid text wrapping
});

const ButtonTableRow = styled.a(({ theme }) => ({
  display: "flex",
  gap: "8px",
  justifyContent: "space-between",
  color: theme.colors.sysTextBlack,

  "&:hover": {
    textDecoration: "underline",
    textDecorationColor: theme.colors.sysTextBlack,
  },
}));

const ButtonLink = styled.a(({ theme }) => ({
  backgroundColor: theme.colors.sysBackgroundDefault,
  color: theme.colors.sysTextBlack,
  border: `1px solid ${theme.colors.sysBorderPrimary}`,
  borderRadius: "4px",
  marginTop: "12px",
  padding: "4px 12px",
  transition: "background-color 0.2s",
  textAlign: "center",
  alignContent: "center",

  "&:hover": {
    backgroundColor: theme.colors.sysBackgroundAlternative,
    color: theme.colors.sysTextBlack,
  },
}));

const PrefixText = styled(Text)(({ theme }) => ({
  color: theme.colors.sysTextSecondary,
  fontWeight: 300,
}));

export default Pipelines;
