import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { QualificationResult } from "components/bid_module/NoticeQualificationResult";
import { useUpdateBidAnswerById } from "lib/hooks/api/bid/bid-answer";

import css from "./EditAnswer.module.scss";

export type EditAnswerProps = {
  answer: QualificationResult["json"];
  answerId: string;
};

export function EditAnswer({ answer, answerId }: EditAnswerProps) {
  const [value, setValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const { isLoading, mutateAsync } = useUpdateBidAnswerById();
  const inputRef = useRef<HTMLInputElement | null>(null);

  function onStartEdit() {
    setIsEditing(true);
  }

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  async function onMutate() {
    if (!value || isLoading || isEditing === false) {
      return;
    }

    await mutateAsync({ input: { answer: value, id: answerId } });
    setIsEditing(false);
  }

  async function onHandleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    switch (e.key) {
      case "Escape":
        setValue(answer?.answer || "Not found");
        setIsEditing(false);
        break;
      case "Enter":
        await onMutate();
        break;
      default:
        break;
    }
  }

  return (
    <>
      <input
        className={classNames(isEditing === false && css.hide)}
        ref={inputRef}
        disabled={isLoading}
        onBlur={onMutate}
        onKeyUp={onHandleKeyUp}
        value={value}
        placeholder={value || answer?.answer || ""}
        onChange={(e) => setValue(e.target.value)}
      />
      <span
        className={classNames(isEditing && css.hide)}
        onClick={(e) => {
          e.stopPropagation();
          onStartEdit();
        }}
        style={{ cursor: "pointer" }}
      >
        {value || answer?.answer || "Not found"}
      </span>
    </>
  );
}
