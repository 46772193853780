import { useEffect, useMemo, useState } from "react";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { GraphQLError } from "graphql";
import { GraphQLClient, RequestDocument, Variables } from "graphql-request";
import { VariablesAndRequestHeadersArgs } from "graphql-request/build/esm/types";
import { createClient, ExecutionResult, Sink } from "graphql-ws";
import useDeepCompareEffect from "use-deep-compare-effect";

import { isTokenExpired, useAppToken } from "lib/hooks/api/useAppToken";

const url = window.app_service_url?.includes("http")
  ? new URL("/graphql", window.app_service_url)
  : new URL(window.app_service_url + "/graphql", window.location.origin);

const websocketUrl = new URL(
  url.pathname,
  `${url.protocol === "https:" ? "wss" : "ws"}://${url.host}`,
);

const graphQLClient = new GraphQLClient(url.toString(), {
  errorPolicy: "none",
});

export function useGraphQlClient() {
  const { data: token, isStale } = useAppToken();
  graphQLClient.setHeader("authorization", `Bearer ${token}`);
  return { client: graphQLClient, isReady: !isStale && !!token };
}

export function useGraphQlQuery<T, V extends Variables = Variables>(
  key: QueryKey,
  document: RequestDocument | TypedDocumentNode<T, V>,
  variables: VariablesAndRequestHeadersArgs<V>,
  options?: UseQueryOptions<T, unknown, T, QueryKey>,
) {
  const { client, isReady } = useGraphQlClient();
  return useQuery(key, async () => client.request(document, ...variables), {
    enabled: isReady && (options?.enabled || true),
    ...options,
  });
}

export function useGraphQlInfiniteQuery<T, V extends Variables = Variables>(
  key: QueryKey,
  document: RequestDocument | TypedDocumentNode<T, V>,
  queryFn: (page: number) => VariablesAndRequestHeadersArgs<V>,
  options?: UseInfiniteQueryOptions<T, unknown, T, T, QueryKey>,
): UseInfiniteQueryResult<T, unknown> {
  const { client } = useGraphQlClient();

  return useInfiniteQuery<T>(
    key,
    async ({ pageParam = 1 }) => {
      const vars = queryFn(pageParam);
      return client.request(document, ...vars);
    },
    options,
  );
}

export function useGraphQlMutation<T, V extends Variables = Variables>(
  document: TypedDocumentNode<T, V>,
  options?: UseMutationOptions<T, GraphQLError, V, unknown>,
): UseMutationResult<T, GraphQLError, V, unknown> {
  const { data: token, isStale, refetch } = useAppToken();

  useEffect(() => {
    graphQLClient.setHeader("authorization", `Bearer ${token}`);
  }, [token]);

  const mutation = useMutation(async (variables: V) => {
    const vars = [variables] as unknown as VariablesAndRequestHeadersArgs<V>;
    return graphQLClient.request(document, ...vars);
  }, options);

  // wrap the muatation to handle token expiry
  const mutate = (variables: V) => {
    if (token && !isTokenExpired(token) && !isStale) {
      mutation.mutate(variables);
    } else {
      refetch()
        .then(({ data }) => {
          graphQLClient.setHeader("authorization", `Bearer ${data}`);
          mutation.mutate(variables);
        })
        .catch(() => {
          console.warn("Failed to fetch api token");
          // deliberately not going to handle the error here, the following mutation will fail but will trigger error states
          mutation.mutate(variables);
        });
    }
  };

  const mutateAsync = async (variables: V) => {
    if (token && !isTokenExpired(token) && !isStale) {
      return mutation.mutateAsync(variables);
    } else {
      const { data } = await refetch();
      graphQLClient.setHeader("authorization", `Bearer ${data}`);
      return mutation.mutateAsync(variables);
    }
  };

  return { ...mutation, mutate, mutateAsync };
}

export function useSubscription<D, V extends Record<string, unknown>>(
  query: string,
  variables: V,
  options?: {
    enabled?: boolean;
    onData?: (data: D, errors?: GraphQLError[]) => void;
  },
) {
  const { data: token } = useAppToken();
  const [data, setData] = useState<D>();
  const [errors, setErrors] = useState<GraphQLError[]>([]);
  const isEnabled = options?.enabled ?? true;

  const client = useMemo(() => {
    return createClient({
      url: websocketUrl.toString(),
      // authorization must be lower case for graphql-ws
      connectionParams: () => ({ authorization: `Bearer ${token}` }),
    });
  }, [token]);

  const sink: Sink<ExecutionResult<Record<string, unknown>, unknown>> = useMemo(
    () => ({
      next: (result) => {
        setData(result.data as D);
        const errors: GraphQLError[] | undefined = result.errors ? [...result.errors] : undefined;
        if (errors) {
          setErrors(errors);
        }
        options?.onData?.(result.data as D, errors);
      },
      error: (error) => {
        setErrors((err) => [...err, error as GraphQLError]);
      },
      complete: () => {},
    }),
    [options],
  );

  // reset state & starts the subscription if it's enabled
  useDeepCompareEffect(() => {
    setData(undefined);
    setErrors([]);
    let unsubscribe: (() => void) | undefined;
    if (token && isEnabled) {
      unsubscribe = client.subscribe({ query, variables }, sink);
    } else if (unsubscribe && isEnabled) {
      unsubscribe();
    }

    return () => {
      unsubscribe?.();
    };
  }, [query, variables, token, isEnabled]);

  return { data, isError: errors.length > 0, errors };
}
