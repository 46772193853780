import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { App, Button, ConfigProvider } from "antd5";
import Tabs, { TabsProps } from "antd5/es/tabs";
import { Link } from "wouter";

import OpportunityBuyers from "components/opportunities/saved/OpportunityBuyers";
import { OpportunitySuppliers } from "components/opportunities/saved/OpportunitySuppliers";
import { useDeleteOpportunity } from "lib/hooks/api/opportunities/useDeleteOpportunity";
import OpportunityCommentsSidebar from "../../components/opportunities/opportunity_comments_sidebar/OpportunityCommentsSidebar";
import OpportunityDetailSidebar from "../../components/opportunities/opportunity_detail_sidebar/OpportunityDetailSidebar";
import OpportunityDocuments from "../../components/opportunities/saved/OpportunityDocuments";
import OpportunityFrameworks from "../../components/opportunities/saved/OpportunityFrameworks";
import { OpportunityNotices } from "../../components/opportunities/saved/OpportunityNotices";
import OpportunitySavedRow from "../../components/opportunities/saved/OpportunitySavedRow";
import CentredSpinner from "../../lib/core_components/CentredSpinner";
import { useOpportunity } from "../../lib/hooks/api/opportunities/useOpportunity";
import UIcon from "../../lib/icons/untitled_ui/UIcon";
import { Flex, Text } from "../../styles/utility-components";

type OpportunityPageProps = {
  id: string;
};

export default function OpportunityPage({ id }: OpportunityPageProps) {
  const theme = useTheme();

  const { message } = App.useApp();

  const { data: opportunity, isLoading: opportunityLoading } = useOpportunity(id);
  const { mutate: deleteOpportunity } = useDeleteOpportunity({
    onSuccess: () => {
      message.success("Opportunity deleted successfully");
      window.location.href = "/opportunities";
    },
  });

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeTab, setActiveTab] = useState("details");
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (opportunityLoading) {
    return <CentredSpinner />;
  }

  const tabs: TabsProps["items"] = [
    {
      key: "details",
      label: "Details",
      children: opportunity ? <OpportunityDetailSidebar opportunity={opportunity} /> : null,
    },
    {
      key: "comments",
      label: "Comments",
      children: opportunity ? <OpportunityCommentsSidebar opportunityId={opportunity.id} /> : null,
    },
  ];

  return (
    <Container>
      <Header>
        <Text color={theme.colors.sysTextSecondary}>
          <Link href="/">Opportunities</Link> / {opportunity?.name}
        </Text>
        <HeaderButton onClick={toggleSidebar} aria-label="Toggle sidebar">
          <UIcon color={theme.colors.sysTextSecondary} icon="layoutRight" size={24} />
        </HeaderButton>
      </Header>
      <Flex>
        <Content column gap={48}>
          <TitleRow>
            <Flex column gap={16}>
              <Text h2 color={theme.colors.sysPrimaryDefault}>
                {opportunity?.name}
              </Text>
              <Text>{opportunity?.description}</Text>
            </Flex>
            <Button
              onClick={() => {
                opportunity && deleteOpportunity({ id: opportunity.id });
              }}
            >
              Delete
            </Button>
          </TitleRow>
          <Flex column gap={48}>
            <Text h2 bold>
              Saved
            </Text>
            <OpportunitySavedRow
              entity="notice"
              onClick={() => (window.location.href = "/notices")}
            >
              <OpportunityNotices notices={opportunity?.notices ?? []} />
            </OpportunitySavedRow>
            <OpportunitySavedRow
              entity="document"
              onClick={() => (window.location.href = "/documents")}
            >
              <OpportunityDocuments documents={opportunity?.documents ?? []} />
            </OpportunitySavedRow>
            <OpportunitySavedRow
              entity="supplier"
              onClick={() => (window.location.href = "/suppliers/search")}
            >
              <OpportunitySuppliers suppliers={opportunity?.suppliers ?? []} />
            </OpportunitySavedRow>
            <OpportunitySavedRow
              entity="framework"
              onClick={() => (window.location.href = "/frameworks")}
            >
              <OpportunityFrameworks frameworks={opportunity?.frameworks ?? []} />
            </OpportunitySavedRow>
            <OpportunitySavedRow
              entity="buyer"
              onClick={() => (window.location.href = "/buyers/all-buyers")}
            >
              <OpportunityBuyers buyers={opportunity?.buyers ?? []} />
            </OpportunitySavedRow>
          </Flex>
        </Content>
        {isSidebarOpen && (
          <Sidebar aria-label="sidebar">
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    horizontalMargin: "0",
                    horizontalItemGutter: 0,
                    horizontalItemPaddingLG: "12px 84px",
                  },
                },
              }}
            >
              <SidebarTabs
                activeKey={activeTab}
                size="large"
                items={tabs}
                onChange={(key: string) => setActiveTab(key)}
              />
            </ConfigProvider>
          </Sidebar>
        )}
      </Flex>
    </Container>
  );
}

const Container = styled(Flex)({
  flexDirection: "column",
  flexGrow: 1,
  width: 480,
});

const Header = styled(Flex)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  gap: 24,

  padding: "20px 24px",

  borderBottom: `1px solid ${theme.colors.sysBorderPrimary}`,
}));

const HeaderButton = styled.button({
  all: "unset",
  cursor: "pointer",
});

const TitleRow = styled(Flex)({
  flexDirection: "row",
  justifyContent: "space-between",
});

const Content = styled(Flex)({
  width: "100%",

  padding: 48,
});

const Sidebar = styled(Flex)(({ theme }) => ({
  width: 480,
  height: "100vh",

  borderLeft: `1px solid ${theme.colors.sysBorderPrimary}`,
}));

const SidebarTabs = styled(Tabs)({
  width: "100%",
  height: "100%",
});
