import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd5";
import qs from "qs";

import { TextLink } from "components/actions/Links";
import { useURLState } from "../../lib/hooks/useURLState";
import { OpportunityEntityType } from "../../lib/types/graphQLEnums";
import { SaveToButton } from "../opportunities/saving/SaveToButton";
import { DocumentViewerFilters } from "./FilterForm";
import { useDocumentTracking } from "./tracking";

import css from "./DocumentHeader.module.scss";

type DocumentHeaderProps = {
  documentId: string;
  documentOrgs: {
    id: string;
    name: string;
  }[];
  title: string;
  downloadUrl?: string;
  disableTitleLink?: boolean;
};

export default function DocumentHeader({
  documentId,
  title,
  downloadUrl,
  disableTitleLink,
  documentOrgs,
}: DocumentHeaderProps) {
  const tracking = useDocumentTracking();

  const [filters] = useURLState<DocumentViewerFilters>("docViewerFilters", { keywords: [] });

  const params = qs.stringify(
    { docViewerFilters: filters },
    { arrayFormat: "brackets", addQueryPrefix: true },
  );

  return (
    <div className={css.header}>
      <Tooltip title={title} placement="bottom">
        <div className={css.ellipsisWrapper}>
          <TextLink
            className={css.title}
            targetType="new-tab"
            to={`/documents/${documentId}${params}`}
            disabled={disableTitleLink}
          >
            {title}
          </TextLink>
        </div>
      </Tooltip>
      <div className={css.actions}>
        <SaveToButton
          entityType="document"
          entityId={documentId}
          defaultOpportunityValues={{
            name: title,
            entities: [
              {
                entityId: documentId,
                entityType: OpportunityEntityType.Document,
              },
              ...documentOrgs.map((org) => ({
                entityId: org.id,
                entityType: OpportunityEntityType.Organisation,
              })),
            ],
          }}
        />
        {downloadUrl && (
          <Button
            download
            href={downloadUrl ?? ""}
            icon={<DownloadOutlined className={css.downloadIcon} />}
            onClick={tracking.exportedData}
          />
        )}
      </div>
    </div>
  );
}
