import React from "react";
import { useForm } from "react-hook-form";
import { hot } from "react-hot-loader/root";
import { Button } from "antd5";

import { withAppLayout } from "components/app_layout/AppLayout";
import { Input } from "components/form_components/Inputs";
import { DOMAINS } from "lib/disallowedDomains";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import * as tracking from "lib/tracking";
import { sendGa4Event } from "../../lib/ga4Tracking";

import css from "./PreAuthenticationScreen.module.scss";

type Props = {
  login_screen: boolean;
};

type FormValues = {
  email: string;
  connection: string;
};

export function PreAuthenticationScreen({ login_screen }: Props) {
  const GOOGLE_CONNECTION = "google-oauth2";
  const AZURE_AD_CONNECTION = "azure-ad-enterprise";

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      email: "",
      connection: "",
    },
  });

  const DISALLOWED_EMAIL_DOMAINS = new RegExp(
    `^(.+)@(${DOMAINS.map((domain) => domain.replace(".", "\\.")).join("|")})$`,
  );

  const content = React.useMemo(() => {
    if (login_screen) {
      return {
        headerTitle: "Log in to Stotles",
        description: "",
        googleAuth: "Log in with Google",
        microsoftAuth: "Log in with Microsoft",
        actionButton: "Log in",
        alternativeAuth: {
          content: "New to Stotles?",
          buttonContent: "Register here",
        },
      };
    } else {
      return {
        headerTitle: "Sign up for free",
        description: "Accelerate your public sector sales with Stotles.",
        googleAuth: "Sign up with Google",
        microsoftAuth: "Sign up with Microsoft",
        actionButton: "Continue",
        alternativeAuth: {
          content: "Already have an account?",
          buttonContent: "Log in",
        },
      };
    }
  }, [login_screen]);

  const toggleAuthMode = () => {
    if (login_screen) {
      window.location.assign("/get-started");
    } else {
      window.location.assign("/users/sign_in");
    }
  };

  const redirectToOmniauth = (connection: string, email: string) => {
    const encodedEmail = encodeURIComponent(email);
    let redirectUrl = `/users/redirect_to_omniauth?connection=${connection}&email=${encodedEmail}`;

    if (!login_screen && connection != AZURE_AD_CONNECTION) {
      redirectUrl = redirectUrl + "&screen=signup";
    }

    // if a social connection is used, this allows users to select another account for login/signup
    if (connection) {
      redirectUrl = redirectUrl + "&prompt=select_account";
    }

    window.location.assign(redirectUrl);
  };

  const handleGoogleAuthentication = async () => {
    if (!login_screen) {
      tracking.logEvent(tracking.EventNames.signUpClicked, {
        "Page source": "Sign up - Stotles Page",
        "Context source": "Sign up with Google",
      });
    }

    sendGa4Event({
      event: "continue_with_google",
      form: {
        name: login_screen ? "Login" : "Signup",
        id: login_screen ? "login-form" : "signup-form",
      },
    });

    redirectToOmniauth(GOOGLE_CONNECTION, "");
  };

  const handleMicrosoftAuthentication = async () => {
    if (!login_screen) {
      tracking.logEvent(tracking.EventNames.signUpClicked, {
        "Page source": "Sign up - Stotles Page",
        "Context source": "Sign up with Microsoft",
      });
    }

    sendGa4Event({
      event: "continue_with_microsoft",
      form: {
        name: login_screen ? "Login" : "Signup",
        id: login_screen ? "login-form" : "signup-form",
      },
    });

    redirectToOmniauth(AZURE_AD_CONNECTION, "");
  };

  const onSubmit = (data: FormValues) => {
    if (!login_screen) {
      tracking.logEvent(tracking.EventNames.signUpClicked, {
        "Page source": "Sign up - Stotles Page",
        "Context source": "Sign up with email",
      });
    }

    sendGa4Event({
      event: "email_submitted",
      form: {
        name: login_screen ? "Login" : "Signup",
        id: login_screen ? "login-form" : "signup-form",
      },
    });

    sendGa4Event({
      event: "continue_click",
      form: {
        name: login_screen ? "Login" : "Signup",
        id: login_screen ? "login-form" : "signup-form",
      },
    });

    redirectToOmniauth("", data.email);
  };

  return (
    <div className={css.preauthenticationPage}>
      <div className={css.preauthenticationContainer}>
        <div className={css.header}>
          <h1 className={css.headerTitle}>{content.headerTitle}</h1>
          <p className={css.description}>{content.description}</p>
        </div>

        <Button onClick={handleGoogleAuthentication} className={css.socialConnectionButton}>
          <span className={css.googleLogo} />
          <span>{content.googleAuth}</span>
        </Button>

        {FeatureToggles.isEnabled(Feature.SIGN_UP_WITH_MICROSOFT) && (
          <Button onClick={handleMicrosoftAuthentication} className={css.socialConnectionButton}>
            <span className={css.microsoftLogo} />
            <span>{content.microsoftAuth}</span>
          </Button>
        )}
        <div className={css.lineContainer}>
          <div className={css.line}></div>
          <div className={css.word}>OR</div>
        </div>

        <form id="validate-email-form" onSubmit={handleSubmit(onSubmit)}>
          <Input
            className={css.emailInput}
            name="email"
            placeholder={login_screen ? "Enter your email address" : "yourname@company.com"}
            label={login_screen ? "Email address" : "Use your work email address"}
            size="large"
            type="text"
            control={control}
            rules={{
              required: { value: true, message: "Email is required" },
              validate: (val) => {
                const validEmailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!validEmailPattern.test(val)) {
                  return "Please provide a valid email address.";
                }

                if (login_screen) {
                  return true;
                }
                if (DISALLOWED_EMAIL_DOMAINS.exec(val)) {
                  return "Please provide a work email e.g. yourname@company.com";
                }
                return true;
              },
            }}
          />
          <Button type="primary" htmlType="submit" className={css.submitButton}>
            {content.actionButton}
          </Button>
          <p className={css.alternativeAuth}>
            {content.alternativeAuth.content}
            <Button type="link" onClick={toggleAuthMode} className={css.link}>
              {content.alternativeAuth.buttonContent}
            </Button>
          </p>

          {!login_screen && (
            <div className={css.footer}>
              <p>
                By signing up, you agree to{" "}
                <a
                  className={css.link}
                  href={
                    "https://www.notion.so/stotles/Stotles-Terms-Conditions-c06c71f5ca19484c8a18d14fd6eadae5"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  Stotle's Terms and Conditions
                </a>{" "}
                and{" "}
                <a
                  className={css.link}
                  href={
                    "https://www.notion.so/stotles/Stotles-Privacy-Policy-4a597d71636d443c9be325fef2382701"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy.
                </a>
              </p>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default hot(
  withAppLayout(PreAuthenticationScreen, (props) => ({
    pageName: props.login_screen ? "Log in" : "Get Started",
    hideMenuItems: true,
    hideUserItems: true,
  })),
);
