import React from "react";
import { CaretDownOutlined, CheckCircleFilled } from "@ant-design/icons";
import { Dropdown } from "antd5";
import classNames from "classnames";

import { DefaultTheme } from "components/app_layout/DefaultTheme";
import { useDeleteRecordQualification } from "lib/hooks/api/records/useDeleteRecordQualification";
import { useUpdateRecordQualification } from "lib/hooks/api/records/useUpdateRecordQualification";
import {
  CircleEmpty,
  CircleFull,
  CircleHalfFull,
  CircleThirdFull,
  CircleTwoThirdsFull,
  TrophyCircle,
} from "lib/icons/ProgressCircle";
import { yellow600 } from "lib/themes/colors";

import css from "./QualificationStatus.module.scss";

type QualificationObj = {
  icon: React.ReactNode;
  text: string;
  textPrefix?: string;
};

export const QUALIFICATIONS = {
  preEngage: {
    unqualified: {
      icon: <CircleEmpty className={css.icon} />,
      text: "Unqualified",
    },
    pre_engage_to_do: {
      icon: <CircleHalfFull fill={yellow600} className={css.icon} />,
      textPrefix: "Pre-engage:",
      text: "To do",
    },
    pre_engage_done: {
      icon: <CheckCircleFilled className={classNames(css.icon, css.greenCircleIcon)} />,
      textPrefix: "Pre-engage:",
      text: "Actioned",
    },
    pre_engage_not_relevant: {
      icon: <CircleFull fill={DefaultTheme.token?.colorError} className={css.icon} />,
      textPrefix: "Pre-engage:",
      text: "Not relevant",
    },
  },
  tender: {
    unqualified: {
      icon: <CircleEmpty className={css.icon} />,
      text: "Unqualified",
    },
    in_review: {
      icon: <CircleThirdFull fill={yellow600} className={css.icon} />,
      textPrefix: "Tender:",
      text: "In review",
    },
    bid_prep: {
      icon: <CircleTwoThirdsFull fill={yellow600} className={css.icon} />,
      textPrefix: "Tender:",
      text: "Bid prep",
    },
    bid_submitted: {
      icon: <CircleFull fill={DefaultTheme.token?.colorSuccess} className={css.icon} />,
      textPrefix: "Tender:",
      text: "Bid submitted",
    },
    won: {
      icon: <TrophyCircle fill={DefaultTheme.token?.colorSuccess} className={css.icon} />,
      textPrefix: "Tender:",
      text: "Won",
    },
    not_relevant: {
      icon: <CircleFull fill={DefaultTheme.token?.colorError} className={css.icon} />,
      textPrefix: "Tender:",
      text: "Not relevant",
    },
    no_bid: {
      icon: <CircleFull fill={DefaultTheme.token?.colorError} className={css.icon} />,
      textPrefix: "Tender:",
      text: "No bid",
    },
    lost: {
      icon: <CircleFull fill={DefaultTheme.token?.colorError} className={css.icon} />,
      textPrefix: "Tender:",
      text: "Lost",
    },
  },
} as {
  preEngage: Record<
    "unqualified" | "pre_engage_to_do" | "pre_engage_done" | "pre_engage_not_relevant",
    QualificationObj
  >;
  tender: Record<
    | "unqualified"
    | "in_review"
    | "bid_prep"
    | "bid_submitted"
    | "won"
    | "not_relevant"
    | "no_bid"
    | "lost",
    QualificationObj
  >;
};

const renderQualification = (qualification: QualificationObj) => (
  <div className={css.qualificationContainer}>
    {qualification.icon} {qualification.textPrefix && <>{qualification.textPrefix} </>}
    {qualification.text}
  </div>
);

export const PRE_ENGAGE_QUALIFICATION: Record<string, React.ReactNode> = {
  unqualified: renderQualification(QUALIFICATIONS.preEngage.unqualified),
  pre_engage_to_do: renderQualification(QUALIFICATIONS.preEngage.pre_engage_to_do),
  pre_engage_done: renderQualification(QUALIFICATIONS.preEngage.pre_engage_done),
  pre_engage_not_relevant: renderQualification(QUALIFICATIONS.preEngage.pre_engage_not_relevant),
};

export const TENDER_QUALIFICATION: Record<string, React.ReactNode> = {
  unqualified: renderQualification(QUALIFICATIONS.tender.unqualified),
  in_review: renderQualification(QUALIFICATIONS.tender.in_review),
  bid_prep: renderQualification(QUALIFICATIONS.tender.bid_prep),
  bid_submitted: renderQualification(QUALIFICATIONS.tender.bid_submitted),
  won: renderQualification(QUALIFICATIONS.tender.won),
  not_relevant: renderQualification(QUALIFICATIONS.tender.not_relevant),
  no_bid: renderQualification(QUALIFICATIONS.tender.no_bid),
  lost: renderQualification(QUALIFICATIONS.tender.lost),
};

export const QualificationEnum = {
  NotRelevant: "not_relevant",
  InReview: "in_review",
  BidPrep: "bid_prep",
  BidSubmitted: "bid_submitted",
  NoBid: "no_bid",
  Won: "won",
  Lost: "lost",
  PreEngageToDo: "pre_engage_to_do",
  PreEngageDone: "pre_engage_done",
  PreEngageNotRelevant: "pre_engage_not_relevant",
} as const;
export type QualificationEnumType = (typeof QualificationEnum)[keyof typeof QualificationEnum];

export type Qualification = QualificationEnumType | "unqualified";

function QualificationSelect({
  recordGuid,
  procurementStage,
  score,
  signalTypes,
}: {
  recordGuid: string;
  procurementStage: { id: string; stage: string; qualification: Qualification };
  score: number | null | undefined;
  signalTypes: Record<string, string[]> | undefined;
}) {
  const { mutate } = useUpdateRecordQualification();
  const { mutate: unQualify } = useDeleteRecordQualification();

  const isPreEngage = procurementStage.stage.toLowerCase() !== "tender";

  const selectedQualifcation = isPreEngage
    ? PRE_ENGAGE_QUALIFICATION[procurementStage.qualification]
    : TENDER_QUALIFICATION[procurementStage.qualification];

  const items = Object.entries(isPreEngage ? PRE_ENGAGE_QUALIFICATION : TENDER_QUALIFICATION).map(
    ([key, value]) =>
      key === procurementStage.qualification
        ? { key, label: value, disabled: true }
        : { key, label: value },
  );

  return (
    <Dropdown
      menu={{
        items,
        onClick: (info) => {
          const key = info.key as Qualification;
          if (key === "unqualified") {
            unQualify({
              procurementStage: procurementStage,
              recordGuid: recordGuid,
              score: score,
              signalTypes: signalTypes,
              contextSource: "Record qualification dropdown",
            });
            return;
          }
          mutate({
            qualification: key,
            recordGuid: recordGuid,
            score: score,
            signalTypes: signalTypes,
            procurementStage: { id: procurementStage.id, stage: procurementStage.stage },
            contextSource: "Record qualification dropdown",
          });
        },
      }}
      trigger={["click"]}
    >
      <div className={css.selectContainer}>
        {selectedQualifcation} <CaretDownOutlined />
      </div>
    </Dropdown>
  );
}

export function QualificationStatus({
  procurementStage,
  noticeGuid,
  score,
  signalTypes,
  enableUpdate,
}: {
  procurementStage: { id: string; stage: string; qualification?: Qualification };
  noticeGuid: string;
  score: number | null | undefined;
  signalTypes?: Record<string, string[]>;
  enableUpdate?: boolean;
}) {
  const qualification: Qualification = procurementStage.qualification || "unqualified";

  const isPreEngage = procurementStage.stage.toLowerCase() !== "tender";

  if (enableUpdate) {
    return (
      <QualificationSelect
        procurementStage={{ ...procurementStage, qualification: qualification }}
        recordGuid={noticeGuid}
        score={score}
        signalTypes={signalTypes}
      />
    );
  }

  if (isPreEngage) {
    return <>{PRE_ENGAGE_QUALIFICATION[qualification]}</>;
  }

  // check is just in case there's an error to prevent a crash
  if (TENDER_QUALIFICATION[qualification]) {
    return <>{TENDER_QUALIFICATION[qualification]}</>;
  }

  return null;
}
