import React from "react";
import styled from "@emotion/styled";
import { Flex, Text } from "styles/utility-components";

import Signal from "lib/icons/Signal";
import UIcon from "lib/icons/untitled_ui/UIcon";
import { routes, settingsRoutes } from "lib/routes";
import { Box } from "./util";

function UsefulLinks({ column }: { column: "1" | "2+" }) {
  return (
    <UsefulLinksBox column={column}>
      <Flex column gap={16}>
        <Text h3>Useful links</Text>
        <Links column gap={8}>
          <Link href={settingsRoutes.signalSettings}>
            <Signal size={16} background="inherit" fill="inherit" />
            <LinkText>Update your signal settings</LinkText>
          </Link>
          <Link href={settingsRoutes.integrations}>
            <UIcon icon="integration" size={16} color="inherit" />
            <LinkText>Setup integrations</LinkText>
          </Link>
          <Link href={routes.help}>
            <UIcon icon="helpCircle" size={16} color="inherit" />
            <LinkText>Vist our help centre</LinkText>
          </Link>
        </Links>
      </Flex>
    </UsefulLinksBox>
  );
}

// Keep max-width in sync with HomepageContent
const UsefulLinksBox = styled(Box)<{ column: "1" | "2+" }>(({ column }) => ({
  display: column === "1" ? "none" : "block",
  "@media screen and (max-width: 992px)": {
    display: column === "2+" ? "none" : "block",
  },
}));

const Links = styled(Flex)({
  marginLeft: "-8px",
});

const Link = styled.a(({ theme }) => ({
  display: "flex",
  gap: 8,
  padding: "4px 0 4px 8px",
  borderRadius: "4px",
  alignItems: "center",
  width: "100%",
  color: theme.colors.sysTextDefault,
  "&:hover": {
    color: theme.colors.sysTextDefault,
    backgroundColor: theme.colors.sysPrimarySubtle,
  },
}));

const LinkText = styled.span({
  flexShrink: 10, // Ensures the icon doesn't shrink
});

export default UsefulLinks;
