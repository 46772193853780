import React from "react";
import { hot } from "react-hot-loader/root";
import { Button } from "antd5";

import { withAppLayout } from "components/app_layout/AppLayout";
import CentredSpinner from "lib/core_components/CentredSpinner";
import { useGetLeadSubscriptionStatus } from "lib/hooks/api/lead_subscriptions/useGetLeadSubscriptionStatus";
import { useLeadSubscriptionUnsubscribe } from "lib/hooks/api/lead_subscriptions/useLeadSubscriptionUnsubscribe";
import { routes } from "lib/routes";

import css from "./UnsubscribePage.module.scss";

import StotlesLogo from "../../../assets/images/stotles_logo.svg";
import StotlesTada from "../../../assets/images/stotles_tada.svg";
import UnsubscribeAeroplane from "../../../assets/images/unsubscribe_aeroplane.svg";

function UnsubscribePage(): JSX.Element {
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");

  const subscriptionStatusRequest = useGetLeadSubscriptionStatus(token);
  const { mutate: unsubscribe, isLoading: unsubscribing } = useLeadSubscriptionUnsubscribe();
  if (!token) {
    return <div>Invalid token</div>;
  }

  if (subscriptionStatusRequest.isLoading) {
    return <CentredSpinner />;
  }

  if (subscriptionStatusRequest.isError) {
    return <div>Error</div>;
  }

  if (subscriptionStatusRequest.data?.status === "INACTIVE") {
    return (
      <div className={css.pageContent}>
        <div>
          <img src={StotlesLogo} alt="Stotles" className={css.logo} />
        </div>

        <div className={css.container}>
          <div className={css.content}>
            <h1>You’ve been successfully unsubscribed.</h1>
            If you ever change your mind, feel free to create alerts in app at any time. In the
            meantime, there are public sector contracts with your name on them, so why not check
            them out on Stotles?
            <div>
              <Button type="primary" href={routes.notices}>
                Find opportunities
              </Button>
            </div>
          </div>{" "}
          <div>
            <img src={StotlesTada} alt="Success" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={css.pageContent}>
      <img src={StotlesLogo} alt="Stotles" className={css.logo} />
      <div className={css.container}>
        <div className={css.content}>
          <h1>Unsubscribe</h1>
          <div>
            We're sorry to see you leave, but we understand that everyone's inbox is personal and
            respect your decision to unsubscribe. To complete the unsubscribe process, click the
            button below.
          </div>
          <div>
            <Button
              onClick={() => unsubscribe({ token, listUnsubscribe: "Standard" })}
              loading={unsubscribing}
              type="primary"
            >
              Unsubscribe
            </Button>
          </div>
          <div className={css.contactMessage}>
            If there's anything we can do to make your experience better, please let us know by
            emailing <a href="mailto:team@stotles.com">team@stotles.com</a>
          </div>
        </div>
        <img src={UnsubscribeAeroplane} alt="Unsubscribe" />
      </div>
    </div>
  );
}

export default hot(
  withAppLayout(UnsubscribePage, {
    pageName: "Unsubscribe",
    contentClassName: css.page,
    textLogo: true,
  }),
);
