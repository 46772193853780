import * as React from "react";
import { Button } from "antd5";
import { SizeType } from "antd5/lib/config-provider/SizeContext";
import { TooltipProps } from "antd5/lib/tooltip";
import classnames from "classnames";

import { SearchRequestStages } from "lib/types/graphQLEnums";
import FrameworkStage, { FRAMEWORK_STAGE_CONFIG } from "./FrameworkStage";

import css from "./FrameworkStageButtons.module.scss";

type Props = {
  onChange: (values: SearchRequestStages[]) => void;
  id?: string;
  className?: string;
  checkedStages: SearchRequestStages[];
  size?: SizeType;
  tooltipPlacement?: TooltipProps["placement"];
};

// Because this is used within Form.Item we have to wrap it in a forwardRef
const FrameworkStageButtons = React.forwardRef<HTMLDivElement, Props>(
  ({ onChange, id, className, checkedStages, size, tooltipPlacement }, ref) => {
    const handleSelect = React.useCallback(
      (stage) => {
        if (checkedStages.includes(stage)) {
          onChange(checkedStages.filter((v) => v !== stage));
        } else onChange([...checkedStages, stage]);
      },
      [checkedStages, onChange],
    );

    return (
      <div
        className={classnames(css.recordStageButtonContainer, className, {
          [css.small]: size === "small",
        })}
        id={id}
        ref={ref}
      >
        {Object.entries(FRAMEWORK_STAGE_CONFIG)
          .sort(([, a], [, b]) => a.order - b.order)
          .map(([key]) => key)
          .map((s) => {
            return (
              <Button
                size={size}
                key={s}
                className={classnames({
                  [css.selected]: checkedStages.includes(s as SearchRequestStages),
                })}
                onClick={() => handleSelect(s)}
                value={s}
              >
                <FrameworkStage tooltipPlacement={tooltipPlacement} stage={s} />
              </Button>
            );
          })}
      </div>
    );
  },
);

FrameworkStageButtons.displayName = "FrameworkStageButtons";

export default FrameworkStageButtons;
