export type PendingDocumentColumns =
  | "title"
  | "fileName"
  | "category"
  | "buyers"
  | "createdAt"
  | "updatedAt"
  | "publishedAt"
  | "sourceUrl"
  | "id"
  | "createdBy";

export type PendingDocumentFilters = {
  title: string;
  categories?: string[];
  buyers?: string[];
  createdBy?: string[];
  sort: {
    field: SortField;
    order: "ASC" | "DESC";
  };
};

export const DEFAULT_PENDING_DOCUMENT_FILTERS: PendingDocumentFilters = {
  title: "",
  sort: {
    field: "createdAt",
    order: "DESC",
  },
};

type Pagination = { pageSize: number; current: number };

export const DEFAULT_PAGINATION = { current: 1, pageSize: 10 };

export type SortField = "createdAt" | "updatedAt" | "createdBy" | "status";

const validSortFields: SortField[] = ["createdAt", "updatedAt", "createdBy", "status"];

export function isValidSortField(field?: string): SortField | undefined {
  if (field && validSortFields.includes(field as SortField)) {
    return field as SortField;
  }
  return undefined;
}

export function convertPendingDocumentFiltersToSearchRequest(
  filters: PendingDocumentFilters,
  pagination?: Pagination,
) {
  const sortFields = filters.sort;

  return {
    title: filters.title,
    offset: pagination?.pageSize ? (pagination.current - 1) * pagination.pageSize : 0,
    limit: pagination?.pageSize ?? 10,
    sort: { field: sortFields.field, order: sortFields.order },
  };
}
