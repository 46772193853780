import { UseMutationOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  CreateCommentMutation,
  CreateCommentMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const createComment = graphql(`
  mutation createComment($request: CreateCommentRequest!) {
    createComment(CreateCommentRequest: $request) {
      id
      content
      companyId
      objectId
      createdAt
      updatedAt
    }
  }
`);

export function useCreateComment(
  options?: UseMutationOptions<
    CreateCommentMutation,
    unknown,
    CreateCommentMutationVariables,
    unknown
  >,
) {
  const { data, ...rest } = useGraphQlMutation(createComment, {
    ...options,
  });

  return { data: data?.createComment, ...rest };
}
