import React from "react";
import styled from "@emotion/styled";

import { OpportunityDetails } from "../../../lib/hooks/api/opportunities/useOpportunity";
import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { sysTextSecondary } from "../../../lib/themes/colors";
import { Flex, Text } from "../../../styles/utility-components";
import { opportunitySavedCardContainer, opportunitySavedCardIcon } from "./shared/styles";
import OpportunitySavedEmptyState from "./OpportunitySavedEmptyState";

type Props = {
  buyers: OpportunityDetails["buyers"];
};

export default function OpportunityBuyers({ buyers }: Props) {
  const handleAddBuyer = () => {
    window.location.href = "/buyers/all-buyers";
  };

  // Buyer empty state
  if (buyers.length === 0) {
    return <OpportunitySavedEmptyState entity="buyer" onClick={handleAddBuyer} />;
  }

  const handleViewBuyer = (id: string) => () => {
    window.location.href = `/buyers/${id}`;
  };

  return (
    <Flex column gap={16}>
      {buyers.map((buyer) => (
        <BuyerCard key={buyer.id} buyer={buyer} onView={handleViewBuyer(buyer.id)} />
      ))}
    </Flex>
  );
}

function BuyerCard({
  buyer,
  onView,
}: {
  buyer: OpportunityDetails["buyers"][0];
  onView: () => void;
}) {
  return (
    <Container onClick={onView}>
      <Flex gap={12}>
        <Icon>
          <Logo url={buyer.logoUrl} />
        </Icon>
        <Flex column gap={2}>
          <Text>{buyer.name || "Untitled Buyer"}</Text>
          {hasCategories(buyer) && (
            <CategoryLabel
              categoryName={buyer.categoryAssignments[0].categoryName}
              parentCategoryName={buyer.categoryAssignments[0].parentCategoryName}
            />
          )}
        </Flex>
      </Flex>
    </Container>
  );
}

function Logo({ url }: { url?: string | null }) {
  return url ? (
    <img aria-label="buyer-logo" src={url} height={24} width={24} />
  ) : (
    <UIcon icon="wallet02" color={sysTextSecondary} />
  );
}

type BuyerWithCategory = {
  id: string;
  name: string;
  categoryAssignments: {
    categoryName: string;
    parentCategoryName: string;
  }[];
};

function hasCategories(buyer: OpportunityDetails["buyers"][0]): buyer is BuyerWithCategory {
  return !!buyer.categoryAssignments && buyer.categoryAssignments.length > 0;
}

function CategoryLabel({
  categoryName,
  parentCategoryName,
}: {
  categoryName: string;
  parentCategoryName?: string;
}) {
  return (
    <CategoryWrapper>
      {parentCategoryName && (
        <>
          <Text inline description color={sysTextSecondary}>
            {parentCategoryName}
          </Text>
          <Divider>&gt;</Divider>
        </>
      )}
      <Text inline description color={sysTextSecondary}>
        {categoryName}
      </Text>
    </CategoryWrapper>
  );
}

const Container = styled(Flex)(({ theme }) => ({
  height: 82,

  ...opportunitySavedCardContainer(theme),
  justifyContent: "flex-start",
}));

const Icon = styled(Flex)(({ theme }) => ({
  ...opportunitySavedCardIcon(theme),
}));

const CategoryWrapper = styled.div(({ theme }) => ({
  color: theme.colors.sysTextSecondary,
  fontSize: 12,
  fontWeight: 400,
  textOverflow: "ellipsis",
  overflow: "hidden",
}));

const Divider = styled.span(() => ({
  margin: "0px 4px",
}));
