import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "../../../generated/app-service-gql";
import { SignalSettingsQuery } from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const signalSettingsQuery = graphql(`
  query signalSettings($includeNotices: Boolean!, $includeAliases: Boolean!) {
    signalSettings {
      countries
      languages
      keywords
      cpvCodes
      competitors {
        id
        name
        noticeCount @include(if: $includeNotices)
        aliases @include(if: $includeAliases)
        countryCode
      }
      partners {
        id
        name
        noticeCount @include(if: $includeNotices)
        aliases @include(if: $includeAliases)
        countryCode
      }
    }
  }
`);

export type SignalSettings = SignalSettingsQuery["signalSettings"];
export function useSignalSettingsGQL(
  { includeNotices, includeAliases }: { includeNotices: boolean; includeAliases: boolean } = {
    includeNotices: false,
    includeAliases: false,
  },
  options?: UseQueryOptions<SignalSettingsQuery, unknown, SignalSettingsQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["signalSettings", includeNotices, includeAliases],
    signalSettingsQuery,
    [{ includeNotices, includeAliases }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return { data: data?.signalSettings, ...rest };
}
