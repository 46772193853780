import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button } from "antd5";

import { NoticeDocument } from "../../../lib/hooks/api/bid/useNoticeDocuments";
import FileInput from "../../form_components/FileInput";
import { FileUploadProgress } from "./FileUploadProgress";

import css from "./NoticeDocumentUpload.module.scss";

type Props = { procurementStageId: string; onComplete: () => void; documents?: NoticeDocument[] };

type UploadForm = {
  files: File[];
  procurementStageId: string;
};

export function NoticeDocumentUpload({ procurementStageId, onComplete, documents }: Props) {
  const [files, setFiles] = useState<File[]>();

  if (documents && documents.length > 0 && !files) {
    return (
      <div className={css.container}>
        <h3>Uploaded documents</h3>
        {documents.map((doc) => (
          <div key={doc.id}>
            <h4>{doc.name}</h4>
          </div>
        ))}
        <div className={css.formActions}>
          <Button onClick={() => setFiles([])}>Upload more documents</Button>
          <Button onClick={onComplete} type="primary">
            Continue
          </Button>
        </div>
      </div>
    );
  }

  if (!files || files.length === 0) {
    return (
      <div className={css.container}>
        <NoticeDocumentUploadForm
          procurementStageId={procurementStageId}
          onSubmit={(values) => setFiles(values.files)}
        />
      </div>
    );
  }

  return (
    <div className={css.container}>
      <FileUploadProgress
        procurementStageId={procurementStageId}
        files={files}
        onComplete={onComplete}
      />
    </div>
  );
}
// TODO: split these out and add error hnadling retires etc.
type FormProps = { procurementStageId: string; onSubmit: (values: UploadForm) => void };
function NoticeDocumentUploadForm({ procurementStageId, onSubmit }: FormProps) {
  const { control, reset, handleSubmit } = useForm<UploadForm>({
    defaultValues: { files: [], procurementStageId },
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={css.form}>
      <Controller control={control} name="files" render={({ field }) => <FileInput {...field} />} />
      <div className={css.formActions}>
        <Button type="primary" htmlType="submit">
          Upload
        </Button>
        <Button onClick={() => reset()}>Reset</Button>
      </div>
    </form>
  );
}
