import React, { useCallback } from "react";
import { App, Button } from "antd5";

import { DetailsContent } from "components/app_layout/DetailsLayout";
import { BuyerDetails } from "lib/types/models";
import BuyerLinkWithLogo from "../../components/buyer_details/BuyerLinkWithLogo";
import { useStotlesApi } from "../../lib/stotlesApiContext";

import css from "./BuyerRedirectMessage.module.scss";

export type Props = {
  buyer: BuyerDetails;
  resolvedBuyers: BuyerDetails[];
};

function BuyerRedirectPage({ buyer, resolvedBuyers }: Props): JSX.Element {
  const { message } = App.useApp();
  const api = useStotlesApi();

  const reportBuyerRelationship = useCallback(async () => {
    try {
      await api.reportBuyerRelationship(buyer.id);
      void message.success("Thank you, we have received your report and will review it soon.");
    } catch {
      void message.error("We apologise, there was an issue submitting your report.");
    }
  }, [api, buyer.id, message]);

  return (
    <DetailsContent className={css.container}>
      <h1>Uh-oh!</h1>
      <h2>You are trying to visit an archived buyer.</h2>
      <p>
        <strong>{buyer.name}</strong> has been split into the following:
      </p>
      <div className={css.resolvedBuyers}>
        {resolvedBuyers.map((resolvedBuyer) => (
          <BuyerLinkWithLogo buyer={resolvedBuyer} key={resolvedBuyer.guid} />
        ))}
      </div>

      <div className={css.reportMessage}>
        Doesn't seem right?{" "}
        <Button onClick={reportBuyerRelationship} className={css.reportButton} type="link">
          Click here
        </Button>
        to let us know.
      </div>
    </DetailsContent>
  );
}

export default BuyerRedirectPage;
