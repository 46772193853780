import * as React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd5";

import FeedUpdateProgressBar from "components/onboarding/FeedUpdateProgressBar";
import { routes } from "lib/routes";

import css from "./modals.module.scss";

type DiscardModalProps = { isOpen: boolean; onClose: () => void; onReset: () => void };

export function DiscardQueryModal({ isOpen, onClose, onReset }: DiscardModalProps): JSX.Element {
  const footer = React.useMemo(
    () => [
      <Button key="cancel" onClick={onClose}>
        Return to editor
      </Button>,
      <Button
        key="reset"
        type="primary"
        danger
        onClick={() => {
          onReset();
          onClose();
        }}
      >
        Discard changes
      </Button>,
    ],
    [onClose, onReset],
  );

  return (
    <Modal open={isOpen} closable onCancel={onClose} footer={footer} title="Discard changes?">
      <p>
        Would you like to discard all changes you have made to your feed settings? This action
        cannot be undone.
      </p>
    </Modal>
  );
}

type SaveModalProps = {
  isOpen: boolean;
  onClose: (shouldSave: boolean) => void;
};

export function SaveQueryModal({ isOpen, onClose }: SaveModalProps): JSX.Element {
  const footer = React.useMemo(
    () => [
      <Button key="cancel" onClick={() => onClose(false)}>
        Cancel
      </Button>,
      <Button
        key="reset"
        type="primary"
        onClick={() => {
          onClose(true);
        }}
      >
        Save changes
      </Button>,
    ],
    [onClose],
  );

  return (
    <Modal
      open={isOpen}
      closable
      onCancel={() => onClose(false)}
      footer={footer}
      title={
        <div className={css.header}>
          <InfoCircleOutlined className={css.info} />
          <span>Changing this will automatically update your saved views</span>
        </div>
      }
      centered
    >
      <p className={css.content}>
        These changes will impact your whole team. We recommend letting your colleagues know before
        you make any changes.
      </p>
    </Modal>
  );
}

type FeedUpdateProgressModalProps = {
  isOpen: boolean;
  onClose: () => void;
  jobId?: string;
  isPartialUpdate?: boolean;
};

export function FeedUpdateProgressModal({
  jobId,
  isOpen,
  isPartialUpdate,
  onClose,
}: FeedUpdateProgressModalProps): JSX.Element {
  const [isFinished, setIsFinished] = React.useState(false);
  const handleProgressComplete = React.useCallback(() => {
    setIsFinished(true);
  }, []);

  const handleGoToFeed = React.useCallback(() => {
    window.location.href = routes.notices;
  }, []);

  return (
    <Modal
      maskClosable={isFinished}
      open={isOpen}
      closable={isFinished}
      onCancel={isFinished ? onClose : undefined}
      destroyOnClose
      afterClose={() => setIsFinished(false)}
      footer={
        isFinished
          ? [
              <Button key="cancel" onClick={onClose} disabled={!isFinished}>
                Close
              </Button>,
              <Button
                key="go_to_my_feed"
                type="primary"
                onClick={handleGoToFeed}
                disabled={!isFinished}
              >
                Go to notices
              </Button>,
            ]
          : null
      }
      title={isFinished ? "" : "Updating your signals"}
      centered
    >
      {isPartialUpdate && isFinished ? (
        <div>
          <h1 className={css.completeTitle}>Signal settings updated 🎉</h1>
          <p className={css.completeContent}>
            Your signals have been updated. Be aware we have populated data from the past 6 months
            first. We will keep processing the remaining historical data in the background.
          </p>
        </div>
      ) : (
        <>
          <p>We are applying your signal changes across the app. This should only take a moment.</p>
          <FeedUpdateProgressBar onComplete={handleProgressComplete} jobId={jobId} />
        </>
      )}
    </Modal>
  );
}
