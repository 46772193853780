import { useQueryClient } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  CreditsStatusSubscription,
  CreditsStatusSubscriptionVariables,
} from "lib/generated/app-service-gql/graphql";
import { EventNames, useTracking } from "../../../tracking";
import { useSubscription } from "../useGraphQlClient";

// adding this so it generates the types
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const credits = graphql(`
  subscription creditsStatus($teamId: String!) {
    creditsStatus(teamId: $teamId) {
      creditLimitReached
      totalCredits
    }
  }
`);

type UseCreditsStatusProps = {
  teamId: string;
  onSuccess: (data: CreditsStatusSubscription) => void;
  enabled?: boolean;
};

export function useCreditsStatus({ teamId, onSuccess, enabled = true }: UseCreditsStatusProps) {
  const queryClient = useQueryClient();

  const { logEvent } = useTracking();

  return useSubscription<CreditsStatusSubscription, CreditsStatusSubscriptionVariables>(
    `
subscription creditsStatus($teamId: String!) {
      creditsStatus(teamId: $teamId) {
        creditLimitReached
        totalCredits
      }
    }
`,
    {
      teamId,
    },
    {
      onData: (data) => {
        if (data.creditsStatus.creditLimitReached) {
          void queryClient.invalidateQueries({ queryKey: ["notices"] });

          logEvent(EventNames.creditLimitReached, {
            "Data type": "AWARDS",
            "Total credits": data.creditsStatus.totalCredits,
          });

          onSuccess(data);
        }
      },
      enabled,
    },
  );
}
