import * as React from "react";
import classnames from "classnames";

import { EllipsisTooltipText, EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import MultipleEntityLinks from "./MultipleEntityLinks";

import css from "./BuyersContainer.module.scss";

type BuyerDetails = {
  id: number;
  name: string;
};

export default function BuyersContainer({
  recordName,
  buyers,
  handleClick,
  showAsLinks = true,
  className,
}: {
  recordName: string;
  buyers: BuyerDetails[];
  handleClick?: () => void;
  showAsLinks: boolean;
  className?: string;
}): JSX.Element | null {
  if (buyers.length < 1) return null;

  const buyerLinks = buyers.map((b) =>
    showAsLinks ? (
      <EllipsisTooltipTextLink
        key={b.id}
        fullText={b.name}
        linkProps={{ onClick: handleClick, to: `/buyers/${b.id}`, className }}
        linkText={b.name}
        tooltipProps={{ overlayClassName: css.tooltip }}
      />
    ) : (
      <>
        <EllipsisTooltipText
          key={b.id}
          fullText={b.name}
          tooltipProps={{ overlayClassName: css.tooltip }}
          childText={b.name}
        />
      </>
    ),
  );

  return (
    <div className={classnames(css.buyerContainer)}>
      <MultipleEntityLinks title={`Buyers for "${recordName}"`} links={buyerLinks} />
    </div>
  );
}
