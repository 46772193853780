import React, { PropsWithChildren, useContext, useEffect, useMemo } from "react";
import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from "uuid";

import {
  DefaultSubscriptionProvider,
  getWindowSubscriptionProvider,
} from "./providers/Subscription";
import { DataTypeName, User } from "./types/models";

/**
 * We have to handle the case when we haven't loaded amplitude at all (in development)
 * but also we should call window.amplitude.getInstace() only after its javascript is loaded
 * @returns the amplitude client
 */
function getAmplitudeInstance() {
  return window.amplitude;
}

export enum EventNames {
  navToSourceDocs = "Navigated to source docs",
  searchPerformed = "Search Performed",
  exportedData = "Exported Data",
  descriptionBoxOpened = "Description Preview Box Opened",
  shareEmailModalOpen = "Share via email modal opened",
  shareEmailSent = "Records shared via email",
  resizeTableColumn = "Resized table column",
  clickedLetsChat = `Clicked "Let's Chat" button`,
  paywallViewed = `Viewed paywall`,
  clickedRequestPro = "See Stotles Pro in action",
  userAccountCreated = "Onboarding: New user account created",
  recordQueryCreated = "Onboarding: New record query created",
  recordQueryUpdated = "Existing record query updated",
  customKeywordAdded = "Onboarding: Custom keyword added",
  clickedSignUpCTA = 'Clicked "Sign Up" CTA',
  clickedAlreadyVerified = 'Onboarding: Clicked "Already Verified?" button',
  clickedNoEmailReceived = 'Onboarding: Clicked "Didn\'t get an email?" button',
  clickedReSendEmail = 'Onboarding: Clicked "Re-send" email button',
  clickedHelpCentre = 'Clicked "Help centre" link',
  clickedPlaybook = "Playbook link clicked",
  feedPreviewFilterButtonClicked = "Feed preview filter button clicked",
  feedPreviewFiltered = "Feed preview filter applied",
  viewSelected = "View selected",
  viewCreated = "View created",
  viewUpdated = "View updated",
  viewDeleted = "View deleted",
  columnEdit = "Columns edited",
  allViewsTableOpened = "All views table opened",
  userInviteResent = "User invite re-sent",
  accountBriefingBtnClicked = "Get account briefing button clicked",
  listDeleted = "List deleted",
  listCreated = "List created",
  listUpdated = "List updated",
  listDuplicated = "List duplicated",
  recordAddedToList = "Record added to list",
  recordRemovedFromList = "Record removed from list",
  clickedWhatsNew = "Page View: What's new",
  summaryViewChanged = "Summary view changed",
  summaryTimelineChanged = "Summary timeline changed",
  buyerUrlClicked = "Buyer URL clicked",
  seeMatchingExpiriesClicked = " See expiries matching my settings clicked",
  tabChanged = "Tab changed",
  emailCopied = "Email copied to clipboard",
  phoneNumberCopied = "Phone copied to clipboard",
  paginationSelected = "Pagination selected",
  linkedinClicked = "LinkedIn clicked",
  reportRecordListItemClicked = "Record list item clicked",
  reportLinkedListItemClicked = "Link list item clicked",
  cpvChartClicked = "CPV chart clicked",
  reportSupplierTableProfileClicked = "Supplier table clicked profile",
  reportSupplierTableAwardsClicked = "Supplier table clicked awards",
  reportListItemDocumentClicked = "Document list item clicked",
  recordPreviewSlideoutOpened = "Record preview slide-out opened",
  qualificationActioned = "Qualification actioned",
  notificationCentreOpened = "Notification centre opened",
  notificationClick = "Notification clicked",
  notificationMarkedAsRead = "Notification marked as read",
  notificationMarkedAsUnread = "Notification marked as unread",
  commentActioned = "Comment actioned",
  assigneeActioned = "Assignee actioned",
  documentViewMoreClicked = "View more documents clicked",
  documentViewClicked = "View documents clicked",
  documentDownloaded = "Document downloaded",
  documentSourceViewed = "Document source viewed",
  documentPreviewClicked = "Document preview clicked",
  documentNextMatchClicked = "Next match clicked",
  documentPreviousMatchClicked = "Previous match clicked",
  buyerSaved = "Buyer saved",
  buyerBulkSave = "Buyers bulk save",
  buyerBulkRemove = "Buyers bulk removed",
  buyerRemoved = "Buyer removed",
  buyerListCreated = "Buyer list created",
  buyerListDeleted = "Buyer list deleted",
  buyerListRenamed = "Buyer list renamed",
  highlightClicked = "Highlight clicked",
  filterActioned = "Filter actioned",
  filterCollapseActioned = "Filter collapse actioned",
  recordDrilldownOpened = "Record drill-down opened",
  savedViewPinned = "Saved view pinned",
  pinnedSavedViewRankChange = "Pinned saved view ranking changed",
  savedViewUnpinned = "Saved view unpinned",
  sendToCRMModalOpened = "Send to CRM modal opened",
  sendToCRMModalCancelled = "Send to CRM modal cancelled",
  sendToCRMClicked = "Send to CRM clicked",
  objectSentToCRM = "Object sent to CRM",
  viewInCRMButtonClicked = "View in CRM button clicked",
  connectToCRMButtonClicked = "Go to integrations modal opened",
  closeCRMModelButtonClicked = "Go to Integrations page closed",
  goToIntegrationsPageClicked = "Go to Integrations Page button clicked",
  connectToIntegrationClicked = "Connect to integration clicked",
  disconnectFromIntegrationClicked = "Disconnect from integration clicked",
  integrationSuccessfullyConnected = "Integration successfully connected",
  viewFeedFromIntegrationClicked = "Go to feed clicked",
  backButtonFromIntegrationClicked = "Back to integrations page clicked",
  setUpIntegrationGuidelinesClicked = "Set up integration guidelines clicked ",
  learnMoreIntegrationModalOpened = "Learn more about integration modal opened",
  fieldAddedForIntegration = "Integration form field added",
  fieldRemovedForIntegration = "Integration form field removed",
  fieldMarkedRequiredForIntegration = "Integration configuration - required field actioned",
  fieldMarkedNotRequiredForIntegration = "Mark as not required",
  settingsSavedForIntegration = "Integration settings saved",
  noticeListClicked = "Notice list clicked",
  noticeListTabChanged = "Notice list tab changed",
  buyerListClicked = "Buyer list clicked",
  groupBySelected = "Grouped by selected",
  chartClickedOn = "Chart Clicked On",
  dateUnitsSelected = "DateUnitsSelected",
  contactDetailsRequested = "Contact request form submitted",
  openOutreachModal = "Outreach modal opened",
  generateOutreachClicked = "Generate outreach clicked",
  refreshOutreachClicked = "Refresh outreach clicked",
  skipGenerateOutreachClicked = "Skip outreach generation clicked",
  outreachCopy = "Outreach copied to clipboard",
  sendOutreach = "Send via email clicked",
  productTourActioned = "Onboarding tooltip actioned",
  productTourHidden = "Onboarding tooltip hidden",
  productTourCompleted = "Onboarding tooltip marked as complete",
  userLike = "User likes",
  userDislike = "User dislikes",
  dismissFeedback = "Dimiss feedback",
  submittedFeedback = "Submitted feedback",
  clickedExternalLink = "Clicked external link",
  paywallModalOpened = "Paywall modal opened",
  paywallModalDismissed = "Paywall modal dismissed",
  paywallActioned = "Paywall actioned",
  industryReportViewed = "Industry report viewed",
  industryReportLinkCopied = "Industry report link copied to clipboard",
  customBriefingViewed = "Custom briefing viewed",
  customBriefingLinkCopied = "Custom briefing link copied to clipboard",
  customBriefingDownloaded = "Custom briefing downloaded",
  tableSorted = "Table sorted",
  freeSearch = "Free search",
  filterPanelOpened = "Filter panel opened",
  relevanceScoreClicked = "Signal score popover opened",
  relevanceScoreUpdateSettings = "Signal settings opened",
  alertModalOpened = "Set view alert modal opened",
  alertModified = "Alert modified",
  promptActioned = "Prompt actioned",
  referenceLinkOpened = "Reference link opened",
  feedbackModalOpened = "Feedback modal opened",
  negativeFeedbackSubmitted = "Negative feedback submitted",
  negativeFeedbackDismissed = "Negative feedback dismissed",
  positiveFeedbackSubmitted = "Positive feedback submitted",
  positiveFeedbackDismissed = "Positive feedback dismissed",
  newChatCreated = "Ask AI: New chat created",
  chatSelected = "Ask AI: Chat selected",
  visitStotlesClicked = "Visit stotles clicked",
  shareReportClicked = "Share report clicked",
  signUpForFreeClicked = "Sign up for free clicked",
  bookADemoClicked = "Book a demo clicked",
  bookADemoRequested = "Book a demo requested",
  confirmationMessageClosed = "Confirmation message closed",
  bookADemoCancelled = "Book a demo cancelled",
  aiReportViewed = "Page View: Report",
  shareReportModalOpened = "Share as report modal opened",
  aiReportGenerated = "Report generated",
  frameworkClicked = "Framework clicked",
  suppliersClicked = "Suppliers clicked",
  callOffClicked = "Call-offs clicked",
  breadcrumbClicked = "Breadcrumb clicked",
  noticeClicked = "Notice clicked",
  navToBuyerProfile = "Navigated to Buyer Profile",
  answerGenerated = "Answer generated",
  newTeamCreated = "New team created",
  signUpClicked = "Sign up clicked",
  joinTeamClicked = "Join team clicked",
  createNewTeamClicked = "Create new team clicked",
  contactSupportClicked = "Contact support clicked",
  joinExistingTeamClicked = "Join existing team clicked",
  joinTeamModalClosed = "Join team modal closed",
  saveSignalsModalActioned = "Save signals confirm modal actioned",
  signalsEdited = "Signals edited (Before Saving)",
  suggestedSignalRefresh = "Suggested signal refreshed",
  emailLinkClicked = "E-mail link clicked",
  documentPreviewOpened = "Document preview slideout opened",
  documentSearched = "Document searched",
  scheduleReminderModalOpened = "Schedule reminder modal opened",
  reminderSavedToCalendar = "Reminder saved to calendar",
  opportunityUpdated = "Opportunity updated",
  planUpgradeClicked = "Plan upgrade clicked",
  planDowngradeClicked = "Plan downgrade clicked",
  creditLimitReached = "Credit limit reached",
}

// Source location of where the event is triggered
// **Keep in sync with getPageSource below**

enum EventPageSources {
  myFeed = "My Feed",
  userLists = "User Lists",
  buyerSearch = "Buyer Search",
  buyerSupplierRelationship = "Buyer Supplier Relationship",
  savedBuyersPage = "Buyer lists",
  relevantBuyersPage = "Relevant Buyers",
  supplierSearch = "Supplier Search",
  recordSearch = "Record Search",
  buyerProfile = "Buyer Profile",
  buyerLists = "Buyer Lists",
  buyerList = "Buyer List",
  supplierProfile = "Supplier Profile",
  recordDetailsPage = "Record Details Page",
  feedSettingsPage = "Feed Settings Page",
  onboardingPage = "Onboarding Page",
  signUpPage = "Sign Up Page",
  accountPage = "Account Management Page",
  accountPageSubscription = "Account Management Page - Subscription",
  reportPage = "Report Page",
  partnerListPage = "My Partners List Page",
  competitorListPage = "My Competitors List Page",
  partnerProfile = "Partner Profile",
  competitorProfile = "Competitor Profile",
  viewDocumentPage = "View document page",
  reportsPage = "Reports",
  unknown = "Unknown Page",
  savedNoticesPage = "Saved Notices Page",
  outreachOpportunitiesList = "Outreach opportunities list",
  spendData = "Spend data",
  documentData = "Document data",
  contacts = "Contact search",
  relatedContacts = "Related contacts",
  outreachModal = "Outreach modal",
  getStarted = "Get started",
  userInfoForm = "UserInfoForm",
  wallOfFame = "Tender WoF",
  buyerCleaning = "Buyer cleaning",
  frameworkSearchPage = "Framework search",
  frameworkDetailsPage = "Framework details",
  guestPassBuyerProfile = "Guest Pass Buyer Profile",
  guestPassSupplierProfile = "Guest Pass Supplier Profile",
  CallOffIdentifierRulesPage = "Call off identification rules page",
  signUpAuthError = "Signup Generic Error",
  documentsToolingPage = "Documents tooling page",
  opportunitiesPage = "Opportunities page",
  opportunitiesDetailPage = "Opportunities detail page",
  homepage = "Homepage",
}

export enum AlertModificationType {
  frequency = "Frequency",
  created = "Created",
  deleted = "Deleted",
}

const OPT_OUT_DOMAINS = ["@stotles.com", "@hitechdigital.com"];

export function getPageSource(): EventPageSources {
  const urlPath = window.location.pathname;
  switch (urlPath) {
    case String(urlPath.match(/^\//)):
      return EventPageSources.homepage;
    case String(urlPath.match(/^\/account-management\/feed-settings.*/)):
      return EventPageSources.feedSettingsPage;
    case String(urlPath.match(/^\/account-management\/subscription.*/)):
      return EventPageSources.accountPageSubscription;
    case String(urlPath.match(/^\/account-management.*/)):
      return EventPageSources.accountPage;
    case String(urlPath.match(/^\/notices/)):
      return EventPageSources.myFeed;
    case String(urlPath.match(/^\/lists.*/)):
      return EventPageSources.userLists;
    case String(urlPath.match(/^\/buyers\/search.*/)):
      return EventPageSources.buyerSearch;
    case String(urlPath.match(/^\/suppliers\/search.*/)):
      return EventPageSources.supplierSearch;
    case String(urlPath.match(/^\/search.*/)):
      return EventPageSources.recordSearch;
    case String(urlPath.match(/^\/buyers\/saved\/key-accounts/)):
      return EventPageSources.savedBuyersPage;
    case String(urlPath.match(/^\/buyers\/lists/)):
      return EventPageSources.buyerLists;
    case String(urlPath.match(/^\/buyers\/lists\/.*/)):
      return EventPageSources.buyerList;
    case String(urlPath.match(/^\/buyers\/relevant-buyers.*/)):
      return EventPageSources.relevantBuyersPage;
    case String(urlPath.match(/^\/buyers\/all-buyers.*/)):
      return EventPageSources.buyerSearch; // This is TBD, it might be renamed to "All buyers"
    case String(urlPath.match(/^\/buyers\/.*/)):
      return EventPageSources.buyerProfile;
    case String(urlPath.match(/^\/suppliers\/.*/)):
      return EventPageSources.supplierProfile;
    case String(urlPath.match(/^\/records\/.*/)):
      return EventPageSources.recordDetailsPage;
    case String(urlPath.match(/^\/onboarding$/)):
    case String(urlPath.match(/^\/email-verification.*/)):
      return EventPageSources.onboardingPage;
    case String(urlPath.match(/^\/report\/.*/)):
      return EventPageSources.reportPage;
    case String(urlPath.match(/^\/partners/)):
      return EventPageSources.partnerListPage;
    case String(urlPath.match(/^\/competitors/)):
      return EventPageSources.competitorListPage;
    case String(urlPath.match(/^\/partners\/.*/)):
      return EventPageSources.partnerProfile;
    case String(urlPath.match(/^\/competitors\/.*/)):
      return EventPageSources.competitorProfile;
    case String(urlPath.match(/^\/reports\/.*/)):
      return EventPageSources.reportsPage;
    case String(urlPath.match(/^\/notices\/lists/)):
      return EventPageSources.savedNoticesPage;
    // This might need to be adjusted if we add routing for the wizard steps
    case String(urlPath.match(/^\/outreach/)):
      return EventPageSources.outreachOpportunitiesList;
    case String(urlPath.match(/^\/contacts.*/)):
      return EventPageSources.contacts;
    case String(urlPath.match(/^\/spend.*/)):
      return EventPageSources.spendData;
    case String(urlPath.match(/^\/documents.*/)):
      return EventPageSources.documentData;
    case String(urlPath.match(/^\/get-started.*/)):
      return EventPageSources.getStarted;
    case String(urlPath.match(/^\/users\/create-account/)):
      return EventPageSources.userInfoForm;
    case String(urlPath.match(/^\/users\/auth\/auth_failure/)):
      return EventPageSources.signUpAuthError;
    case String(urlPath.match(/^\/wall-of-fame.*/)):
      return EventPageSources.wallOfFame;
    case String(urlPath.match(/^\/tools\/buyer_cleaning.*/)):
      return EventPageSources.buyerCleaning;
    case String(urlPath.match(/^\/frameworks/)):
      return EventPageSources.frameworkSearchPage;
    case String(urlPath.match(/^\/frameworks\/.*/)):
      return EventPageSources.frameworkDetailsPage;
    case String(urlPath.match(/^\/guest_access\/buyers\/.*/)):
      return EventPageSources.guestPassBuyerProfile;
    case String(urlPath.match(/^\/guest_access\/suppliers\/.*/)):
      return EventPageSources.guestPassSupplierProfile;
    case String(urlPath.match(/^\/tools\/call_off_identifier_rules\/.*/)):
      return EventPageSources.CallOffIdentifierRulesPage;
    case String(urlPath.match(/^\/tools\/document_upload/)):
      return EventPageSources.documentsToolingPage;
    case String(urlPath.match(/^\/opportunities/)):
      return EventPageSources.opportunitiesPage;
    case String(urlPath.match(/^\/opportunities\/.*/)):
      return EventPageSources.opportunitiesDetailPage;
    default:
      if (process.env.NODE_ENV === "development") {
        throw new Error(`No page source matched for ${urlPath} - please specify one`);
      } else {
        Sentry.captureException(`Unknown page in tracking code: ${urlPath}`);
      }
      return EventPageSources.unknown;
  }
}

// Data type the event concerns
export enum EventDataTypes {
  record = "Record(s)",
  buyer = "Buyer(s)",
  supplier = "Supplier(s)",
  contact = "Contact(s)",
  savedView = "Saved view(s)",
  recordList = "Record List(s)",
  frameworks = "Framework(s)",
  callOff = "Call-off(s)",
  documents = "Document(s)",
}

// Attributes logged in amplitude
enum Attributes {
  isGuestUser = "Is guest user",
  companyName = "company_name",
  companyId = "company_id",
  companyGuid = "company_guid",
  companyIsDemo = "company_is_demo",
  enabledDataTypes = "enabled_data_types",
  selfServe = "self_serve",
  trialStatus = "trial_status",
  trialEnd = "trial_end",
  referrerUrl = "stotles_referrerUrl",
  firstPageVisit = "first_page_visit",
  userEmail = "email",
  userGuid = "user_guid",
  teamId = "stotles_team_id",
  teamName = "stotles_team_name",
}

/**
 * Use this only if you need to identify a user that's not logged in.
 * Otherwise `init()` will take care of this
 */
export function identifyByEmail(email: string): void {
  // Separating out the functions because in the event one of the tracking software
  // clients are not initialized properly or fail, I want the failure to be contained to only that
  // tracking software's logic
  // Was relevant when we had multiple tracking solutions in place, could be simplified now
  // but keeping it so we could easily add another tracking solution in the future
  identifyGuestUserForAmplitudeTracking(email);
}

/**
 * Identifies guest user (not logged in user) for amplitude tracking
 * @param email user's email which is used as a user ID in amplitude
 * @returns
 */
function identifyGuestUserForAmplitudeTracking(email: string) {
  const amplitudeInstance = getAmplitudeInstance();
  if (!amplitudeInstance) return;

  if (email.endsWith("@stotles.com")) {
    console.log("Opting out of amplitude for @stotles.com user");
    amplitudeInstance.setOptOut(true);
    return;
  } else {
    amplitudeInstance.setUserId(email);
  }
  if (window.guestUser) {
    const identify = new window.amplitude.Identify();
    identify.set("Is guest user", true);
    amplitudeInstance.identify(identify);
  }
}

/**
 * Function initializes amplitude tracking of users
 * @returns
 */
export function init(): void {
  try {
    const { currentUser } = window;
    if (!currentUser) {
      console.log("Cannot initialize tracking as there is no user present");
      return;
    } else {
      // Calling these methods here so the methods only have to be called once
      // Was more relevant when we had multiple tracking solutions in place
      const subscriptionProvider = getWindowSubscriptionProvider();
      const dataTypeSubs = currentUser.data_type_subscriptions
        .filter((s) => !s.disabled)
        .map((s) => s.data_type_name);

      identifyAmplitudeAttributes(currentUser, subscriptionProvider, dataTypeSubs);
    }
  } catch (e) {
    // Make sure that tracking exceptions don't break the website
    Sentry.captureException(e);
  }
}

/**
 * Initializes amplitude tracking. Identifies the attributes of a user and stores these
 * values in the amplitude instance
 * @param amplitudeInstance the amplitude client object
 * @returns
 */
function identifyAmplitudeAttributes(
  currentUser: User,
  subscriptionProvider: DefaultSubscriptionProvider,
  dataTypeSubs: DataTypeName[],
) {
  const amplitudeInstance = getAmplitudeInstance();
  if (!amplitudeInstance) {
    console.debug("No amplitude instance - amplitude tracking disabled?");
    return;
  }
  amplitudeInstance.setUserId(currentUser.email);
  const identify = new window.amplitude.Identify();
  identify.set(Attributes.userGuid, currentUser.guid);

  const currentUserCompany = currentUser.company;
  identify.set(Attributes.companyName, currentUserCompany.name);
  identify.set(Attributes.companyId, currentUserCompany.id);
  identify.set(Attributes.companyGuid, currentUserCompany.guid);
  identify.set(Attributes.companyIsDemo, currentUserCompany.demo ? true : false);
  identify.set(Attributes.enabledDataTypes, dataTypeSubs);
  identify.set(Attributes.trialStatus, subscriptionProvider.trialStatus());
  identify.set(Attributes.teamId, currentUser.team.id);
  identify.set(Attributes.teamName, currentUser.team.name);
  if (currentUserCompany.trial_end) {
    identify.set(Attributes.trialEnd, currentUserCompany.trial_end);
  }
  if (currentUser.signup_utm?.referrer) {
    identify.set(Attributes.referrerUrl, currentUser.signup_utm?.referrer);
  }
  if (currentUser.signup_utm?.url) {
    identify.set(Attributes.firstPageVisit, currentUser.signup_utm?.url);
  }

  if (currentUser.signup_utm?.utmParams) {
    Object.entries(currentUser.signup_utm.utmParams).forEach(([k, v]) => {
      if (!v) {
        return;
      }
      identify.set(`stotles_utm_${k}`, v);
    });
  }

  identify.unset(Attributes.isGuestUser);
  if (currentUserCompany.analytics_tags) {
    // TODO: We could be calling amplitude API when this data is changed in our DB
    for (const [tag, value] of Object.entries(currentUserCompany.analytics_tags)) {
      identify.set(tag, value);
    }
  }
  amplitudeInstance.identify(identify);

  const optOutDomain = OPT_OUT_DOMAINS.find((domain) => currentUser.email.endsWith(domain));
  if (optOutDomain) {
    console.log(`Opting out of amplitude for ${optOutDomain} user`);
    amplitudeInstance.setOptOut(true);
  }
}

export function logout(): void {
  const amplitudeInstance = getAmplitudeInstance();

  if (!amplitudeInstance) {
    return;
  }
  amplitudeInstance.setUserId(undefined);
  amplitudeInstance.setDeviceId(uuidv4());
}

function trackCapteraConversion(config: NonNullable<typeof window.capteraConfig>): Promise<void> {
  return new Promise((accept) => {
    // Based on https://www.capterra.com/vp/vendors/conversion_tracking
    const ct = document.createElement("img");
    ct.src =
      "https://ct.capterra.com/capterra_tracker.gif?vid=" +
      config.vendroId +
      "&vkey=" +
      config.vendorKey;
    ct.addEventListener("load", () => accept());
    document.body.appendChild(ct);
  });
}

export type EventData = {
  "Page source"?: string;
  "Context source"?: string;
  [key: string]: unknown | undefined;
};

export function logEvent(eventName: EventNames, data: EventData): void {
  if (!data["Page source"]) {
    data["Page source"] = getPageSource();
  }

  if (process.env.NODE_ENV === "development") {
    console.log(`Event: '${eventName}' with data '${JSON.stringify(data)}'`);
  }

  // Tracking the event in amplitude
  const amplitudeInstance = getAmplitudeInstance();
  if (amplitudeInstance) {
    amplitudeInstance.track(eventName, data);
  }
}

function sendGtagEvent(eventName: string, eventParameters: Record<string, unknown>): Promise<void> {
  const { gtag } = window;
  if (gtag) {
    return new Promise((accept) =>
      // event_callback: see https://developers.google.com/tag-platform/gtagjs/reference/parameters
      gtag("event", eventName, { ...eventParameters, event_callback: accept }),
    );
  } else {
    return Promise.resolve();
  }
}

export async function logUserConversion(): Promise<void> {
  // Conversion for linkedin campaigns
  if (window.lintrk) {
    window.lintrk("track", { conversion_id: 5410305 });
  }

  await sendGtagEvent("conversion", { send_to: "AW-776917579/Vml4CN-Hw94CEMuku_IC" });

  if (window.capteraConfig) {
    await trackCapteraConversion(window.capteraConfig);
  }
}

/**
 * log a page view
 *
 * TODO: ideally don't export this function, so that most pages don't have to worry about logging
 * page views (should be automatic ideally)
 *
 * @param pageName string | undefined TODO: remove, and use getPageSource()
 * @param params {} extra data
 */
export function pageView(pageName: string | undefined, params: Record<string, unknown>): void {
  const amplitudeInstance = getAmplitudeInstance();
  pageName = pageName || getPageSource();

  if (process.env.NODE_ENV === "development") {
    console.log(`Page View: ${pageName}`, params);
  }

  if (amplitudeInstance) {
    amplitudeInstance.track(`Page View: ${pageName}`, params);
  }
}

export const usePageView = (pageName: string, params: Record<string, unknown>): void => {
  useEffect(() => {
    pageView(pageName, params);
  }, [pageName, params]);
};

const TrackingContext = React.createContext({ data: {} });

type TrackingProviderProps = PropsWithChildren<{
  data: EventData;
}>;

export function TrackingProvider({ data, children }: TrackingProviderProps): JSX.Element {
  const { data: inheritedData } = useContext(TrackingContext);
  return (
    <TrackingContext.Provider value={{ data: { ...inheritedData, ...data } }}>
      {children}
    </TrackingContext.Provider>
  );
}

export type TrackingEvent = {
  name: EventNames;
  data?: EventData;
};

export type Tracking = {
  logEvent: (eventName: EventNames, data?: EventData) => void;
  logEvents: (...events: TrackingEvent[]) => void;
  trackingData: EventData;
};

export function useTracking(): Tracking {
  const { data: inheritedData } = useContext(TrackingContext);
  return useMemo(() => {
    return {
      logEvent: (eventName: EventNames, data?: EventData) =>
        logEvent(eventName, { ...inheritedData, ...data }),
      logEvents: (...events: TrackingEvent[]) => {
        for (const { name, data } of events) {
          logEvent(name, { ...inheritedData, ...data });
        }
      },
      trackingData: inheritedData,
    };
  }, [inheritedData]);
}
