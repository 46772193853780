import React, { useMemo, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd5";
import _ from "lodash";

import {
  convertPendingDocumentFiltersToSearchRequest,
  DEFAULT_PAGINATION,
  DEFAULT_PENDING_DOCUMENT_FILTERS,
  PendingDocumentFilters,
} from "components/documents/pendingDocumentsUtils";
import { createUseDebounce } from "lib/debounce";
import { Organisation } from "lib/generated/app-service-gql/graphql";
import { usePendingDocuments } from "lib/hooks/api/documents/usePendingDocuments";
import { useOrganisations } from "lib/hooks/api/organisations/useOrganisations";
import { useSearchAdminUsersGql } from "lib/hooks/api/users/useSearchAdminUsersGql";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { isDefined } from "lib/utils";
import { PendingDocument, PendingDocumentsTable } from "./PendingDocumentsTable";

import css from "./FilterablePendingDocuments.module.scss";

type FilterableDocumentProps = {
  defaultFilters?: PendingDocumentFilters;
};

export function FilterablePendingDocuments({
  defaultFilters = DEFAULT_PENDING_DOCUMENT_FILTERS,
}: FilterableDocumentProps) {
  const [filters, setFilters] = useState<PendingDocumentFilters>(defaultFilters);

  const [documentTitle, setDocumentTitle] = useState(filters.title);

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const { authorised: hasDocumentsDataType } = useCheckSubscription("DOCUMENTS");

  const { data, isLoading, isError } = usePendingDocuments({
    ...convertPendingDocumentFiltersToSearchRequest(filters, pagination),
  });

  // get user objects for documents
  const userGuids = useMemo(
    () =>
      data?.results ? _.uniq(data?.results?.map((d) => d.uploadCreatedBy).filter(isDefined)) : [],
    [data],
  );

  const { data: users } = useSearchAdminUsersGql(
    {
      guid: userGuids,
    },
    { enabled: userGuids.length > 0 },
  );

  // get organisation objects for documents
  const allOrgs = useMemo(() => {
    return data?.results
      ? _.uniq(data?.results?.flatMap((d) => d.organisations).filter(isDefined))
      : [];
  }, [data]);

  const { data: orgs } = useOrganisations(allOrgs, {
    enabled: allOrgs.length > 0,
  });

  // replace org Ids with Organisation objects
  const documents = useMemo(() => {
    if (!data || !data?.results?.length) return [] as PendingDocument[];

    // map users
    const usersMap = new Map<string, string>();
    if (users) {
      users.map((u) => usersMap.set(u.guid, u.email));
    }

    // map orgs
    const orgsMap = new Map<string, Organisation>();
    if (orgs) {
      orgs.organisations.map((o) => orgsMap.set(o.id, o));
    }

    const docs = data.results.map((doc): PendingDocument => {
      const orgs = doc.organisations.map((o) => orgsMap.get(o)).filter(isDefined);
      const createdBy = usersMap.get(doc.uploadCreatedBy);
      return { ...doc, organisations: orgs, uploadCreatedBy: createdBy } as PendingDocument;
    });
    return docs;
  }, [data, orgs, users]);

  const useDebounce = createUseDebounce(500);
  const debouncedSetFilters = useDebounce((filters: PendingDocumentFilters) => {
    setFilters(filters);
    setDocumentTitle(filters.title);
  });

  if (!data?.results?.length && !documentTitle.length && !isLoading && !isError) {
    return <></>;
  }

  return (
    <div className={css.container}>
      {
        <>
          <div className={css.actionsContainer}>
            <h2>Pending Document Uploads</h2>
            <div className={css.actions}>
              <Input
                placeholder="Search pending document by title or file name"
                prefix={<SearchOutlined />}
                value={documentTitle}
                onChange={(e) => {
                  setDocumentTitle(e.target.value);
                  debouncedSetFilters({ ...filters, title: e.target.value });
                }}
                allowClear
                className={css.searchInput}
                data-hj-allow
              />
            </div>
          </div>
          <PendingDocumentsTable
            filters={filters}
            documents={{ results: documents, totalResults: data?.totalResults || 0 }}
            hasDocumentsDataType={hasDocumentsDataType}
            isLoading={isLoading}
            isError={isError}
            pagination={pagination}
            setPagination={setPagination}
            onSortChange={(sort) => setFilters({ ...filters, sort })}
          />
        </>
      }
    </div>
  );
}
