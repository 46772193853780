import React from "react";
import { Skeleton } from "antd5";
import { Flex, Text } from "styles/utility-components";

import { useBidDocuments } from "lib/hooks/api/bid/useBidDocuments";
import UIcon from "lib/icons/untitled_ui/UIcon";

import css from "./DocumentSlideout.module.scss";

export function DocumentSlideout({ bidId }: { bidId: string }) {
  const { data, isLoading } = useBidDocuments({ bidId });

  return (
    <div className={css.tenderDocuments}>
      <Text h2>Tender documents ({data?.items?.length ?? 0})</Text>
      <Flex flexDirection="column">
        {isLoading ? (
          <Skeleton style={{ marginTop: "24px" }}></Skeleton>
        ) : (
          data?.items.map((doc) => (
            <div key={doc.id} className={css.item}>
              <UIcon icon="fileAttachment" className={css.icon} />
              <span className="text-gray-700 text-sm break-words">{doc.fileName}</span>
            </div>
          ))
        )}
      </Flex>
    </div>
  );
}
