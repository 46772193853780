import React, { useMemo } from "react";
import { Pagination } from "antd5";
import { isEqual } from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";

import {
  DEFAULT_PAGINATION,
  isValidSortField,
  PendingDocumentFilters,
} from "components/documents/pendingDocumentsUtils";
import { getDocumentTypeLabel } from "components/documents/utils";
import OrganisationsContainer from "components/record_details/OrganisationsPopover";
import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import { ColumnType } from "lib/core_components/Table/ColumnTypes";
import { Table } from "lib/core_components/Table/Table";
import { Organisation } from "lib/generated/app-service-gql/graphql";
import { ListPendingUploads200ResponseResultsInner } from "lib/generated/data-svc";
import DocumentIcon from "lib/icons/DocumentIcon";
import PaywallStar from "lib/icons/PaywallStar";

import css from "./PendingDocumentsTable.module.scss";

// replace with Organisation object
export type PendingDocument = Omit<ListPendingUploads200ResponseResultsInner, "organisations"> & {
  organisations: Organisation[];
};

type PendingDocumentsResponse = { results: PendingDocument[]; totalResults: number };

type SortState = Exclude<PendingDocumentFilters["sort"], undefined>;

type Props = {
  filters: PendingDocumentFilters;
  onSortChange: (sort: SortState) => void;
  pagination: { current: number; pageSize: number };
  documents: PendingDocumentsResponse;
  isLoading: boolean;
  isError: boolean;
  setPagination: (pagination: { current: number; pageSize: number }) => void;
  hasDocumentsDataType: boolean;
};

function getSortOrder(key: string, sort?: SortState): "ascend" | "descend" | null {
  return sort?.field !== key ? null : sort.order === "ASC" ? "ascend" : "descend";
}

function getColumns({
  filters,
  hasDocumentsDataType,
}: {
  filters: PendingDocumentFilters;
  hasDocumentsDataType: boolean;
}): ColumnType<PendingDocument>[] {
  const sort = filters.sort;

  const id: ColumnType<PendingDocument> = {
    title: "Task id",
    dataIndex: "uploadTaskId",
    key: "uploadTaskId",
    render: (_, d) => <span>{d.uploadTaskId}</span>,
    sorter: false,
    showSorterTooltip: false,
  };

  const status: ColumnType<PendingDocument> = {
    title: "Status",
    dataIndex: "uploadStatus",
    key: "status",
    render: (_, d) => <span>{d.uploadStatus}</span>,
    sorter: true,
    sortOrder: getSortOrder("status", sort),
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: false,
  };

  const title: ColumnType<PendingDocument> = {
    title: "Document title",
    dataIndex: "title",
    key: "title",
    render: (_, d) => (
      <div className={css.titleColumn}>
        <div className={css.titleContainer}>
          <DocumentIcon className={css.documentIcon} />
          <div className={css.title}>{d.title && d.title !== "" ? d.title : "--"} </div>
        </div>
        {!hasDocumentsDataType && <PaywallStar className={css.paywallStar} />}
      </div>
    ),
    sorter: false,
  };

  const fileName: ColumnType<PendingDocument> = {
    title: "File Name",
    dataIndex: "fileName",
    key: "fileName",
    render: (_, d) => <span>{d.fileName ?? "-"}</span>,
    sorter: false,
  };

  const category: ColumnType<PendingDocument> = {
    title: "Type",
    dataIndex: "category",
    key: "category",
    render: (_, d) => <span>{d.category ? getDocumentTypeLabel(d.category) : "-"}</span>,
    sorter: false,
  };

  const createdBy: ColumnType<PendingDocument> = {
    title: "Created By",
    dataIndex: "uploadCreatedBy",
    key: "createdBy",
    render: (_, d) => <span>{d.uploadCreatedBy ?? "-"}</span>,
    sorter: true,
    sortOrder: getSortOrder("createdBy", sort),
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: false,
  };

  const createdAt: ColumnType<PendingDocument> = {
    title: "Uploaded",
    key: "createdAt",
    render: (d) => (
      <div className={css.dateCell}>{d.uploadCreatedAt.replace("T", ` `).split(`.`)[0]}</div>
    ),
    sorter: true,
    sortOrder: getSortOrder("createdAt", sort),
    sortDirections: ["descend", "ascend", "descend"],
    showSorterTooltip: false,
  };

  const updatedAt: ColumnType<PendingDocument> = {
    title: "Upload last updated",
    key: "updatedAt",
    render: (d) => (
      <div className={css.dateCell}>{d.uploadUpdatedAt.replace("T", ` `).split(`.`)[0]}</div>
    ),
    sorter: true,
    sortOrder: getSortOrder("updatedAt", sort),
    sortDirections: ["descend", "ascend", "descend"],
    showSorterTooltip: false,
  };

  const publishedAt: ColumnType<PendingDocument> = {
    title: "Document published",
    key: "publishedAt",
    render: (d) => (
      <div className={css.dateCell}>{d.publishedAt?.replace("T", ` `).split(`.`)[0]}</div>
    ),
    sorter: false,
  };

  const sourceUrl: ColumnType<PendingDocument> = {
    title: "Source URL",
    key: "sourceUrl",
    render: (d) => (
      <div>
        {d.sourceUrl && (
          <EllipsisTooltipTextLink
            fullText={d.sourceUrl}
            linkText={"Source"}
            linkProps={{ to: d.sourceUrl }}
          />
        )}
        <br />
        {d.alternativeSource && (
          <EllipsisTooltipTextLink
            fullText={d.alternativeSource}
            linkText={"Alternative Source"}
            linkProps={{ to: d.alternativeSource }}
          />
        )}
      </div>
    ),
    sorter: false,
    showSorterTooltip: false,
  };

  const buyers: ColumnType<PendingDocument> = {
    title: "Buyers",
    key: "buyers",
    render: (d) =>
      d.organisations.length ? (
        <OrganisationsContainer orgs={d.organisations} orgType={"buyers"} />
      ) : (
        <></>
      ),
    sorter: false,
  };

  return [
    status,
    title,
    fileName,
    category,
    buyers,
    createdBy,
    createdAt,
    updatedAt,
    publishedAt,
    sourceUrl,
    id,
  ];
}

export function PendingDocumentsTable({
  filters,
  documents,
  isLoading,
  isError,
  hasDocumentsDataType,
  pagination,
  setPagination,
  onSortChange,
}: Props) {
  const columns = useMemo(() => {
    return getColumns({
      filters,
      hasDocumentsDataType,
    });
  }, [filters, hasDocumentsDataType]);

  useDeepCompareEffect(() => {
    setPagination(DEFAULT_PAGINATION);
  }, [filters]);

  return (
    <div>
      <Table<PendingDocument>
        ariaLabel="Document Table"
        dataSource={documents?.results || []}
        loading={isLoading}
        columns={columns}
        showSorterTooltip={false}
        rowKey="id"
        onChange={(_, __, sorter) => {
          if (Array.isArray(sorter)) {
            sorter = sorter[0];
          }
          // If the sort order is not defined, we don't want to trigger a sort without an order
          if (!filters.sort && !sorter.order) {
            return;
          }

          const sortField = isValidSortField(sorter.columnKey?.toString());
          if (sortField) {
            const newSortState: SortState = {
              order: sorter.order === "descend" ? "DESC" : "ASC",
              field: sortField,
            };

            if (!isEqual(filters.sort, newSortState)) {
              onSortChange?.(newSortState);
            }
          }
        }}
        isError={isError}
      />
      <Pagination
        className={css.pagination}
        current={pagination.current}
        defaultPageSize={pagination.pageSize}
        total={documents?.totalResults || 0}
        onChange={(page: number, pageSize: number) => {
          setPagination({ current: page, pageSize: pageSize });
        }}
      />
    </div>
  );
}
