export type OptionItem = {
  code: string;
  name: string;
  description?: string;
};

export const langCodes: OptionItem[] = [
  { name: "English", code: "EN" },
  { name: "German", code: "DE" },
  { name: "French", code: "FR" },
  { name: "Polish", code: "PL" },
  { name: "Spanish", code: "ES" },
  { name: "Italian", code: "IT" },
  { name: "Czech", code: "CS" },
  { name: "Dutch", code: "NL" },
  { name: "Swedish", code: "SV" },
  { name: "Romanian", code: "RO" },
  { name: "Bulgarian", code: "BG" },
  { name: "Finnish", code: "FI" },
  { name: "Slovenian", code: "SL" },
  { name: "Lithuanian", code: "LT" },
  { name: "Portuguese", code: "PT" },
  { name: "Greek", code: "EL" },
  { name: "Hungarian", code: "HU" },
  { name: "Danish", code: "DA" },
  { name: "Croatian", code: "HR" },
  { name: "Latvian", code: "LV" },
  { name: "Slovak", code: "SK" },
  { name: "Estonian", code: "ET" },
  { name: "Welsh", code: "CY" },
  { name: "Irish", code: "GA" },
];

export const countryCodes: OptionItem[] = [
  {
    code: "AF",
    name: "Afghanistan",
    description: "Country in Asia",
  },
  {
    code: "AX",
    name: "Åland Islands",
    description: "Country in Europe",
  },
  {
    code: "AL",
    name: "Albania",
    description: "Country in Europe",
  },
  {
    code: "DZ",
    name: "Algeria",
    description: "Country in Africa",
  },
  {
    code: "AS",
    name: "American Samoa",
    description: "Country in Oceania",
  },
  {
    code: "AD",
    name: "Andorra",
    description: "Country in Europe",
  },
  {
    code: "AO",
    name: "Angola",
    description: "Country in Africa",
  },
  {
    code: "AI",
    name: "Anguilla",
    description: "Country in North America",
  },
  {
    code: "AQ",
    name: "Antarctica",
    description: "Continent",
  },
  {
    code: "AG",
    name: "Antigua and Barbuda",
    description: "Country in North America",
  },
  {
    code: "AR",
    name: "Argentina",
    description: "Country in South America",
  },
  {
    code: "AM",
    name: "Armenia",
    description: "Country in Asia",
  },
  {
    code: "AW",
    name: "Aruba",
    description: "Country in South America",
  },
  {
    code: "AU",
    name: "Australia",
    description: "Country in Oceania",
  },
  {
    code: "AT",
    name: "Austria",
    description: "Country in Europe",
  },
  {
    code: "AZ",
    name: "Azerbaijan",
    description: "Country in Asia",
  },
  {
    code: "BS",
    name: "Bahamas",
    description: "Country in North America",
  },
  {
    code: "BH",
    name: "Bahrain",
    description: "Country in Asia",
  },
  {
    code: "BD",
    name: "Bangladesh",
    description: "Country in Asia",
  },
  {
    code: "BB",
    name: "Barbados",
    description: "Country in North America",
  },
  {
    code: "BY",
    name: "Belarus",
    description: "Country in Europe",
  },
  {
    code: "BE",
    name: "Belgium",
    description: "Country in Europe",
  },
  {
    code: "BZ",
    name: "Belize",
    description: "Country in North America",
  },
  {
    code: "BJ",
    name: "Benin",
    description: "Country in Africa",
  },
  {
    code: "BM",
    name: "Bermuda",
    description: "Country in North America",
  },
  {
    code: "BT",
    name: "Bhutan",
    description: "Country in Asia",
  },
  {
    code: "BO",
    name: "Bolivia, Plurinational State of",
    description: "Country in South America",
  },
  {
    code: "BQ",
    name: "Bonaire, Sint Eustatius and Saba",
    description: "Country in South America",
  },
  {
    code: "BA",
    name: "Bosnia and Herzegovina",
    description: "Country in Europe",
  },
  {
    code: "BW",
    name: "Botswana",
    description: "Country in Africa",
  },
  {
    code: "BV",
    name: "Bouvet Island",
    description: "Island",
  },
  {
    code: "BR",
    name: "Brazil",
    description: "Country in South America",
  },
  {
    code: "IO",
    name: "British Indian Ocean Territory",
  },
  {
    code: "BN",
    name: "Brunei Darussalam",
    description: "Country in Asia",
  },
  {
    code: "BG",
    name: "Bulgaria",
    description: "Country in Europe",
  },
  {
    code: "BF",
    name: "Burkina Faso",
    description: "Country in Africa",
  },
  {
    code: "BI",
    name: "Burundi",
    description: "Country in Africa",
  },
  {
    code: "KH",
    name: "Cambodia",
    description: "Country in Asia",
  },
  {
    code: "CM",
    name: "Cameroon",
    description: "Country in Africa",
  },
  {
    code: "CA",
    name: "Canada",
    description: "Country in North America",
  },
  {
    code: "CV",
    name: "Cape Verde",
    description: "Country in Africa",
  },
  {
    code: "KY",
    name: "Cayman Islands",
    description: "Country in North America",
  },
  {
    code: "CF",
    name: "Central African Republic",
    description: "Country in Africa",
  },
  {
    code: "TD",
    name: "Chad",
    description: "Country in Africa",
  },
  {
    code: "CL",
    name: "Chile",
    description: "Country in South America",
  },
  {
    code: "CN",
    name: "China",
    description: "Country in Asia",
  },
  {
    code: "CX",
    name: "Christmas Island",
  },
  {
    code: "CC",
    name: "Cocos (Keeling) Islands",
  },
  {
    code: "CO",
    name: "Colombia",
    description: "Country in South America",
  },
  {
    code: "KM",
    name: "Comoros",
    description: "Country in Africa",
  },
  {
    code: "CG",
    name: "Congo, Republic of",
    description: "Country in Africa",
  },
  {
    code: "CD",
    name: "Congo, the Democratic Republic of the",
    description: "Country in Africa",
  },
  {
    code: "CK",
    name: "Cook Islands",
    description: "Country in Oceania",
  },
  {
    code: "CR",
    name: "Costa Rica",
    description: "Country in North America",
  },
  {
    code: "CI",
    name: "Côte d'Ivoire",
    description: "Country in Africa",
  },
  {
    code: "HR",
    name: "Croatia",
    description: "Country in Europe",
  },
  {
    code: "CU",
    name: "Cuba",
    description: "Country in North America",
  },
  {
    code: "CW",
    name: "Curaçao",
    description: "Country in South America",
  },
  {
    code: "CY",
    name: "Cyprus",
    description: "Country in Europe",
  },
  {
    code: "CZ",
    name: "Czech Republic",
    description: "Country in Europe",
  },
  {
    code: "DK",
    name: "Denmark",
    description: "Country in Europe",
  },
  {
    code: "DJ",
    name: "Djibouti",
    description: "Country in Africa",
  },
  {
    code: "DM",
    name: "Dominica",
    description: "Country in North America",
  },
  {
    code: "DO",
    name: "Dominican Republic",
    description: "Country in North America",
  },
  {
    code: "EC",
    name: "Ecuador",
    description: "Country in South America",
  },
  {
    code: "EG",
    name: "Egypt",
    description: "Country in Africa",
  },
  {
    code: "SV",
    name: "El Salvador",
    description: "Country in North America",
  },
  {
    code: "GQ",
    name: "Equatorial Guinea",
    description: "Country in Africa",
  },
  {
    code: "ER",
    name: "Eritrea",
    description: "Country in Africa",
  },
  {
    code: "EE",
    name: "Estonia",
    description: "Country in Europe",
  },
  {
    code: "ET",
    name: "Ethiopia",
    description: "Country in Africa",
  },
  {
    code: "FK",
    name: "Falkland Islands (Malvinas)",
  },
  {
    code: "FO",
    name: "Faroe Islands",
    description: "Country in Europe",
  },
  {
    code: "FJ",
    name: "Fiji",
    description: "Country in Oceania",
  },
  {
    code: "FI",
    name: "Finland",
    description: "Country in Europe",
  },
  {
    code: "FR",
    name: "France",
    description: "Country in Europe",
  },
  {
    code: "GF",
    name: "French Guiana",
  },
  {
    code: "PF",
    name: "French Polynesia",
  },
  {
    code: "TF",
    name: "French Southern Territories",
  },
  {
    code: "GA",
    name: "Gabon",
    description: "Country in Africa",
  },
  {
    code: "GM",
    name: "Gambia",
    description: "Country in Africa",
  },
  {
    code: "GE",
    name: "Georgia",
    description: "Country in Asia",
  },
  {
    code: "DE",
    name: "Germany",
    description: "Country in Europe",
  },
  {
    code: "GH",
    name: "Ghana",
    description: "Country in Africa",
  },
  {
    code: "GI",
    name: "Gibraltar",
  },
  {
    code: "GR",
    name: "Greece",
    description: "Country in Europe",
  },
  {
    code: "GL",
    name: "Greenland",
    description: "Country in North America",
  },
  {
    code: "GD",
    name: "Grenada",
    description: "Country in North America",
  },
  {
    code: "GP",
    name: "Guadeloupe",
  },
  {
    code: "GU",
    name: "Guam",
    description: "Country in Oceania",
  },
  {
    code: "GT",
    name: "Guatemala",
    description: "Country in North America",
  },
  {
    code: "GG",
    name: "Guernsey",
  },
  {
    code: "GN",
    name: "Guinea",
    description: "Country in Africa",
  },
  {
    code: "GW",
    name: "Guinea-Bissau",
    description: "Country in Africa",
  },
  {
    code: "GY",
    name: "Guyana",
    description: "Country in South America",
  },
  {
    code: "HT",
    name: "Haiti",
    description: "Country in North America",
  },
  {
    code: "HM",
    name: "Heard Island and McDonald Islands",
  },
  {
    code: "VA",
    name: "Vatican City",
    description: "Country in Europe",
  },
  {
    code: "HN",
    name: "Honduras",
    description: "Country in North America",
  },
  {
    code: "HK",
    name: "Hong Kong",
    description: "Special Administrative Region of China",
  },
  {
    code: "HU",
    name: "Hungary",
    description: "Country in Europe",
  },
  {
    code: "IS",
    name: "Iceland",
    description: "Country in Europe",
  },
  {
    code: "IN",
    name: "India",
    description: "Country in Asia",
  },
  {
    code: "ID",
    name: "Indonesia",
    description: "Country in Asia",
  },
  {
    code: "IR",
    name: "Iran, Islamic Republic of",
    description: "Country in Asia",
  },
  {
    code: "IQ",
    name: "Iraq",
    description: "Country in Asia",
  },
  {
    code: "IE",
    name: "Ireland",
    description: "Country in Europe",
  },
  {
    code: "IM",
    name: "Isle of Man",
    description: "Island in Europe",
  },
  {
    code: "IL",
    name: "Israel",
    description: "Country in Asia",
  },
  {
    code: "IT",
    name: "Italy",
    description: "Country in Europe",
  },
  {
    code: "JM",
    name: "Jamaica",
    description: "Country in North America",
  },
  {
    code: "JP",
    name: "Japan",
    description: "Country in Asia",
  },
  {
    code: "JE",
    name: "Jersey",
  },
  {
    code: "JO",
    name: "Jordan",
    description: "Country in Asia",
  },
  {
    code: "KZ",
    name: "Kazakhstan",
    description: "Country in Asia",
  },
  {
    code: "KE",
    name: "Kenya",
    description: "Country in Africa",
  },
  {
    code: "KI",
    name: "Kiribati",
    description: "Country in Oceania",
  },
  {
    code: "KP",
    name: "Korea, Democratic People's Republic of",
    description: "Country in Asia",
  },
  {
    code: "KR",
    name: "Korea, Republic of",
    description: "Country in Asia",
  },
  {
    code: "1A",
    name: "Kosovo",
    description: "Country in Europe",
  },
  {
    code: "KW",
    name: "Kuwait",
    description: "Country in Asia",
  },
  {
    code: "KG",
    name: "Kyrgyzstan",
    description: "Country in Asia",
  },
  {
    code: "LA",
    name: "Lao People's Democratic Republic",
    description: "Country in Asia",
  },
  {
    code: "LV",
    name: "Latvia",
    description: "Country in Europe",
  },
  {
    code: "LB",
    name: "Lebanon",
    description: "Country in Asia",
  },
  {
    code: "LS",
    name: "Lesotho",
    description: "Country in Africa",
  },
  {
    code: "LR",
    name: "Liberia",
    description: "Country in Africa",
  },
  {
    code: "LY",
    name: "Libya",
    description: "Country in Africa",
  },
  {
    code: "LI",
    name: "Liechtenstein",
    description: "Country in Europe",
  },
  {
    code: "LT",
    name: "Lithuania",
    description: "Country in Europe",
  },
  {
    code: "LU",
    name: "Luxembourg",
    description: "Country in Europe",
  },
  {
    code: "MO",
    name: "Macao",
    description: "Special Administrative Region of China",
  },
  {
    code: "MK",
    name: "Macedonia",
    description: "Country in Europe",
  },
  {
    code: "MG",
    name: "Madagascar",
    description: "Country in Africa",
  },
  {
    code: "MW",
    name: "Malawi",
    description: "Country in Africa",
  },
  {
    code: "MY",
    name: "Malaysia",
    description: "Country in Asia",
  },
  {
    code: "MV",
    name: "Maldives",
    description: "Country in Asia",
  },
  {
    code: "ML",
    name: "Mali",
    description: "Country in Africa",
  },
  {
    code: "MT",
    name: "Malta",
    description: "Country in Europe",
  },
  {
    code: "MH",
    name: "Marshall Islands",
    description: "Country in Oceania",
  },
  {
    code: "MQ",
    name: "Martinique",
  },
  {
    code: "MR",
    name: "Mauritania",
    description: "Country in Africa",
  },
  {
    code: "MU",
    name: "Mauritius",
    description: "Country in Africa",
  },
  {
    code: "YT",
    name: "Mayotte",
  },
  {
    code: "MX",
    name: "Mexico",
    description: "Country in North America",
  },
  {
    code: "FM",
    name: "Micronesia, Federated States of",
    description: "Country in Oceania",
  },
  {
    code: "MD",
    name: "Moldova, Republic of",
    description: "Country in Europe",
  },
  {
    code: "MC",
    name: "Monaco",
    description: "Country in Europe",
  },
  {
    code: "MN",
    name: "Mongolia",
    description: "Country in Asia",
  },
  {
    code: "ME",
    name: "Montenegro",
    description: "Country in Europe",
  },
  {
    code: "MS",
    name: "Montserrat",
  },
  {
    code: "MA",
    name: "Morocco",
    description: "Country in Africa",
  },
  {
    code: "MZ",
    name: "Mozambique",
    description: "Country in Africa",
  },
  {
    code: "MM",
    name: "Myanmar",
    description: "Country in Asia",
  },
  {
    code: "NA",
    name: "Namibia",
    description: "Country in Africa",
  },
  {
    code: "NR",
    name: "Nauru",
    description: "Country in Oceania",
  },
  {
    code: "NP",
    name: "Nepal",
    description: "Country in Asia",
  },
  {
    code: "NL",
    name: "Netherlands",
    description: "Country in Europe",
  },
  {
    code: "NC",
    name: "New Caledonia",
  },
  {
    code: "NZ",
    name: "New Zealand",
    description: "Country in Oceania",
  },
  {
    code: "NI",
    name: "Nicaragua",
    description: "Country in North America",
  },
  {
    code: "NE",
    name: "Niger",
    description: "Country in Africa",
  },
  {
    code: "NG",
    name: "Nigeria",
    description: "Country in Africa",
  },
  {
    code: "NU",
    name: "Niue",
    description: "Country in Oceania",
  },
  {
    code: "NF",
    name: "Norfolk Island",
  },
  {
    code: "MP",
    name: "Northern Mariana Islands",
  },
  {
    code: "NO",
    name: "Norway",
    description: "Country in Europe",
  },
  {
    code: "OM",
    name: "Oman",
    description: "Country in Asia",
  },
  {
    code: "PK",
    name: "Pakistan",
    description: "Country in Asia",
  },
  {
    code: "PW",
    name: "Palau",
    description: "Country in Oceania",
  },
  {
    code: "PS",
    name: "Palestine, State of",
    description: "Country in Asia",
  },
  {
    code: "PA",
    name: "Panama",
    description: "Country in North America",
  },
  {
    code: "PG",
    name: "Papua New Guinea",
    description: "Country in Oceania",
  },
  {
    code: "PY",
    name: "Paraguay",
    description: "Country in South America",
  },
  {
    code: "PE",
    name: "Peru",
    description: "Country in South America",
  },
  {
    code: "PH",
    name: "Philippines",
    description: "Country in Asia",
  },
  {
    code: "PN",
    name: "Pitcairn",
  },
  {
    code: "PL",
    name: "Poland",
    description: "Country in Europe",
  },
  {
    code: "PT",
    name: "Portugal",
    description: "Country in Europe",
  },
  {
    code: "PR",
    name: "Puerto Rico",
  },
  {
    code: "QA",
    name: "Qatar",
    description: "Country in Asia",
  },
  {
    code: "RE",
    name: "Réunion",
  },
  {
    code: "RO",
    name: "Romania",
    description: "Country in Europe",
  },
  {
    code: "RU",
    name: "Russian Federation",
    description: "Country in Europe and Asia",
  },
  {
    code: "RW",
    name: "Rwanda",
    description: "Country in Africa",
  },
  {
    code: "BL",
    name: "Saint Barthélemy",
  },
  {
    code: "SH",
    name: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    code: "KN",
    name: "Saint Kitts and Nevis",
    description: "Country in North America",
  },
  {
    code: "LC",
    name: "Saint Lucia",
    description: "Country in North America",
  },
  {
    code: "MF",
    name: "Saint Martin (French part)",
  },
  {
    code: "PM",
    name: "Saint Pierre and Miquelon",
  },
  {
    code: "VC",
    name: "Saint Vincent and the Grenadines",
    description: "Country in North America",
  },
  {
    code: "WS",
    name: "Samoa",
    description: "Country in Oceania",
  },
  {
    code: "SM",
    name: "San Marino",
    description: "Country in Europe",
  },
  {
    code: "ST",
    name: "Sao Tome and Principe",
    description: "Country in Africa",
  },
  {
    code: "SA",
    name: "Saudi Arabia",
    description: "Country in Asia",
  },
  {
    code: "SN",
    name: "Senegal",
    description: "Country in Africa",
  },
  {
    code: "RS",
    name: "Serbia",
    description: "Country in Europe",
  },
  {
    code: "SC",
    name: "Seychelles",
    description: "Country in Africa",
  },
  {
    code: "SL",
    name: "Sierra Leone",
    description: "Country in Africa",
  },
  {
    code: "SG",
    name: "Singapore",
    description: "Country in Asia",
  },
  {
    code: "SX",
    name: "Sint Maarten (Dutch part)",
  },
  {
    code: "SK",
    name: "Slovakia",
    description: "Country in Europe",
  },
  {
    code: "SI",
    name: "Slovenia",
    description: "Country in Europe",
  },
  {
    code: "SB",
    name: "Solomon Islands",
    description: "Country in Oceania",
  },
  {
    code: "SO",
    name: "Somalia",
    description: "Country in Africa",
  },
  {
    code: "ZA",
    name: "South Africa",
    description: "Country in Africa",
  },
  {
    code: "GS",
    name: "South Georgia and the South Sandwich Islands",
  },
  {
    code: "SS",
    name: "South Sudan",
    description: "Country in Africa",
  },
  {
    code: "ES",
    name: "Spain",
    description: "Country in Europe",
  },
  {
    code: "LK",
    name: "Sri Lanka",
    description: "Country in Asia",
  },
  {
    code: "SD",
    name: "Sudan",
    description: "Country in Africa",
  },
  {
    code: "SR",
    name: "Suriname",
    description: "Country in South America",
  },
  {
    code: "SJ",
    name: "Svalbard and Jan Mayen",
  },
  {
    code: "SZ",
    name: "Eswatini",
    description: "Country in Africa",
  },
  {
    code: "SE",
    name: "Sweden",
    description: "Country in Europe",
  },
  {
    code: "CH",
    name: "Switzerland",
    description: "Country in Europe",
  },
  {
    code: "SY",
    name: "Syria",
    description: "Country in Asia",
  },
  {
    code: "TW",
    name: "Taiwan, Province of China",
  },
  {
    code: "TJ",
    name: "Tajikistan",
    description: "Country in Asia",
  },
  {
    code: "TZ",
    name: "Tanzania, United Republic of",
    description: "Country in Africa",
  },
  {
    code: "TH",
    name: "Thailand",
    description: "Country in Asia",
  },
  {
    code: "TL",
    name: "Timor-Leste",
    description: "Country in Asia",
  },
  {
    code: "TG",
    name: "Togo",
    description: "Country in Africa",
  },
  {
    code: "TK",
    name: "Tokelau",
  },
  {
    code: "TO",
    name: "Tonga",
    description: "Country in Oceania",
  },
  {
    code: "TT",
    name: "Trinidad and Tobago",
    description: "Country in North America",
  },
  {
    code: "TN",
    name: "Tunisia",
    description: "Country in Africa",
  },
  {
    code: "TR",
    name: "Turkey",
    description: "Country in Europe and Asia",
  },
  {
    code: "TM",
    name: "Turkmenistan",
    description: "Country in Asia",
  },
  {
    code: "TC",
    name: "Turks and Caicos Islands",
  },
  {
    code: "TV",
    name: "Tuvalu",
    description: "Country in Oceania",
  },
  {
    code: "UG",
    name: "Uganda",
    description: "Country in Africa",
  },
  {
    code: "UA",
    name: "Ukraine",
    description: "Country in Europe",
  },
  {
    code: "AE",
    name: "United Arab Emirates",
    description: "Country in Asia",
  },
  {
    code: "UK",
    name: "United Kingdom",
    description: "Country in Europe",
  },
  {
    code: "US",
    name: "United States",
    description: "Country in North America",
  },
  {
    code: "UM",
    name: "United States Minor Outlying Islands",
  },
  {
    code: "UY",
    name: "Uruguay",
    description: "Country in South America",
  },
  {
    code: "UZ",
    name: "Uzbekistan",
    description: "Country in Asia",
  },
  {
    code: "VU",
    name: "Vanuatu",
    description: "Country in Oceania",
  },
  {
    code: "VE",
    name: "Venezuela, Bolivarian Republic of",
    description: "Country in South America",
  },
  {
    code: "VN",
    name: "Vietnam",
    description: "Country in Asia",
  },
  {
    code: "VG",
    name: "Virgin Islands, British",
  },
  {
    code: "VI",
    name: "Virgin Islands, U.S.",
  },
  {
    code: "WF",
    name: "Wallis and Futuna",
  },
  {
    code: "EH",
    name: "Western Sahara",
  },
  {
    code: "YE",
    name: "Yemen",
    description: "Country in Asia",
  },
  {
    code: "ZM",
    name: "Zambia",
    description: "Country in Africa",
  },
  {
    code: "ZW",
    name: "Zimbabwe",
    description: "Country in Africa",
  },
];

export const countryCodeSelectOptions = countryCodes.map((c) => ({ label: c.name, value: c.code }));

export function countryToCountryCode(country: string): string | undefined {
  return countryCodes.find((c) => c.name === country)?.code;
}

export function countryCodeToCountry(code: string): string | undefined {
  return countryCodes.find((c) => c.code === code)?.name;
}

export const cpvDimensions: OptionItem[] = [
  {
    code: "75",
    name: "Administration, defence and social security services",
  },
  {
    code: "16",
    name: "Agricultural machinery",
  },
  {
    code: "03",
    name: "Agricultural, farming, fishing, forestry and related products",
  },
  {
    code: "77",
    name: "Agricultural, forestry, horticultural, aquacultural and apicultural services",
  },
  {
    code: "71",
    name: "Architectural, construction, engineering and inspection services",
  },
  {
    code: "79",
    name: "Business services: law, marketing, consulting, recruitment, printing and security",
  },
  {
    code: "24",
    name: "Chemical products",
  },
  {
    code: "18",
    name: "Clothing, footwear, luggage articles and accessories",
  },
  {
    code: "41",
    name: "Collected and purified water",
  },
  {
    code: "44",
    name: "Construction structures and materials; auxiliary products to construction (except electric apparatus)",
  },
  {
    code: "45",
    name: "Construction work",
  },
  {
    code: "80",
    name: "Education and training services",
  },
  {
    code: "31",
    name: "Electrical machinery, apparatus, equipment and consumables; lighting",
  },
  {
    code: "66",
    name: "Financial and insurance services",
  },
  {
    code: "15",
    name: "Food, beverages, tobacco and related products",
  },
  {
    code: "39",
    name: "Furniture (incl. office furniture), furnishings, domestic appliances (excl. lighting) and cleaning products",
  },
  {
    code: "85",
    name: "Health and social work services",
  },
  {
    code: "55",
    name: "Hotel, restaurant and retail trade services",
  },
  {
    code: "42",
    name: "Industrial machinery",
  },
  {
    code: "51",
    name: "Installation services (except software)",
  },
  {
    code: "72",
    name: "IT services: consulting, software development, Internet and support",
  },
  {
    code: "38",
    name: "Laboratory, optical and precision equipments (excl. glasses)",
  },
  {
    code: "19",
    name: "Leather and textile fabrics, plastic and rubber materials",
  },
  {
    code: "43",
    name: "Machinery for mining, quarrying, construction equipment",
  },
  {
    code: "33",
    name: "Medical equipments, pharmaceuticals and personal care products",
  },
  {
    code: "14",
    name: "Mining, basic metals and related products",
  },
  {
    code: "37",
    name: "Musical instruments, sport goods, games, toys, handicraft, art materials and accessories",
  },
  {
    code: "30",
    name: "Office and computing machinery, equipment and supplies except furniture and software packages",
  },
  {
    code: "98",
    name: "Other community, social and personal services",
  },
  {
    code: "09",
    name: "Petroleum products, fuel, electricity and other sources of energy",
  },
  {
    code: "64",
    name: "Postal and telecommunications services",
  },
  {
    code: "22",
    name: "Printed matter and related products",
  },
  {
    code: "65",
    name: "Public utilities",
  },
  {
    code: "32",
    name: "Radio, television, communication, telecommunication and related equipment",
  },
  {
    code: "70",
    name: "Real estate services",
  },
  {
    code: "92",
    name: "Recreational, cultural and sporting services",
  },
  {
    code: "50",
    name: "Repair and maintenance services",
  },
  {
    code: "73",
    name: "Research and development services and related consultancy services",
  },
  {
    code: "35",
    name: "Security, fire-fighting, police and defence equipment",
  },
  {
    code: "76",
    name: "Services related to the oil and gas industry",
  },
  {
    code: "90",
    name: "Sewage, refuse, cleaning and environmental services",
  },
  {
    code: "48",
    name: "Software package and information systems",
  },
  {
    code: "63",
    name: "Supporting and auxiliary transport services; travel agencies services",
  },
  {
    code: "34",
    name: "Transport equipment and auxiliary products to transportation",
  },
  {
    code: "60",
    name: "Transport services (excl. Waste transport)",
  },
];

export const cpvDimensionSelectOptions = cpvDimensions.map((cpv) => ({
  label: cpv.name,
  value: cpv.code,
}));
