import React, { useCallback, useEffect, useRef } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd5";

import { upload01 } from "../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../lib/icons/untitled_ui/UIcon";
import { Text } from "../../styles/utility-components";

import css from "./FileInput.module.scss";

type Props = {
  onChange: (files: File[]) => void;
  onUnsupportedFilesError?: (errors: string[]) => void;
  value: File[];
  isError?: boolean;
  errorMessage?: string;
  acceptedFileTypes?: string;
  multiple?: boolean;
  title?: string;
};

function handleDragover(event: DragEvent) {
  event.preventDefault();
  event.stopPropagation();
}

export function FileInput({
  onChange,
  onUnsupportedFilesError,
  value: files,
  isError,
  errorMessage,
  acceptedFileTypes,
  multiple = true,
  title,
}: Props) {
  const dropContainer = useRef<HTMLDivElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);

  const isValidFileType = useCallback(
    (file: File) => {
      if (!acceptedFileTypes) return true; // Allow all if no file type restriction
      const acceptedTypes = acceptedFileTypes.split(",").map((type) => type.trim());
      return acceptedTypes.some((type) => file.type === type || file.name.endsWith(type));
    },
    [acceptedFileTypes],
  );

  const handleFiles = useCallback(
    (selectedFiles: File[]) => {
      const validFiles = selectedFiles.filter(isValidFileType);
      const invalidFiles = selectedFiles.filter((file) => !isValidFileType(file));

      if (invalidFiles.length > 0) {
        onUnsupportedFilesError?.(invalidFiles.map((file) => file.name));
      }

      if (validFiles.length > 0) {
        const updatedFiles = multiple ? [...files, ...validFiles] : [validFiles[0]];
        onChange(updatedFiles);
      }
    },
    [files, isValidFileType, multiple, onChange, onUnsupportedFilesError],
  );

  useEffect(() => {
    function handleDrop(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();

      if (event.dataTransfer?.files) {
        const droppedFiles = Array.from(event.dataTransfer.files);
        handleFiles(droppedFiles);
      }
    }

    const container = dropContainer.current;
    if (container) {
      container.addEventListener("dragover", handleDragover);
      container.addEventListener("drop", handleDrop);
    }

    return () => {
      container?.removeEventListener("dragover", handleDragover);
      container?.removeEventListener("drop", handleDrop);
    };
  }, [files, onChange, multiple, acceptedFileTypes, handleFiles]);

  return (
    <div
      ref={dropContainer}
      onClick={() => fileInput.current && fileInput.current.click()}
      className={isError ? css.containerError : css.container}
    >
      <input
        type="file"
        multiple={multiple}
        onChange={(e) => {
          if (e.target.files) {
            const selectedFiles = Array.from(e.target.files);
            handleFiles(selectedFiles);
          }
        }}
        accept={acceptedFileTypes}
        style={{ display: "none" }}
        ref={fileInput}
      />
      <UIcon svg={upload01} className={css.tooltipIcon} size={24} />
      {errorMessage && isError && <p className={css.errorText}>{errorMessage}</p>}
      {title ? (
        <Text h3 className={css.instructionText}>
          {title}
        </Text>
      ) : (
        <p className={css.instructionText}>Click or drag file to this area to upload</p>
      )}
      <p className={css.instructionText}>You can upload multiple files at once</p>
      {acceptedFileTypes && (
        <p className={css.instructionText}>
          Accepted file types: {acceptedFileTypes.replace(/,/g, ", ")}
        </p>
      )}

      {files.map((file, index) => (
        <div key={file.name + index} className={css.file}>
          {file.name}{" "}
          <Tooltip title="Delete">
            <Button
              type="text"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={(evt) => {
                evt.stopPropagation();
                const updated = [...files];
                updated.splice(index, 1);
                onChange(updated);
              }}
            />
          </Tooltip>
        </div>
      ))}
    </div>
  );
}

export default FileInput;
