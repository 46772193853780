import { Core, WebViewerInstance } from "@pdftron/webviewer";

const wordSplitter = /[\n\s/(/)]/;
const punctuation = /[.,/#!$%^&*;:{}=\-_`~()]/g;
// TODO: This will most likely come from a database or some other source
// This is currently used to reduce the complexity by dropping common words
const commonWords = [
  "and",
  "be",
  "that",
  "must",
  "by",
  "on",
  "any",
  "may",
  "not",
  "in",
  "to",
  "the",
  "of",
  "a",
  "for",
];

export function searchText(instance: WebViewerInstance, searchTerm: string) {
  let results: Core.Search.SearchResult[] = [];
  const { documentViewer, Search } = instance.Core;
  // Attempt to find the searchTerm in a basic format
  // remove new lines (\n) and swap out punctuation for a regex match
  const basicSearchTerm = searchTerm.replace(punctuation, ".*?").replace("\n", " ");
  documentViewer.textSearchInit(
    basicSearchTerm,
    Search.Mode.PAGE_STOP | Search.Mode.HIGHLIGHT | Search.Mode.AMBIENT_STRING | Search.Mode.REGEX,
    {
      fullSearch: true,
      onResult: (result: Core.Search.SearchResult) => {
        if (result.resultCode === Search.ResultCode.FOUND) {
          results.push({ ...result });
        }
      },
      onDocumentEnd: () => {
        if (results.length) {
          displayResults(instance, results);
        } else {
          // search using more complex method breaking down the search term
          searchUsingQuadrants(instance, searchTerm);
        }
      },
    },
  );
}

function searchUsingQuadrants(instance: WebViewerInstance, searchTerm: string) {
  //remove all characters that might affect the search and spilt into words including spaces
  const searchTerms = searchTerm.split(wordSplitter).filter((term) => term);
  const searchRegexTerm = searchTerms.join("|");
  let results: Core.Search.SearchResult[] = [];
  const { documentViewer, Search } = instance.Core;

  // remove words that are not really valid
  const validSearchTerms = searchTerms.filter((term) => !commonWords.includes(term.toLowerCase()));
  documentViewer.textSearchInit(
    searchRegexTerm,
    Search.Mode.PAGE_STOP | Search.Mode.AMBIENT_STRING | Search.Mode.REGEX | Search.Mode.HIGHLIGHT,
    {
      fullSearch: true,
      onResult: (result: Core.Search.SearchResult) => {
        if (result.resultCode === Search.ResultCode.FOUND) {
          results.push({ ...result });
        }
      },
      onDocumentEnd: () => {
        if (results.length) {
          // loop through the results and map out the best combined result using the quadrant information
          const matchedResults = findClosestCluster(results, validSearchTerms);
          displayResults(instance, matchedResults);
        }
      },
    },
  );
}

/**
 * Finds the best cluster of matches for the given search term using
 * coordinate based grouping.
 * @param results - Array of Core.Search.SearchResult
 * @param searchTerm - The full search term to find.
 * @param yThreshold - Threshold for grouping matches by y1 coordinate.
 * @returns The best cluster of matches for the search term.
 */
function findClosestCluster(
  results: Core.Search.SearchResult[],
  searchTerms: string[],
  yThreshold = 15,
): Core.Search.SearchResult[] {
  const pageClusters: {
    pageNum: number;
    cluster: Core.Search.SearchResult[];
    proximityScore: number;
  }[] = [];

  // Step 1: Group matches by word and page
  const wordMatchesByPage: { [pageNum: number]: Core.Search.SearchResult[][] } = {};

  results.forEach((result) => {
    const pageNum = result.pageNum;
    if (!wordMatchesByPage[pageNum]) wordMatchesByPage[pageNum] = [];
    for (let i = 0; i < searchTerms.length; i++) {
      if (!wordMatchesByPage[pageNum][i]) wordMatchesByPage[pageNum][i] = [];
      if (result.resultStr.toLowerCase() === searchTerms[i].toLowerCase()) {
        wordMatchesByPage[pageNum][i].push(result);
      }
    }
  });

  // Step 2: For each page, find the best cluster
  Object.entries(wordMatchesByPage).forEach(([pageNum, wordMatches]) => {
    const clusters: { cluster: Core.Search.SearchResult[]; proximityScore: number }[] = [];

    // Generate all potential clusters of matches
    function generateClusters(currentCluster: Core.Search.SearchResult[], currentIndex: number) {
      if (currentIndex === searchTerms.length) {
        // Validate cluster by y1 proximity
        const yValues = currentCluster.map((match) => match.quads.y1);
        const minY = Math.min(...yValues);
        const maxY = Math.max(...yValues);

        // Ignore clusters that aren't vertically aligned
        if (maxY - minY > yThreshold) return;

        // Calculate proximity score
        let proximityScore = 0;
        for (let i = 1; i < currentCluster.length; i++) {
          proximityScore += calculateDistance(currentCluster[i - 1], currentCluster[i]);
        }

        clusters.push({ cluster: [...currentCluster], proximityScore });
        return;
      }

      // Explore all matches for the current word
      wordMatches[currentIndex]?.forEach((match) => {
        currentCluster.push(match);
        generateClusters(currentCluster, currentIndex + 1);
        currentCluster.pop();
      });
    }

    generateClusters([], 0);

    // Find the best cluster for this page
    if (clusters.length > 0) {
      clusters.sort((a, b) => a.proximityScore - b.proximityScore);
      pageClusters.push({
        pageNum: +pageNum,
        cluster: clusters[0].cluster,
        proximityScore: clusters[0].proximityScore,
      });
    }
  });

  // Step 3: Find the best cluster across all pages
  pageClusters.sort((a, b) => a.proximityScore - b.proximityScore);
  return pageClusters.length > 0 ? pageClusters[0].cluster : [];
}

// Helper to compute Euclidean distance between two points
function calculateDistance(
  result1: Core.Search.SearchResult,
  result2: Core.Search.SearchResult,
): number {
  return Math.sqrt(
    Math.pow(result1.quads[0].x2 - result2.quads[0].x1, 2) +
      Math.pow(result1.quads[0].y2 - result2.quads[0].y1, 2),
  );
}

function displayResults(instance: WebViewerInstance, results: Core.Search.SearchResult[]) {
  const { documentViewer, annotationManager, Annotations } = instance.Core;

  if (results.length === 0) {
    return;
  }

  // remove any previous results
  annotationManager.disableReadOnlyMode();
  documentViewer.clearSearchResults();
  const annots = annotationManager.getAnnotationsList();
  annotationManager.deleteAnnotations(annots);

  documentViewer.setSearchHighlightColors({
    searchResult: "rgba(255, 0, 0, 0.0)",
    activeSearchResult: "rgba(0, 255, 0, 0.0)",
  });

  documentViewer.displayAdditionalSearchResults(results);
  documentViewer.setActiveSearchResult(results[0]);

  // Find the top most word and the bottom most word to create a rectangle
  const quads = results.map((result) => result.quads);
  const x1 = Math.min(...quads.map((quad) => quad[0].x1));
  const y1 = Math.min(...quads.map((quad) => quad[0].y1));
  const x2 = Math.max(...quads.map((quad) => quad[quad.length - 1].x2));
  const y2 = Math.max(...quads.map((quad) => quad[quad.length - 1].y2));

  const rect = new Annotations.RectangleAnnotation({
    PageNumber: results[0].pageNum,
    X: x1 - 15,
    Y: y1 - 15,

    Width: x2 - x1 + 15,
    Height: y2 - y1 + 15,
    StrokeThickness: 2,
    StrokeColor: new Annotations.Color(255, 0, 0, 1),
  });

  annotationManager.addAnnotation(rect);
  annotationManager.enableReadOnlyMode();
  annotationManager.redrawAnnotation(rect);
}
