import { NumericRangeCriteria, SerialisedDateRangeCriteria } from "components/record_search/types";
import { File } from "lib/documents/types";
import {
  BuyerCategoryAssignmentDto,
  BuyerSummaryDto,
  CpvCodesDto,
  LotDto,
  ProcurementStageDto,
  ProcurementStageDtoProcurementStageQualificationDto,
  RecordDto,
  RecordListSummaryDto,
  RecordSearchRequestDtoProcurementStageQualificationsEnum,
  Signal,
  SignalDto,
  SupplierSummaryDto,
} from "lib/generated/app-api";
import { SortState } from "lib/search/types";
import { tagColourFromSignal } from "lib/tagUtils";
import { VisitInfo } from "lib/UtmParams";

export type SubscriptionEnum = "freemium" | "basic" | "growth" | "expert";

export type User = {
  guid: string;
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  is_verified: boolean;
  signup_utm?: VisitInfo;
  admin?: boolean;
  show_buyer_categories: boolean;
  has_export_permission: boolean;
  has_account_briefing_permission: boolean;
  use_supplier_name: boolean;
  company: Company;
  data_type_subscriptions: DataTypeSubscription[];
  active_data_types: DataTypeName[];
  subscribed_data_types: DataTypeName[];
  has_dismissed_disqualification_warning: boolean;
  created_at: string;
  subscription: SubscriptionEnum;
  team: {
    id: string;
    name: string;
  };
};

export type Company = {
  id: number;
  guid: string;
  name: string;
  demo?: boolean;
  has_per_user_unread_state: boolean;
  hide_discard_button: boolean;
  spec_sheet_uid?: string | null;
  spec_sheet_url?: string | null;
  analytics_tags?: Record<string, string | boolean | number>;
  trial_start: string | null;
  trial_end: string | null;
  partner_programme: PartnerProgramme | null;
  has_international_data_access?: boolean;
  qualification_states: QualificationStates;
  payment_type: "Demo" | "Freemium" | "Paid" | null;
  created_at: string;
  allow_crm_integration?: boolean;
};

export type LeadAssigneeData = {
  guid: string;
  first_name: string;
  last_name: string;
  email: string;
};

export type ProcurementStageUserData = {
  hidden_date?: string | null;
  qualification?: Qualification | null;
  procurementStageQualification?: ProcurementStage["procurementStageQualification"];
  assignee?: LeadAssigneeData | null;
  comment_count?: number;
};

export type RecordUserData = {
  lists?: UserListSummary[];
  seenByUsers: { first_name: string; guid: string }[];
};

export type DataTypeName =
  | "TENDERS|PRE-TENDERS"
  | "AWARDS"
  | "BUYERS|SUPPLIERS"
  | "NEWS"
  | "CONTACTS"
  | "FRAMEWORKS"
  | "DOCUMENTS";

// this is a slightly annoying exception to handle, where we treat BUYERS|SUPPLIERS as one
// data source (and want to keep it that way for some time to come), but it makes more sense
// from the user's point of view if they can see which *specific* source they need to get
// to access certain features. It would be misleading to say "you need to unlock buyers *and*
// suppliers" to access Supplier search, for instance
export type DataTypeNameWithBuyersAndSuppliers = DataTypeName | "BUYERS" | "SUPPLIERS";

export type ProDataTypeSeparateBuyersSuppliers = Exclude<
  DataTypeNameWithBuyersAndSuppliers,
  "TENDERS|PRE-TENDERS" | "BUYERS|SUPPLIERS"
>;

export type DataTypeSubscription = { data_type_name: DataTypeName; disabled: boolean };

export type Settings = {
  trialLength: number;
  openOppsViewId: string;
  allActivityViewId: string;
};

export type BuyerCategoryAssignment = {
  buyer_category_id: string;
  source: string;
};

function buyerCategoryAssignmentFromDto(dto: BuyerCategoryAssignmentDto): BuyerCategoryAssignment {
  return {
    buyer_category_id: dto.buyerCategoryId,
    source: dto.source,
  };
}

export type BuyerDetails = {
  id: number;
  name: string;
  country: string;
  url: string | null;
  postal_code: string | null;
  address: string | null;
  town: string | null;
  nuts_location: string | null;
  category: string | null;
  guid: string;
  categories: BuyerCategoryAssignment[];
  metadata: BuyerMetadata | null;
  is_archived: boolean;
  is_stotles_verified: boolean;
  enhanced_data: EnhancedBuyerData;
};

export type GuestAccessPass = {
  id: string;
  token: string;
  title: string;
};

type EnhancedBuyerData = {
  oscar_category: string[] | null;
  employee_count: string | null;
  annual_spend: string | null;
  location_summary: string | null;
  url: string | null;
};

type BuyerMetadata = {
  logo_url: string | null;
  twitter_handle: string | null;
};

type CPVCode = {
  code: string;
  name: string;
  dimension_code: string;
};

function cpvCodeFromDto(dto: CpvCodesDto): CPVCode {
  return {
    ...dto,
    dimension_code: dto.dimensionCode,
  };
}

export type SupplierSummary = {
  id: number;
  name: string;
  country: string | null;
  is_sme?: boolean | null;
  awards_count?: number;
  expiries_count?: number;
  signals?: Array<Signal>;
  guid?: string;
  is_stotles_verified?: boolean;
};

function supplierSummaryFromDto(dto: SupplierSummaryDto): SupplierSummary {
  return {
    ...dto,
    is_sme: dto.isSme,
  };
}
export type CountryRegion = {
  countries: string[];
  regions: string[];
  selected?: string[];
};

type Lot = {
  id: number;
  lot_title: string | null;
  lot_number: string | null;
  award_date: string | null;
  value: number | null;
  currency: string | null;
  calculated_expiry: string | null;
  place_of_performance: string | null;
  end_date: string | null;
  start_date: string | null;
  suppliers: SupplierSummary[] | null;
};

function lotFromDto(dto: LotDto): Lot {
  return {
    ...dto,
    lot_title: dto.lotTitle,
    lot_number: dto.lotNumber,
    award_date: dto.awardDate,
    calculated_expiry: dto.calculatedExpiry,
    place_of_performance: dto.placeOfPerformance,
    end_date: dto.endDate,
    start_date: dto.startDate,
    suppliers: dto.suppliers?.map(supplierSummaryFromDto),
  };
}

export enum TenderStage {
  PRE_TENDER = "PRE_TENDER",
  STALE_PRE_TENDER = "STALE_PRE_TENDER",
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  AWARDED = "AWARDED",
}

export function getRecordSignals(record: RecordDetails): LeadSignal[] | undefined {
  return record.signals;
}

export function getSignalTypes(signals?: BasicSignal[]) {
  return signals?.reduce<Record<string, string[]>>((types, nextSignal) => {
    if (types[nextSignal.category]) {
      types[nextSignal.category].push(nextSignal.name);
    } else {
      types[nextSignal.category] = [nextSignal.name];
    }
    return types;
  }, {});
}

export type RecordDetails = {
  type: "Tender" | "Contract" | "TenderIntent" | "BridgeNotice";
  guid: string;
  name: string;
  // TODO: Remove singular buyer once buyers fully implemented
  buyer: BuyerSummary;
  buyers: BuyerSummary[];
  url_path: string;
  canonical_url_path: string;
  country: string;

  value_src: number | null;
  currency: string | null;
  description?: string | null;
  url?: string;
  stage: TenderStage;

  close_date?: string | null;
  publish_date: string;
  award_date?: string | null;
  expiry_date?: string | null;
  tender_date?: string | null;

  tenders_received: number | null;
  lots?: Lot[];
  lists?: UserListSummary[];

  signals?: LeadSignal[];

  cpv_codes: CPVCode[];

  // TODO: remove these fields once RecordDetailsPage doesn't use them
  buyer_contact_email: string | null;
  buyer_contact_phone: string | null;
  buyer_contact_name: string | null;

  procurement_stage: ProcurementStage;
  hidden_date: string | null;
  assignee?: LeadAssigneeData;
  comment_count?: number;
  seen_by_users: { guid: string; first_name: string }[];
  added_at?: string | null;
  relevance_score?: number | null;
};

export function recordFromDto(dto: RecordDto): RecordDetails {
  return {
    ...dto,
    type: dto.type as RecordDetails["type"],
    stage: dto.stage as TenderStage,
    url_path: dto.urlPath,
    canonical_url_path: dto.canonicalUrlPath,
    value_src: dto.valueSrc,
    close_date: dto.closeDate,
    publish_date: dto.publishDate,
    award_date: dto.awardDate,
    expiry_date: dto.expiryDate,
    tenders_received: dto.tendersReceived,
    buyer_contact_email: dto.buyerContactEmail,
    buyer_contact_name: dto.buyerContactName,
    buyer_contact_phone: dto.buyerContactPhone,
    hidden_date: dto.hiddenDate,
    seen_by_users: dto.seenByUsers.map((user) => ({ guid: user.guid, first_name: user.firstName })),
    buyers: dto.buyers.map(buyerSummaryFromDto),
    buyer: buyerSummaryFromDto(dto.buyer),
    lots: dto.lots?.map(lotFromDto),
    cpv_codes: dto.cpvCodes?.map(cpvCodeFromDto),
    procurement_stage: procurementStageFromDto(dto.procurementStage),
    assignee: dto.assignee
      ? {
          ...dto.assignee,
          first_name: dto.assignee.firstName,
          last_name: dto.assignee.lastName,
        }
      : undefined,
    lists: dto.lists?.map(userListSummaryFromDto),
    signals: dto.signals?.map(signalFromDto),
    added_at: dto.addedAt?.toISOString(),
    relevance_score: dto.relevanceScore,
  };
}

type Qualification = {
  id: string;
  label: string;
  disqualifies_lead: boolean;
  company_id?: string | null;
};

type ProcurementStage = {
  id: string;
  stage: string;
  record_family_id: string;
  qualification: Qualification | null;
  procurementStageQualification?: ProcurementStageDtoProcurementStageQualificationDto | null;
  // when the record is passed directly to the page rather than the api it is in snake case
  procurement_stage_qualification?: ProcurementStageDtoProcurementStageQualificationDto | null;
};

function procurementStageFromDto(dto: ProcurementStageDto): ProcurementStage {
  return {
    ...dto,
    record_family_id: dto.recordFamilyId,
    qualification: dto.qualification
      ? {
          ...dto.qualification,
          disqualifies_lead: dto.qualification.disqualifiesLead,
        }
      : null,
  };
}

export type RecordPreview = {
  guid: string;
  name: string;
  description: string | null;
  publish_date: string;
  buyer: {
    id: number;
    guid: string;
    name: string;
  };
  stage: TenderStage;
};

export type BuyerSummary = {
  id: number;
  name: string;
  country: string;
  url?: string | null;
  postal_code?: string | null;
  address?: string | null;
  town?: string | null;
  nuts_location?: string | null;
  guid: string;
  oscar_id?: string | null;
  categories: BuyerCategoryAssignment[];
  is_stotles_verified: boolean;
  is_archived: boolean;
  metadata?: BuyerMetadata | null;
};

function buyerSummaryFromDto(dto: BuyerSummaryDto): BuyerSummary {
  return {
    id: dto.id,
    name: dto.name,
    country: dto.country,
    url: dto.url,
    postal_code: dto.postalCode,
    address: dto.address,
    town: dto.town,
    nuts_location: dto.nutsLocation,
    guid: dto.guid,
    oscar_id: dto.oscarId,
    categories: dto.categories.map(buyerCategoryAssignmentFromDto),
    is_stotles_verified: dto.isStotlesVerified,
    is_archived: dto.isArchived,
    metadata: dto.metadata
      ? { logo_url: dto.metadata.logoUrl, twitter_handle: dto.metadata.twitterHandle ?? null }
      : undefined,
  };
}

export type SignalCategory = "Keywords" | "CPV codes" | "Partners" | "Competitors";

export type LeadSignal = {
  id: string;
  name: string;
  category: SignalCategory;
  colour: string;
};

function signalFromDto(dto: SignalDto): LeadSignal {
  return {
    ...dto,
    category: dto.category as SignalCategory,
    colour: tagColourFromSignal(dto.category) ?? "blue",
  };
}

export type RecordListSummary = {
  id: number;
  name: string;
  company_id: number;
  owner_id: number | null;
  guid: string;
};

export type UserListSummary = RecordListSummary & {
  owner_id: number | null;
  guid: string;
};

export type UserListSummaryWithCount = UserListSummary & {
  count: number;
};

function userListSummaryFromDto(dto: RecordListSummaryDto): UserListSummary {
  return {
    ...dto,
    owner_id: dto.ownerId,
    company_id: dto.companyId,
  };
}

export type CpvDimension = {
  code: string;
  name: string;
};

export type OscarOrgDetails = {
  id: string;
  org_urn: string;
  archived_by_oscar: boolean;
  data: {
    Org: string;
    WebsiteUrl?: string;
    Add1?: string;
    Add2?: string;
    Add3?: string;
    Add4?: string;
    Town?: string;
    DataTypes?: string;
    OrgTypes?: string;
    // We can add fields as we start using it
    // but also have the flexibiltiy to look up any key
    [key: string]: any;
  };
};

export type UserInvitation = {
  invitee_details: {
    job_role: string;
    email: string;
    first_name: string;
    last_name: string;
  };
  target_company: {
    id: number;
    name: string;
  } | null;
  new_company_name: string | null;
  id: string;
  created_at: string;
  inviter_id: string;
};

export type PartnerProgramme = {
  name: string;
  id: string;
  referral_code: string;
  initial_data_types: DataTypeName[];
};

export type Status = "OPEN" | "STALE_PRE_TENDER" | "PRE_TENDER" | "CLOSED" | "AWARDED";

export type StageFilter =
  | "stale_pre_tender"
  | "pre_tender"
  | "open"
  | "closed"
  | "awarded"
  | "STALE_PRE_TENDER"
  | "PRE_TENDER"
  | "OPEN"
  | "CLOSED"
  | "AWARDED";

type NoticeColumn =
  | "date_added"
  | "title"
  | "indicator"
  | "stage"
  | "close_date"
  | "buyer"
  | "buyer_categories"
  | "user_tag"
  | "published_date"
  | "expiry_date"
  | "award_date"
  | "value"
  | "country"
  | "suppliers"
  | "is_sme"
  | "qualification"
  | "hidden_date"
  | "assignee";

export type BuyerColumn =
  | "buyer_name"
  | "matching_records"
  | "open_ops"
  | "expiries"
  | "competitors"
  | "partners"
  | "signals";

export type BuyerSearchColumn =
  | "buyer_name"
  | "notices"
  | "country"
  | "town"
  | "region"
  | "signals";

export type ColumnSetting<F> = {
  field: F;
  width?: number;
  title?: string;
  disabled?: boolean;
};

export type TableSettings<F> = {
  columns: ColumnSetting<F>[];
};

/**
 * This filter represents a sum of signals,
 * defined either by id or by category name.
 *
 * If a category name is specified, all the signals from that category will be used.
 */
export type SignalFilters = {
  categories: SignalCategory[] | undefined;
  ids: string[] | undefined;
};

type FilterSettings = {
  text?: string;
  stage?: StageFilter[];
  buyer_ids?: number[];
  buyer_guids?: string[];
  country_code?: string[];
  close_date?: SerialisedDateRangeCriteria;
  close_nulls?: boolean;
  added_date?: SerialisedDateRangeCriteria;
  publish_date?: SerialisedDateRangeCriteria;
  expiry_date?: SerialisedDateRangeCriteria;
  expiry_nulls?: boolean;
  signals?: SignalFilters;
  buyer_category_id?: string[];
  buyer_category_nulls?: boolean;
  buyer_regions?: string[];
  buyer_country?: string[];
  value?: NumericRangeCriteria;
  value_nulls?: boolean;
  signal_category?: string[]; // TODO: Figure out how this parameter interacts with `signals`
  show_user_hidden_leads?: UserHiddenLeadsFilter;
  include_all_disqualified_states?: boolean;
  qualification_states?: string[];
  assignee?: string[];
  buyer_list_id?: string; // TODO: Remove buyerListId after backfill of views to use buyer_list_ids instead of buyer_list_id
  buyer_list_ids?: string[];
  procurement_stage_qualifications?: RecordSearchRequestDtoProcurementStageQualificationsEnum[];
};

export type SavedView = {
  id: string;
  team_id?: string | null;
  description?: string;
  owner_id: string | null;
  name: string;
  is_standard_view: boolean;
  view: {
    filter_settings: FilterSettings;
    sort_order: SortState;
    table_settings: TableSettings<NoticeColumn>;
  };
  updated_at?: string;
};

export type LeadSubscriptionFrequency = "DAILY" | "WEEKLY";

export type LeadSubscriptionType = "EMAIL" | "SLACK" | "TEAMS";

export type EmailSubscription = {
  id: string;
  user_id: string;
  company_id: string;
  frequency: LeadSubscriptionFrequency;
  type: "EMAIL";
  lead_subscription_contents: Record<string, LeadSubscriptionContents>;
  disabled: boolean;
};

export type IntegrationSubscription = {
  id: string;
  user_id: string;
  company_id: string;
  frequency: LeadSubscriptionFrequency;
  type: "SLACK" | "TEAMS";
  webhook_url: string;
  name: string;
  lead_subscription_contents: Record<string, LeadSubscriptionContents>;
  disabled: boolean;
};

export type LeadSubscription = EmailSubscription | IntegrationSubscription;

type LeadSubscriptionContents = {
  id: string;
  lead_subscription_id: string;
  resource_type: "SavedView" | "RecordList";
  resource_id: string;
};

export type DocumentMatch = {
  document: File;
  signal_ids: string[];
};

type QualificationStates = {
  default_qualification_state_id: string;
  default_disqualification_state_id: string;
  qualification_states: Record<string, QualificationStateConfig>;
} | null;

type QualificationStateConfig = {
  id: string;
  label: string;
  disqualifies_lead: boolean;
  company_id: string | null;
  rank: number;
};

export enum UserHiddenLeadsFilter {
  "SHOW_ALL" = "0",
  "SHOW_UNHIDDEN_ONLY" = "1",
  "SHOW_HIDDEN_ONLY" = "2",
}

export type BasicSignal = { id?: string; name: string; category: string };

// Used in filters as a "magic item" which selects all possible values of a field
export const SELECT_ALL = "__SELECT_ALL__";

export const ALL_COMPETITORS_TOKEN = "SIGNALS:ALL_COMPETITORS";
export const ALL_PARTNERS_TOKEN = "SIGNALS:ALL_PARTNERS";
export const ALL_KEYWORDS_TOKEN = "SIGNALS:ALL_KEYWORDS";
export const ALL_CPV_CODES_TOKEN = "SIGNALS:ALL_CPV_CODES";
