import React from "react";
import styled from "@emotion/styled";

import { lock01 } from "../../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { sysTextPlaceholder } from "../../../lib/themes/colors";
import { Flex } from "../../../styles/utility-components";

type RedactDataProps = {
  isRedacted?: boolean | null;
  bullets?: number;
  children?: React.ReactNode;
};

function RedactData({ isRedacted = false, bullets = 8, children }: RedactDataProps) {
  if (!isRedacted) return <>{children}</>;

  return (
    <Flex alignItems="center" gap="4px">
      <IconWrapper>
        <UIcon size={16} color={sysTextPlaceholder} svg={lock01} />
      </IconWrapper>
      <StyledBullets>{"•".repeat(bullets)}</StyledBullets>
    </Flex>
  );
}

export default RedactData;

const StyledBullets = styled.span({ color: sysTextPlaceholder });

const IconWrapper = styled.div({
  flexShrink: 0,
  flexGrow: 0,
  display: "flex",
  alignItems: "center",
});
