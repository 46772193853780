import React from "react";
import { Button } from "antd5";

import UIcon, { IconKey } from "../../../lib/icons/untitled_ui/UIcon";
import { sysTextSecondary } from "../../../lib/themes/colors";
import { capitalize } from "../../../lib/utils";
import { Flex, Text } from "../../../styles/utility-components";
// TODO extract to shared area?
type DisplayableEntities = "notice" | "document" | "framework" | "supplier" | "buyer";

type Props = {
  children: React.ReactNode;
  entity: DisplayableEntities;
  onClick: () => void;
};

const ICON_MAP: Record<DisplayableEntities, IconKey> = {
  notice: "file02",
  document: "box",
  framework: "grid01",
  supplier: "trophy01",
  buyer: "wallet02",
};

const rowHeader = (entity: DisplayableEntities) => {
  switch (entity) {
    case "notice":
    case "document":
    case "framework":
    case "buyer":
      return `${capitalize(entity)}s`;
    case "supplier":
      return "Relevant suppliers";
  }
};

export default function OpportunitySavedRow({ children, entity, onClick }: Props) {
  return (
    <Flex column gap={16}>
      <Flex alignItems="center" gap={8}>
        <UIcon icon={ICON_MAP[entity]} color={sysTextSecondary} />
        <Text color={sysTextSecondary}>{rowHeader(entity)}</Text>
        <Button
          icon={<UIcon icon="plus" color={sysTextSecondary} />}
          type="link"
          onClick={onClick}
        />
      </Flex>
      {children}
    </Flex>
  );
}
