import React, { useState } from "react";
import { useIsDevCycleInitialized } from "@devcycle/react-client-sdk";
import { Button } from "antd5";
import Link from "antd5/lib/typography/Link";
import { Redirect } from "wouter";

import { DocumentSlideout } from "components/bid_module/DocumentSlideout";
import { EditAnswer } from "components/bid_module/EditAnswer";
import { useVariableValue } from "lib/featureFlags";
import { useGetOrQualifyBid, useQualifyBid } from "lib/hooks/api/bid/scorecard";
import { arrowLeft, fileSearch02, minus, plus } from "lib/icons/untitled_ui/SVGs";
import UIcon from "lib/icons/untitled_ui/UIcon";
import { exportDataToCsv } from "../../lib/exportArrayToCsv";
import { BidQualification_Result_Sources } from "../../lib/generated/app-service-gql/graphql";
import { grey700 } from "../../lib/themes/colors";
import { Flex, Text } from "../../styles/utility-components";
import { DetailsContent, DetailsHeader, DetailsPage } from "../app_layout/DetailsLayout";
import LoadingState from "../buyer_details/question_panel/LoadingState";
import DocumentDrawer from "./DocumentViewer";

import css from "./ScorecardPage.module.scss";

export function ScorecardPage({
  noticeId,
  procurementStageId,
  noticeTitle,
  buyerName,
  bidId,
}: {
  noticeId: string;
  procurementStageId: string;
  noticeTitle: string;
  buyerName: string;
  bidId: string;
}) {
  const [resultData, setResultData] = useState<BidQualification_Result_Sources | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [slideOutOpen, setSlideOutOpen] = useState(false);
  const [debug, setDebug] = useState(false);

  const devCycleReady = useIsDevCycleInitialized();
  const isEnabled = useVariableValue("notice-auto-qualification", false);
  const { mutate, isLoading: isQualifying } = useQualifyBid();
  const {
    data,
    isLoading: isQuerying,
    refetch,
  } = useGetOrQualifyBid(
    {
      bidId,
      procurementStageId,
    },
    {
      enabled: !!isEnabled,
    },
  );

  const isAdmin = window.currentUser?.admin || false;

  const handleCSVExport = React.useCallback(() => {
    if (data) {
      const questionsAndAnswers = data.flatMap((item) =>
        item.questions.map((question) => ({
          question: question.title,
          answer: question.answer?.answer || null,
        })),
      );
      const csvData = [
        { question: "Scorecard link", answer: window.location.href },
        { question: "Question", answer: "Answer" },
        ...questionsAndAnswers,
      ];
      exportDataToCsv("scorecard.csv", csvData, false);
    }
  }, [data]);

  if (devCycleReady && !isEnabled) {
    return <Redirect to="/" />;
  }

  function generate() {
    mutate(
      { input: { procurementStageId, bidId } },
      {
        onSuccess: async () => {
          await refetch();
        },
      },
    );
  }

  const isLoading = isQuerying || isQualifying;

  if (isLoading) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        height="100vh"
        width="100vw"
        flexDirection="column"
      >
        <LoadingState />
        <Text h2>Analysing documents...</Text>
      </Flex>
    );
  }

  return (
    <DetailsPage>
      <DetailsHeader className={css.header}>
        <Link href={`/records/${noticeId}`} className={css.backLink}>
          <UIcon svg={arrowLeft} className={css.tooltipIcon} size={16} />
          Back
        </Link>
        <div>
          <Link onClick={() => setSlideOutOpen(!slideOutOpen)}>Documents</Link>
          <Button
            className={css.downloadCsv}
            type="primary"
            onClick={handleCSVExport}
            disabled={!data || data.length == 0}
          >
            Download CSV
          </Button>
        </div>
      </DetailsHeader>
      <DetailsContent>
        <Flex height="100vh">
          <div className={css.scoreCardWrapper}>
            <div className={css.noticeHeader}>
              <div className={css.title}>
                <Text h1 className={css.scorecardTitle}>
                  Qualification Report
                </Text>
                <p className={css.noticeTitle}>{noticeTitle}</p>
                <p>{buyerName}</p>
              </div>
            </div>

            {(data ?? []).map(({ groupName, questions }) => (
              <details key={groupName}>
                <summary>
                  <Text h3 className={css.groupName}>
                    {groupName}
                  </Text>
                  <span>
                    <UIcon svg={minus} className={css.opened} size={24} color={grey700} />
                    <UIcon svg={plus} className={css.closed} size={24} color={grey700} />
                  </span>
                </summary>
                <section>
                  {questions.map((question) => (
                    <article key={question.title}>
                      <Text className={css.questionTitle}>{question.title}</Text>
                      <aside>
                        <p>
                          <EditAnswer answer={question.answer} answerId={question.answerId} />
                        </p>
                        {(question.sources ?? []).map((source, idx) => (
                          <>
                            <Link
                              key={idx}
                              className={css.sourceLink}
                              onClick={() => {
                                setDrawerOpen(true);
                                setResultData({
                                  annotations: source.annotations ?? [],
                                  document: source.document,
                                  documentId: source.documentId,
                                  documentLink: source.documentLink,
                                });
                              }}
                            >
                              <UIcon svg={fileSearch02} className={css.tooltipIcon} size={12} />
                              Link to source document
                            </Link>
                            {debug ? <pre>{JSON.stringify(source, null, 2)}</pre> : null}
                          </>
                        ))}
                      </aside>
                    </article>
                  ))}
                </section>
              </details>
            ))}
            {isAdmin && (
              <Flex gap="24px">
                <Button onClick={() => setDebug(!debug)}>Toggle debug</Button>
                <Button type="primary" disabled={isLoading} onClick={generate}>
                  Generate report
                </Button>
              </Flex>
            )}
          </div>
          {slideOutOpen ? <DocumentSlideout bidId={bidId} /> : null}
        </Flex>
        {resultData && (
          <DocumentDrawer
            open={drawerOpen}
            source={resultData}
            onClose={() => setDrawerOpen(false)}
          />
        )}
      </DetailsContent>
    </DetailsPage>
  );
}
