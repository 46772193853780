import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  UpdateBidAnswerByIdMutation,
  UpdateBidAnswerByIdMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "lib/hooks/api/useGraphQlClient";

export const updateBidAnswerById = graphql(`
  mutation UpdateBidAnswerById($input: UpdateBidAnswerRequest!) {
    updateBidAnswerById(UpdateBidAnswerRequest: $input) {
      answer {
        answer
      }
    }
  }
`);

export function useUpdateBidAnswerById(
  options?: UseMutationOptions<
    UpdateBidAnswerByIdMutation,
    unknown,
    UpdateBidAnswerByIdMutationVariables,
    unknown
  >,
) {
  const queryClient = useQueryClient();
  const { data, ...rest } = useGraphQlMutation(updateBidAnswerById, {
    ...options,
    onSuccess: async (data, vars, ctx) => {
      options?.onSuccess?.(data, vars, ctx);
      await queryClient.invalidateQueries(["bid_qualify_bid"]);
    },
  });

  return { data: data?.updateBidAnswerById, ...rest };
}
