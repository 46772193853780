import React from "react";
import styled from "@emotion/styled";
import { Flex, Text } from "styles/utility-components";

import SendInviteModal from "components/modals/SendInviteModal";
import { useUserAndTeamDetails } from "lib/hooks/api/homepage/useUserAndTeamDetails";
import { useDialogManager } from "lib/providers/DialogManager";
import { settingsRoutes } from "lib/routes";
import { Box } from "./util";

function WelcomeBox() {
  const dialogManager = useDialogManager();
  const { data } = useUserAndTeamDetails(window.currentUser?.guid ?? "", {
    // Current iteration of this widget doesn't actually need this API call since
    // everything is available in window.currentUser. Just disabling for now as I
    // imagine the next iteration will probably need it.
    enabled: false, // window.currentUser != null,
  });

  if (window.currentUser == null) {
    return null;
  }
  const subscription = data?.user.subscription ?? window.currentUser.subscription;

  return (
    <Box>
      <Flex column gap={24}>
        <Text h1>
          Welcome to Stotles, <div>{data?.user.firstName ?? window.currentUser?.first_name}</div>
        </Text>
        <Flex column gap={4}>
          <TextItemHeader>Your plan</TextItemHeader>
          <Text h3 textTransform="capitalize">
            {subscription === "freemium" ? "free" : subscription}
          </Text>
          <Link href={settingsRoutes.subscription}>
            {subscription === "growth" ? "View your plan features" : "Upgrade your plan"}
          </Link>
        </Flex>
        <Flex column gap={4}>
          <TextItemHeader>Your team</TextItemHeader>
          <Text h3>{data?.team.name ?? window.currentUser?.team.name}</Text>
          <LinkStyleButton onClick={() => dialogManager.openDialog(SendInviteModal, {})}>
            Invite team members
          </LinkStyleButton>
        </Flex>
      </Flex>
    </Box>
  );
}

const Link = styled.a(({ theme }) => ({
  color: theme.colors.sysTextDefault,
  "&:hover": {
    color: theme.colors.sysTextDefault,
    textDecoration: "underline",
  },
}));

const LinkStyleButton = styled.button(({ theme }) => ({
  color: theme.colors.sysTextDefault,
  background: "none",
  border: "none",
  padding: 0,
  textAlign: "left",
  cursor: "pointer",

  "&:hover": {
    color: theme.colors.sysTextDefault,
    textDecoration: "underline",
  },
}));

const TextItemHeader = styled(Text)(({ theme }) => ({
  color: theme.colors.sysTextSecondary,
}));

export default WelcomeBox;
