import { useEffect } from "react";

export const useUnsavedChangeWarning = (isUnsaved: boolean) => {
  useEffect(() => {
    if (isUnsaved) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [isUnsaved]);
};
