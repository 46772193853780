import { useQuery } from "@tanstack/react-query";

import { ListPendingUploads200Response } from "lib/generated/data-svc";
import { useOpenApi } from "lib/openApiContext";

type Props = {
  title?: string;
  limit: number;
  offset: number;
  sort?: {
    field: string;
    order: "ASC" | "DESC";
  };
};

export function usePendingDocuments(request: Props) {
  const api = useOpenApi();

  return useQuery(["list-pending-uploads", request], async () => {
    const response = await api.listPendingUploads({ body: request });
    return response as ListPendingUploads200Response;
  });
}

export type UsePendingDocuments = typeof usePendingDocuments;
