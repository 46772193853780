import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd5";

import { LLMProvider } from "../../lib/types/graphQLEnums";
import { Radio, TextArea } from "../form_components/Inputs";

import css from "./NoticeQualificationForm.module.scss";

type QualifyNoticeForm = {
  procurementStageId: string;
  qualificationQuestions: { question: string }[];
  llmProvider: LLMProvider;
  llmPrompt: string;
};
export type QualificationFormValues = {
  procurementStageId: string;
  qualificationQuestions: string[];
  llmProvider: LLMProvider;
  llmPrompt: string;
};

type Props = {
  procurementStageId: string;
  defaultValues?: QualificationFormValues;
  onSubmit: (values: QualificationFormValues) => void;
};
export function NoticeQualifcationForm({
  procurementStageId,
  onSubmit,
  defaultValues = {
    procurementStageId,
    qualificationQuestions: [],
    llmProvider: LLMProvider.Mistral,
    llmPrompt: "",
  },
}: Props) {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<QualifyNoticeForm>({
    defaultValues: {
      llmPrompt: defaultValues.llmPrompt,
      llmProvider: defaultValues.llmProvider,
      procurementStageId: defaultValues.procurementStageId,
      qualificationQuestions: defaultValues.qualificationQuestions.map((v) => ({ question: v })),
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "qualificationQuestions",
    rules: { required: true, minLength: 1 },
  });

  return (
    <form
      onSubmit={handleSubmit((values) =>
        onSubmit({
          ...values,
          qualificationQuestions: values.qualificationQuestions.map((q) => q.question),
        }),
      )}
      className={css.form}
    >
      <Radio
        control={control}
        name="llmProvider"
        label="LLM Service"
        options={[
          { label: "Mistral", value: LLMProvider.Mistral },
          { label: "OpenAI", value: LLMProvider.Openai },
        ]}
      />

      <TextArea
        control={control}
        name="llmPrompt"
        label="LLM Prompt"
        rules={{ minLength: 10, required: true }}
        className={css.questionInput}
        rows={8}
      />

      {fields.map((field, index) => (
        <div key={field.id} className={css.inputContainer}>
          <TextArea
            control={control}
            name={`qualificationQuestions.${index}.question`}
            label={`Question ${index + 1}`}
            rules={{ minLength: 10, required: true }}
            className={css.questionInput}
          />
          <Button type="link" icon={<CloseOutlined />} onClick={() => remove(index)} />
        </div>
      ))}
      <Button type="dashed" onClick={() => append({ question: "" })} block icon={<PlusOutlined />}>
        Add question
      </Button>
      <div>
        <Button type="primary" htmlType="submit" disabled={!isValid}>
          Qualify
        </Button>
      </div>
    </form>
  );
}
