import React from "react";
import styled from "@emotion/styled";

import Banner from "./Banner";
import Opportunities from "./Opportunities";
import Pipelines from "./Pipelines";
import UsefulLinks from "./UsefulLinks";
import WelcomeBox from "./WelcomeBox";

/*
Explanation on why useful links exists twice, showing depending on columns.

The homepage should be in a masonry-style layout, going to a single column on smaller screens.
UsefulLinks should always be under WelcomeBox on larger screens but when it goes to one column it should be at the very bottom.

We have a few CSS layout options available to us:
- CSS Grid: Would require JS for our use case as each element can have a different height
  Couldn't hardcode the heights as it depends on the screen width (and it's too much to hardcode with media queries).
- CSS Grid Masonary: Perfect solution but it's currently got 0 relevant browser support (just Safari Technology Preview).
- CSS Columns: Would enforce all columns to have the same width.
- Flexbox: (Chosen) Allows for different column widths and heights.
  - Two elements (Chosen): Have to have media query which swaps visibility.
  - Using order: to reposition UsefulLinks would have accessibility issues since it breaks the tab order in relation to visual order.
    Also, would require everything in a single Flex container which would make the layout harder to manage.
    + would still need to use a media query to set the order.
*/
function HomepageContent() {
  return (
    <PageWrapper>
      <LeftColumn>
        <WelcomeBox />
        <UsefulLinks column="2+" /* Hidden on 1 column layout */ />
      </LeftColumn>
      <RightColumn>
        <Banner />
        <Opportunities />
        <Pipelines />
        <UsefulLinks column="1" /* Hidden on 2 or more column layout */ />
      </RightColumn>
    </PageWrapper>
  );
}

// If you change the width 1 column happens at, also update UsefulLinks
const PageWrapper = styled.div(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  minHeight: "100%",
  display: "flex",
  gap: "64px",
  flexWrap: "nowrap" as const,
  justifyContent: "center",
  margin: "0 auto",
  padding: "24px 40px",
  backgroundColor: theme.colors.sysBackgroundAlternative,

  // 1 Column layout
  "@media screen and (max-width: 992px)": {
    flexWrap: "wrap" as const,
    gap: "48px", // To match other gaps between elements
  },
  // Mobile layout
  "@media screen and (max-width: 576px)": {
    padding: "24px",
  },
}));

const LeftColumn = styled.div(() => ({
  display: "flex",
  flexDirection: "column" as const,
  flexGrow: 1,
  flexShrink: 0,
  gap: "16px",
  height: "fit-content",
  minWidth: "300px",
  maxWidth: "300px",

  "@media screen and (max-width: 992px)": {
    width: "100%",
    minWidth: "0px",
    maxWidth: "100%",
  },
}));

const RightColumn = styled.div(() => ({
  display: "flex",
  flexDirection: "column" as const,
  gap: "40px",
  flexGrow: 1,
  minWidth: "0px",
  maxWidth: "916px", // 1280px wanted page max width - 64px gap - 300px left column
}));

export default HomepageContent;
