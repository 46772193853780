/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserDetailsCompany } from './UserDetailsCompany';
import {
    UserDetailsCompanyFromJSON,
    UserDetailsCompanyFromJSONTyped,
    UserDetailsCompanyToJSON,
} from './UserDetailsCompany';
import type { UserDetailsTeam } from './UserDetailsTeam';
import {
    UserDetailsTeamFromJSON,
    UserDetailsTeamFromJSONTyped,
    UserDetailsTeamToJSON,
} from './UserDetailsTeam';
import type { UserSavedView } from './UserSavedView';
import {
    UserSavedViewFromJSON,
    UserSavedViewFromJSONTyped,
    UserSavedViewToJSON,
} from './UserSavedView';

/**
 * 
 * @export
 * @interface UserDetails
 */
export interface UserDetails {
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    guid: string;
    /**
     * 
     * @type {number}
     * @memberof UserDetails
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    firstName: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    lastName: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    email: string;
    /**
     * 
     * @type {UserDetailsTeam}
     * @memberof UserDetails
     */
    team: UserDetailsTeam;
    /**
     * 
     * @type {UserDetailsCompany}
     * @memberof UserDetails
     */
    company: UserDetailsCompany;
    /**
     * 
     * @type {Array<UserSavedView>}
     * @memberof UserDetails
     */
    availableSavedViews: Array<UserSavedView>;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    hasVerifiedEmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    showBuyerCategories: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    sendTiUpdates: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    hasExportAccess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    hasAccountBriefingAccess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    configured: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    companyGuid: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    subscription: UserDetailsSubscriptionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    admin: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    role: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    active: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    auth0Id: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    hubspotContactId: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    userPersona?: UserDetailsUserPersonaEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    pubSecExperience?: UserDetailsPubSecExperienceEnum;
}


/**
 * @export
 */
export const UserDetailsSubscriptionEnum = {
    Freemium: 'freemium',
    Basic: 'basic',
    Growth: 'growth',
    Expert: 'expert'
} as const;
export type UserDetailsSubscriptionEnum = typeof UserDetailsSubscriptionEnum[keyof typeof UserDetailsSubscriptionEnum];

/**
 * @export
 */
export const UserDetailsUserPersonaEnum = {
    StrategyLeadership: 'Strategy / Leadership',
    OperationalEndUser: 'Operational / End User'
} as const;
export type UserDetailsUserPersonaEnum = typeof UserDetailsUserPersonaEnum[keyof typeof UserDetailsUserPersonaEnum];

/**
 * @export
 */
export const UserDetailsPubSecExperienceEnum = {
    None: 'none',
    Limited: 'limited',
    Familiar: 'familiar',
    Mature: 'mature',
    Buyer: 'buyer'
} as const;
export type UserDetailsPubSecExperienceEnum = typeof UserDetailsPubSecExperienceEnum[keyof typeof UserDetailsPubSecExperienceEnum];


/**
 * Check if a given object implements the UserDetails interface.
 */
export function instanceOfUserDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "guid" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "team" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "availableSavedViews" in value;
    isInstance = isInstance && "hasVerifiedEmail" in value;
    isInstance = isInstance && "showBuyerCategories" in value;
    isInstance = isInstance && "sendTiUpdates" in value;
    isInstance = isInstance && "hasExportAccess" in value;
    isInstance = isInstance && "hasAccountBriefingAccess" in value;
    isInstance = isInstance && "configured" in value;
    isInstance = isInstance && "companyGuid" in value;
    isInstance = isInstance && "subscription" in value;
    isInstance = isInstance && "admin" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "auth0Id" in value;
    isInstance = isInstance && "hubspotContactId" in value;

    return isInstance;
}

export function UserDetailsFromJSON(json: any): UserDetails {
    return UserDetailsFromJSONTyped(json, false);
}

export function UserDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guid': json['guid'],
        'id': json['id'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'email': json['email'],
        'team': UserDetailsTeamFromJSON(json['team']),
        'company': UserDetailsCompanyFromJSON(json['company']),
        'availableSavedViews': ((json['available_saved_views'] as Array<any>).map(UserSavedViewFromJSON)),
        'hasVerifiedEmail': json['has_verified_email'],
        'showBuyerCategories': json['show_buyer_categories'],
        'sendTiUpdates': json['send_ti_updates'],
        'hasExportAccess': json['has_export_access'],
        'hasAccountBriefingAccess': json['has_account_briefing_access'],
        'configured': json['configured'],
        'companyGuid': json['company_guid'],
        'subscription': json['subscription'],
        'admin': json['admin'],
        'role': json['role'],
        'active': json['active'],
        'auth0Id': json['auth0_id'],
        'hubspotContactId': json['hubspot_contact_id'],
        'userPersona': !exists(json, 'user_persona') ? undefined : json['user_persona'],
        'pubSecExperience': !exists(json, 'pub_sec_experience') ? undefined : json['pub_sec_experience'],
    };
}

export function UserDetailsToJSON(value?: UserDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guid': value.guid,
        'id': value.id,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'email': value.email,
        'team': UserDetailsTeamToJSON(value.team),
        'company': UserDetailsCompanyToJSON(value.company),
        'available_saved_views': ((value.availableSavedViews as Array<any>).map(UserSavedViewToJSON)),
        'has_verified_email': value.hasVerifiedEmail,
        'show_buyer_categories': value.showBuyerCategories,
        'send_ti_updates': value.sendTiUpdates,
        'has_export_access': value.hasExportAccess,
        'has_account_briefing_access': value.hasAccountBriefingAccess,
        'configured': value.configured,
        'company_guid': value.companyGuid,
        'subscription': value.subscription,
        'admin': value.admin,
        'role': value.role,
        'active': value.active,
        'auth0_id': value.auth0Id,
        'hubspot_contact_id': value.hubspotContactId,
        'user_persona': value.userPersona,
        'pub_sec_experience': value.pubSecExperience,
    };
}

