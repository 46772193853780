import React from "react";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";
import { App, Button, Modal } from "antd5";

import { Input } from "components/form_components/Inputs";
import { assertCurrentUser } from "lib/currentUser";
import { GetRecordListsResponseResult, RecordListSummaryDto } from "lib/generated/app-api";
import { useDeleteNoticeList } from "lib/hooks/api/notices/lists/useDeleteNoticeList";
import { useDuplicateNoticeList } from "lib/hooks/api/notices/lists/useDuplicateNoticeList";
import { useNoticeLists } from "lib/hooks/api/notices/lists/useNoticeLists";
import { useRenameNoticeList } from "lib/hooks/api/notices/lists/useRenameNoticeList";
import { EventData } from "lib/tracking";
import { UserListSummaryWithCount } from "lib/types/models";

type DeleteListProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete?: (success: boolean) => void;
  list: GetRecordListsResponseResult | RecordListSummaryDto;
  trackingData: EventData;
};

export function canEditList(list?: GetRecordListsResponseResult | RecordListSummaryDto): boolean {
  const user = assertCurrentUser();
  if (!list) {
    return false;
  }
  if ("user" in list) {
    return list.user.guid === user.guid;
  }
  return list.ownerId === user.id;
}

export function DeleteListModal({
  isOpen,
  onClose,
  onDelete,
  list,
  trackingData,
}: DeleteListProps): JSX.Element {
  const { message } = App.useApp();
  const { mutate, isLoading } = useDeleteNoticeList({
    onSuccess: () => {
      void message.success(`Successfully deleted "${list.name}"`, 3);
      onDelete?.(true);
      onClose();
    },
    onError: () => message.error(`Failed to delete "${list.name}"`),
  });

  return (
    <Modal
      open={isOpen}
      closable={false}
      onCancel={onClose}
      footer={
        <>
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button
            key="save"
            danger
            onClick={() => mutate({ list, trackingData })}
            loading={isLoading}
          >
            Delete
          </Button>
        </>
      }
      title={`Delete "${list.name}"?`}
    >
      <p>Are you sure you want to delete this list? This action cannot be undone.</p>
    </Modal>
  );
}

type RenameListModalProps = {
  isOpen: boolean;
  onClose: () => void;
  list: GetRecordListsResponseResult | RecordListSummaryDto;
  trackingData: EventData;
};

export function RenameListModal({
  isOpen,
  onClose,
  list,
  trackingData,
}: RenameListModalProps): JSX.Element {
  const { message } = App.useApp();
  const { mutate, isLoading } = useRenameNoticeList({
    onSuccess: () => onClose(),
    onError: () => message.error("Unable to rename list"),
  });

  return (
    <Modal open={isOpen} footer={null} title={`Rename "${list.name}"?`} closable={false}>
      <ListForm
        listName={list.name}
        onSubmit={(data) => mutate({ name: data.name, list, trackingData })}
        onCancel={onClose}
        isSubmitting={isLoading}
      />
    </Modal>
  );
}

type DuplicateListModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: (list: UserListSummaryWithCount) => void;
  list: GetRecordListsResponseResult | RecordListSummaryDto;
  trackingData: EventData;
};

export function DuplicateListModal({
  isOpen,
  onClose,
  onSuccess,
  list,
  trackingData,
}: DuplicateListModalProps): JSX.Element {
  const { message } = App.useApp();
  const { mutate, isLoading } = useDuplicateNoticeList({
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
      onClose();
    },
    onError: () => message.error("Unable to duplicate list"),
  });

  return (
    <Modal open={isOpen} footer={null} title={`Duplicate "${list.name}"?`} closable={false}>
      <ListForm
        listName={list.name}
        onSubmit={(data) => mutate({ name: data.name, list, trackingData })}
        onCancel={onClose}
        isSubmitting={isLoading}
      />
    </Modal>
  );
}

export function ListForm({
  listName,
  onSubmit,
  onCancel,
  isSubmitting,
  footer,
}: {
  listName: string;
  onSubmit: (data: { name: string }) => void;
  onCancel: () => void;
  isSubmitting: boolean;
  footer?: React.ReactNode;
}) {
  const { control, handleSubmit } = useForm<{ name: string }>({
    defaultValues: { name: listName },
  });
  const { data } = useNoticeLists();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        control={control}
        name="name"
        label="List name"
        placeholder="Type list name here..."
        rules={{
          required: { value: true, message: "A name is required" },
          validate: (val) => {
            if (data?.listNames.map((l) => l.toLowerCase()).includes(val.toLowerCase())) {
              return "This name is already in use";
            }
            return true;
          },
        }}
      />
      {footer ? (
        footer
      ) : (
        <FormFooter>
          <Button onClick={() => onCancel()}>Cancel</Button>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            Save
          </Button>
        </FormFooter>
      )}
    </Form>
  );
}

const Form = styled.form({
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

const FormFooter = styled.div({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: 20,
  gap: 8,
});
