import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { UserAndTeamDetailsQuery } from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";

const query = graphql(`
  query userAndTeamDetails($userId: String!) {
    user(id: $userId) {
      firstName
      subscription
    }
    team {
      name
    }
  }
`);

export function useUserAndTeamDetails(
  userId: string,
  options?: UseQueryOptions<UserAndTeamDetailsQuery, unknown, UserAndTeamDetailsQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["userAndTeamDetails", userId],
    query,
    [{ userId }],
    options,
  );

  return { data, ...rest };
}
