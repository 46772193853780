import React from "react";
import styled from "@emotion/styled";
import { Flex, Text } from "styles/utility-components";

import { currencyFormat, numberFormatLong } from "components/app_layout/Typography/Numbers";
import { convertViewToNoticeFilters } from "components/my_feed/useMyFeedPageState";
import {
  convertNoticeFiltersToSearchRequest,
  generateNoticeSearchUrl,
} from "components/notices/utils";
import SkeletonText from "components/ui/skeleton/SkeletonText";
import { useVariableValue } from "lib/featureFlags";
import { SavedViewType } from "lib/generated/app-api";
import { useNoticesOverall } from "lib/hooks/api/homepage/useNoticesOverall";
import { useAllViews } from "lib/hooks/api/notices/views/useAllViews";
import UIcon from "lib/icons/untitled_ui/UIcon";
import { Box } from "./util";

/**
 * @deprecated Don't use this, outside of homepage. Preferably this wouldn't be done at all, but the alternative is matching on the name
 */
export const DEFAULT_VIEW_IDS = {
  openOpportunities: "b568b291-4567-496f-bbf8-8165002f2ce5",
  upcomingContractExpiries: "c735a82a-4fea-4a97-84a8-7f9638e64c25",
};

function Opportunities() {
  const { data: allViews } = useAllViews();

  return (
    <Flex column gap={24} flexWrap="wrap">
      <Text h2>Find opportunities</Text>
      <Flex gap={16} flexWrap="wrap" width="100%">
        <NoticeBox
          title="Expiring contracts"
          viewId="upcomingContractExpiries"
          allViews={allViews}
        />
        <NoticeBox title="Open tenders" viewId="openOpportunities" allViews={allViews} />
      </Flex>
    </Flex>
  );
}

const NoticeBox = ({
  title,
  viewId,
  allViews,
}: {
  title: string;
  viewId: keyof typeof DEFAULT_VIEW_IDS;
  allViews?: SavedViewType[];
}) => (
  <NoticeBoxContainer>
    <ContainerLink href={generateNoticeSearchUrl({}, DEFAULT_VIEW_IDS[viewId])}>
      <Flex column gap={24}>
        <TitleContainer gap={8} justifyContent="space-between">
          <Text h3>{title}</Text>
          <UIcon icon="arrowRight" size={24} />
        </TitleContainer>
        <NoticeBoxContent
          filters={
            allViews?.find((view) => view.id === DEFAULT_VIEW_IDS[viewId]) as
              | SavedViewType
              | undefined
          }
        />
      </Flex>
    </ContainerLink>
  </NoticeBoxContainer>
);

const NoticeBoxContent = ({ filters }: { filters?: SavedViewType }) => {
  const isLayerCakeEnabled = useVariableValue("layer-cake-filters", false);

  const { data } = useNoticesOverall(
    convertNoticeFiltersToSearchRequest(
      convertViewToNoticeFilters(
        filters ??
          // Fallback below should never be used due to the enabled check
          // However, we need to have this to satisfy the TS compiler
          // Know it has more propeties than required, but that's so the function
          // doesn't error if somehow the fallback is somehow used
          ({
            name: "Fallback",
            isStandardView: false,
            view: {
              filterSettings: {},
              sortOrder: {},
              tableSettings: {},
            },
          } as SavedViewType),
        isLayerCakeEnabled,
      ),
    ),
    { enabled: filters !== undefined },
  );
  const totalValue = data?.values?.combined?.gbp;

  if (data?.totalResults === undefined || totalValue === undefined) {
    return (
      <Flex column gap={8}>
        <Flex gap={8}>
          <SkeletonText width="60px" fontSize={24} lineHeight={1.33} />
          <Text h1 color="inherit">
            notices
          </Text>
        </Flex>
        <Flex gap={4}>
          <SkeletonText width="80px" fontSize={14} lineHeight={1.57} />
          <Text>total value</Text>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex column gap={8}>
      <CountContainer>
        <CountNumber>{numberFormatLong(data.totalResults)}</CountNumber> notices
      </CountContainer>
      <Text>{currencyFormat(totalValue, "GBP")} total value</Text>
    </Flex>
  );
};

const NoticeBoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  flexBasis: 0,
  minWidth: "min(260px, 100%)", // Need to force to 1 column to avoid text wrapping
  padding: 0,
  color: theme.colors.sysPrimaryDefault,

  // Can't do on the actual elemnents since we need to do hover on the whole box
  [`${TitleContainer} svg`]: {
    transition: "transform 0.2s",
  },
  "&:hover": {
    backgroundColor: theme.colors.sysPrimarySubtle,
    borderColor: theme.colors.sysPrimaryHover,
    color: theme.colors.sysPrimaryHover,

    [`${TitleContainer}`]: {
      textDecoration: "underline",
      textDecorationColor: theme.colors.sysTextBlack,
      "& svg": {
        transform: "translateX(8px)",
      },
    },
  },
  "&:active": {
    backgroundColor: theme.colors.sysPrimarySubtle,
    borderColor: theme.colors.sysPrimaryPressed,
    color: theme.colors.sysPrimaryPressed,
  },
}));

const TitleContainer = styled(Flex)(() => ({}));

const ContainerLink = styled.a(() => ({
  padding: "24px",
  width: "100%",
}));

const CountContainer = styled.h3(({ theme }) => ({
  color: theme.colors.sysPrimaryDefault,
  lineHeight: 1.15,
  fontWeight: 300,
  fontSize: "17px",
}));

const CountNumber = styled.span({
  lineHeight: 1.25,
  fontWeight: 800,
  fontSize: "24px",
});

export default Opportunities;
