import * as React from "react";
import { Button, ConfigProvider } from "antd5";
import { TooltipProps } from "antd5/lib/tooltip";
import classNames from "classnames";

import { ViewConfigurationFilterSettingsRelevanceScoreEnum } from "lib/generated/app-api";
import RelevanceIcon from "lib/icons/Relevance";
import { RelevanceButton } from "lib/themes/RelevanceConfig";

import css from "./RelevanceScoreButtons.module.scss";

type Props = {
  onChange: (values: ViewConfigurationFilterSettingsRelevanceScoreEnum[]) => void;
  id?: string;
  checkedRelevanceScores: ViewConfigurationFilterSettingsRelevanceScoreEnum[];
  tooltipPlacement?: TooltipProps["placement"];
};

// Because this is used within Form.Item we have to wrap it in a forwardRef
export const RelevanceScoreButtons = React.forwardRef<HTMLDivElement, Props>(
  ({ onChange, id, checkedRelevanceScores }, ref) => {
    const handleSelect = React.useCallback(
      (stage: ViewConfigurationFilterSettingsRelevanceScoreEnum) => {
        if (checkedRelevanceScores.includes(stage)) {
          onChange(checkedRelevanceScores.filter((v) => v !== stage));
        } else {
          onChange([...checkedRelevanceScores, stage]);
        }
      },
      [checkedRelevanceScores, onChange],
    );

    RelevanceScoreButtons.displayName = "RelevanceScoreButtons";

    const isSelected = (value: string) =>
      Object.values(checkedRelevanceScores).includes(
        value as ViewConfigurationFilterSettingsRelevanceScoreEnum,
      );

    return (
      <div className={css.relevanceScoreButtonContainer} id={id} ref={ref}>
        <ConfigProvider theme={RelevanceButton}>
          <>
            {Object.keys(ViewConfigurationFilterSettingsRelevanceScoreEnum).map((key, index) => (
              <Button
                key={key}
                className={classNames(
                  css.relevanceScoreButton,
                  isSelected(key) ? css.selected : undefined,
                )}
                onClick={() =>
                  handleSelect(key as ViewConfigurationFilterSettingsRelevanceScoreEnum)
                }
              >
                <RelevanceIcon darkMode={isSelected(key)} relevance={index} />
                <b>{index}</b>
              </Button>
            ))}
          </>
        </ConfigProvider>
      </div>
    );
  },
);
