import * as React from "react";
import { Empty, Input, Modal } from "antd5";

import { ReferenceOrgList } from "components/organisation_clean/OrgListPanel";
import { ReferenceOrgDto } from "lib/generated/app-api";
import { useReferenceOrgs } from "lib/hooks/api/reference_orgs/useReferenceOrgs";

type Props = {
  handleChange: (chOrg?: ReferenceOrgDto) => void;
  isOpen: boolean;
  onClose: () => void;
};

export default function CompaniesHouseSearchModal({ handleChange, isOpen, onClose }: Props) {
  const [textSearch, setTextSearch] = React.useState<string>("");

  const { data: orgs } = useReferenceOrgs({ text: textSearch, source: "companies_house" });

  return (
    <Modal
      style={{ top: "5vh", minWidth: "1000px" }}
      styles={{ body: { height: "90vh" } }}
      open={isOpen}
      onCancel={onClose}
      footer={null}
    >
      <div>
        <header>
          <h1>Companies House search</h1>
          <Input
            placeholder="Search by name..."
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
          />
        </header>
        <div>
          {orgs?.length ? (
            <ReferenceOrgList
              orgs={orgs}
              onSelect={(org) => {
                handleChange(org);
                onClose();
              }}
            />
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </Modal>
  );
}
