import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  OpportunitiesQuery,
  OpportunitiesQueryVariables,
  PaginationOptions,
  SearchOpportunitiesRequest,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlInfiniteQuery, useGraphQlQuery } from "../useGraphQlClient";

const OPPORTUNITIES_QUERY = graphql(`
  query opportunities($options: SearchOpportunitiesRequest!, $pagination: PaginationOptions!) {
    opportunities(SearchOpportunityRequest: $options, PaginationOptions: $pagination) {
      results {
        id
        name
        description
        value
        assignedTo {
          guid
          firstName
          lastName
        }
        stage {
          name
          color
        }
        closeDate
      }
      total
    }
  }
`);

export type OpportunityFromSearch = OpportunitiesQuery["opportunities"]["results"][number];

export function useOpportunities(
  request: SearchOpportunitiesRequest,
  pagination: PaginationOptions,
  options?: UseQueryOptions<OpportunitiesQuery, unknown, OpportunitiesQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["opportunities", request, pagination],
    OPPORTUNITIES_QUERY,
    [{ options: request, pagination }],
    options,
  );
  return { data: data?.opportunities, ...rest };
}

export function useInfiniteOpportunities(request: SearchOpportunitiesRequest) {
  return useGraphQlInfiniteQuery<OpportunitiesQuery, OpportunitiesQueryVariables>(
    ["opportunities", request],
    OPPORTUNITIES_QUERY,
    (page) => [{ options: request, pagination: { page, pageSize: 10 } }],
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.opportunities.total > allPages.length * 10
          ? allPages.length + 1
          : undefined;
      },
      getPreviousPageParam: (_, allPages) => allPages.length - 1 || 1,
    },
  );
}
