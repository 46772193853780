import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  SearchAdminUsersQuery,
  SearchUserRequest,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const searchAdminUsersQuery = graphql(`
  query searchAdminUsers($request: SearchUserRequest!) {
    adminUsers(SearchUserRequest: $request) {
      guid
      firstName
      lastName
      email
    }
  }
`);

export type Users = SearchAdminUsersQuery["adminUsers"];

export function useSearchAdminUsersGql(
  request: SearchUserRequest,
  options?: UseQueryOptions<SearchAdminUsersQuery, unknown, SearchAdminUsersQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["searchAdminUsers", request],
    searchAdminUsersQuery,
    [{ request }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );
  return { data: data?.adminUsers, ...rest };
}
