import { UseMutationOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  DeleteCommentMutation,
  DeleteCommentMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const deleteComment = graphql(`
  mutation deleteComment($request: DeleteCommentRequest!) {
    deleteComment(DeleteCommentRequest: $request) {
      id
    }
  }
`);

export function useDeleteComment(
  options?: UseMutationOptions<
    DeleteCommentMutation,
    unknown,
    DeleteCommentMutationVariables,
    unknown
  >,
) {
  const { data, ...rest } = useGraphQlMutation(deleteComment, {
    ...options,
  });

  return { data: data?.deleteComment, ...rest };
}
