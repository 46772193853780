import React from "react";
import { Control, FieldValues, Path } from "react-hook-form";

import { SupplierTagMultiselectExplanation } from "../../../form_components/SupplierTagMultiselect";
import { MatchCountMap } from "../../../onboarding/SharedOnboarding";
import ClearAllSignalButton from "../clear_all_signal_button/ClearAllSignalButton";
import SimpleSupplierSelect from "./simple_supplier_select/SimpleSupplierSelect";

import css from "./SupplierInput.module.scss";

type SupplierInputProps<T extends FieldValues> = {
  competitorFieldName: Path<T>;
  partnerFieldName: Path<T>;
  control: Control<T, unknown>;
  competitorCounts: MatchCountMap | undefined;
  partnerCounts: MatchCountMap | undefined;
};

export default function SupplierInput<T extends FieldValues>({
  competitorFieldName,
  partnerFieldName,
  control,
  competitorCounts,
  partnerCounts,
}: SupplierInputProps<T>) {
  return (
    <div className={css.container}>
      <div className={css.section}>
        <div className={css.header}>
          <p className={css.title}>Competitors</p>
          <SupplierTagMultiselectExplanation supplierType="competitor" />
        </div>
        <SimpleSupplierSelect
          mode="multiple"
          name={competitorFieldName}
          control={control}
          label=""
          counts={competitorCounts}
          tagVariant="error"
          placeholder="Search for competitors"
        />
      </div>
      <ClearAllSignalButton name={competitorFieldName} control={control} />
      <div className={css.section}>
        <div className={css.header}>
          <p className={css.title}>Partners</p>
          <SupplierTagMultiselectExplanation supplierType="partner" />
        </div>
        <SimpleSupplierSelect
          mode="multiple"
          name={partnerFieldName}
          control={control}
          label=""
          counts={partnerCounts}
          tagVariant="warning"
          placeholder="Search for partners"
        />
      </div>
      <ClearAllSignalButton name={partnerFieldName} control={control} />
    </div>
  );
}
