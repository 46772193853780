import React from "react";
import { FieldValues, useController } from "react-hook-form";
import styled from "@emotion/styled";
import { Button } from "antd5";

export default function ClearAllSignalButton({ ...props }: FieldValues) {
  const { field } = useController({
    name: props.name,
    control: props.control,
  });

  const handleClearAll = () => {
    field.onChange([]);
  };

  return <StyledButton onClick={handleClearAll}>Clear all</StyledButton>;
}

const StyledButton = styled(Button)`
  width: fit-content;
`;
