import React from "react";
import styled from "@emotion/styled";

import { OpportunityDetails } from "../../../lib/hooks/api/opportunities/useOpportunity";
import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { sysTextSecondary } from "../../../lib/themes/colors";
import { Flex, Text } from "../../../styles/utility-components";
import { opportunitySavedCardContainer, opportunitySavedCardIcon } from "./shared/styles";
import OpportunitySavedEmptyState from "./OpportunitySavedEmptyState";

type Props = {
  frameworks: OpportunityDetails["frameworks"];
};

export default function OpportunityFrameworks({ frameworks }: Props) {
  const handleAddFramework = () => {
    window.location.href = "/frameworks";
  };

  // Documents empty state
  if (frameworks.length === 0) {
    return <OpportunitySavedEmptyState entity="framework" onClick={handleAddFramework} />;
  }

  const handleViewFramework = (id: string) => () => {
    window.location.href = `/frameworks/${id}`;
  };

  return (
    <Flex column gap={16}>
      {frameworks.map((framework) => (
        <FrameworkCard
          key={framework.id}
          framework={framework}
          onView={handleViewFramework(framework.id)}
        />
      ))}
    </Flex>
  );
}

function FrameworkCard({
  framework,
  onView,
}: {
  framework: OpportunityDetails["frameworks"][0];
  onView: () => void;
}) {
  return (
    <Container onClick={onView}>
      <Flex gap={12}>
        <Icon>
          <UIcon icon="grid01" color={sysTextSecondary} />
        </Icon>
        <Text>{framework.title || "Untitled Framework"}</Text>
      </Flex>
    </Container>
  );
}

const Container = styled(Flex)(({ theme }) => ({
  height: 64,

  ...opportunitySavedCardContainer(theme),
}));

const Icon = styled(Flex)(({ theme }) => ({
  ...opportunitySavedCardIcon(theme),
}));
