import {
  QueryKey,
  UseMutationOptions,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  GetOrQualifyBidQuery,
  QualifyBidMutation,
  QualifyBidMutationVariables,
  QualifyBidRequest,
} from "lib/generated/app-service-gql/graphql";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "lib/hooks/api/utils";
import { useGraphQlMutation, useGraphQlQuery } from "../useGraphQlClient";

const qualifyBid = graphql(`
  mutation QualifyBid($input: QualifyBidRequest!) {
    qualifyBid(QualifyBidRequest: $input) {
      result {
        groupName
        questions {
          answerId
          title
          answer {
            answer
          }
          sources {
            documentLink
            documentId
          }
        }
      }
    }
  }
`);

const getOrQualifyBid = graphql(`
  query getOrQualifyBid($request: QualifyBidRequest!) {
    getOrQualifyBid(QualifyBidRequest: $request) {
      result {
        groupName
        questions {
          answerId
          title
          answer {
            answer
          }
          sources {
            document
            documentLink
            documentId
            annotations
          }
        }
      }
    }
  }
`);

export function useQualifyBid(
  options?: UseMutationOptions<QualifyBidMutation, unknown, QualifyBidMutationVariables, unknown>,
) {
  const queryClient = useQueryClient();
  const { data, ...rest } = useGraphQlMutation(qualifyBid, {
    ...options,
    onSuccess: async (data, vars, ctx) => {
      options?.onSuccess?.(data, vars, ctx);
      await queryClient.invalidateQueries({ queryKey: ["bid_qualify_bid"] });
    },
  });

  return { data: data?.qualifyBid, ...rest };
}

export function useGetOrQualifyBid(
  params: QualifyBidRequest,
  options?: UseQueryOptions<GetOrQualifyBidQuery, unknown, GetOrQualifyBidQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["bid_qualify_bid", params.procurementStageId, params.bidId],
    getOrQualifyBid,
    [{ request: params }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return {
    data: data?.getOrQualifyBid?.result,
    ...rest,
  };
}
