import React from "react";
import styled from "@emotion/styled";

import { usePqlActioned } from "../../lib/hooks/api/users/usePqlActioned";
import { useDialogManager } from "../../lib/providers/DialogManager";
import PaywallCTAModal from "../account_management/PaywallCTAModal";
import PaidContentBanner from "./../ui/banner/PaidContentBanner";

export function BasicContentBanner() {
  const dialogManager = useDialogManager();
  const { mutate } = usePqlActioned(undefined, {});

  function handleClick() {
    mutate({
      actionType: "Compare all plans",
      dataType: "AWARDS",
      pageUrl: window.location.href,
    });

    void dialogManager.openDialog(PaywallCTAModal, {
      showBookADemoForm: false,
      featureType: "AWARDS",
    });
  }

  return (
    <PaidContentBanner
      paidPlanType="basic"
      description={
        <Button onClick={handleClick}>
          Basic plan feature: <ExploreText>Explore plans</ExploreText>
        </Button>
      }
    />
  );
}

const ExploreText = styled.span({ fontWeight: 700 });

const Button = styled.div(({ theme }) => ({
  color: theme.colors.sysTextDefault,
  textDecoration: "none",
  "&:hover": {
    cursor: "pointer",
    color: "inherit",
    [`${ExploreText}`]: {
      textDecoration: "underline",
    },
  },
}));
