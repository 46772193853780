import React from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";

import CpvCodeSelector from "../../../form_components/CpvCodeSelector";
import ClearAllSignalButton from "../clear_all_signal_button/ClearAllSignalButton";

import css from "./CPVCodeInput.module.scss";

type CPVCodeInputProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T, unknown>;
};

export default function CPVCodeInput<T extends FieldValues>({
  name,
  control,
}: CPVCodeInputProps<T>) {
  const { field } = useController({
    name,
    control,
  });

  return (
    <div className={css.container}>
      <CpvCodeSelector
        selectedCodes={field.value}
        onChange={field.onChange}
        placeholder="Search for CPV code or description"
        inputId="feedSettingsCpvCodesSelector"
        disableTopLevelCodeCheck
        disableClear
      />
      <ClearAllSignalButton name={name} control={control} />
    </div>
  );
}
