import React from "react";
import { FieldValues, useController, UseControllerProps } from "react-hook-form";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { Button } from "antd5";

import { useKeywordsRecommendation } from "../../../../../lib/hooks/api/keywords/useKeywordsRecommendation";
import { Flex } from "../../../../../styles/utility-components";
import Tag from "../../../../ui/tag/Tag";

import css from "./SuggestedKeywordsInput.module.scss";

type SuggestedKeywordsInputProps<T extends FieldValues> = UseControllerProps<T>;

export default function SuggestedKeywordsInput<T extends FieldValues>(
  props: SuggestedKeywordsInputProps<T>,
) {
  const { field } = useController(props);

  const {
    data: suggestedKeywords,
    isFetching: suggestedKeywordsLoading,
    refetch: refetchSuggestedKeywords,
  } = useKeywordsRecommendation({
    existingKeywords: field.value,
  });

  const handleAddKeyword = (keyword: string) => {
    field.onChange([...field.value, keyword]);
  };

  const handleRefresh = () => {
    void refetchSuggestedKeywords();
  };

  const Content = () => {
    if (suggestedKeywordsLoading) {
      return (
        <div className={css.suggestedKeywordsContainer}>
          {Array.from({ length: 3 }).map((_, index) => {
            return <Tag key={index} isLoading />;
          })}
        </div>
      );
    }

    if (!suggestedKeywords) {
      return (
        <div className={css.suggestedKeywordsContainer}>
          <p className={css.subtitle}>No suggested keywords could be found.</p>
        </div>
      );
    }

    return (
      <div className={css.suggestedKeywordsContainer}>
        {suggestedKeywords?.keywords.slice(0, 3).map((keyword) => {
          return (
            <Tag
              key={keyword}
              label={keyword}
              icon={<PlusOutlined className={css.icon} />}
              onClick={() => handleAddKeyword(keyword)}
              isContainerClickable
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className={css.container}>
      <div className={css.header}>
        <div className={css.titleHeader}>
          <p className={css.title}>Suggested keywords</p>
          <p className={css.subtitle}>
            Add these keywords to your selection by pressing &apos;+&apos;.
          </p>
        </div>
      </div>
      <Flex justifyContent="space-between" alignItems="center">
        <Content />
        <Button
          type="text"
          icon={<SyncOutlined className={css.refreshIcon} spin={suggestedKeywordsLoading} />}
          iconPosition="start"
          onClick={handleRefresh}
          disabled={suggestedKeywordsLoading}
        >
          Refresh
        </Button>
      </Flex>
    </div>
  );
}
