import React, { useEffect, useState } from "react";

import { FileUpload } from "./FileUpload";
import { ProcessFile } from "./ProcessFile";

import css from "./FileUploadProgress.module.scss";

type Props = {
  procurementStageId: string;
  files: File[];
  onComplete: () => void;
};

type FileUploadStatus = {
  file: File;
  isError: boolean;
  isUploaded: boolean;
  isProcessed: boolean;
  documentId?: string;
};
export function FileUploadProgress({ procurementStageId, files, onComplete }: Props) {
  const [fileStatus, setFileStatus] = useState<FileUploadStatus[]>(
    files.map((file) => ({ file, isError: false, isUploaded: false, isProcessed: false })),
  );

  useEffect(() => {
    if (fileStatus.every((status) => status.isProcessed)) {
      onComplete();
    }
  }, [fileStatus, onComplete]);

  return (
    <div>
      <h2>Uploading Files</h2>
      <div className={css.filesContainer}>
        {fileStatus.map((status) =>
          status.isUploaded && status.documentId ? (
            <ProcessFile
              key={status.file.name}
              file={status.file}
              procurementStageId={procurementStageId}
              documentId={status.documentId}
              onComplete={(_, error) => {
                setFileStatus((prev) =>
                  prev.map((fileStatus) =>
                    fileStatus.file.name === status.file.name
                      ? { ...fileStatus, isProcessed: !error, isError: error }
                      : fileStatus,
                  ),
                );
              }}
            />
          ) : (
            <FileUpload
              key={status.file.name}
              file={status.file}
              procurementStageId={procurementStageId}
              onComplete={(_, isError, documentId) => {
                setFileStatus((prev) =>
                  prev.map((fileStatus) =>
                    fileStatus.file.name === status.file.name
                      ? {
                          ...fileStatus,
                          isUploaded: !isError,
                          isError,
                          documentId,
                        }
                      : fileStatus,
                  ),
                );
              }}
            />
          ),
        )}
      </div>
    </div>
  );
}
