import React from "react";
import { App, Button, Modal } from "antd5";

import { useAdminCreateBuyerList } from "lib/hooks/api/buyer_lists/admin";
import {
  BuyerContextSource,
  useCreateBuyerList,
} from "lib/hooks/api/buyer_lists/useCreateBuyerList";
import { BuyerListFormValues } from "./utils/types";
import { CreateBuyerListForm } from "./CreateBuyerListForm";

import css from "./CreateBuyerListModal.module.scss";

export type UserModalProps = {
  isOpen: boolean;
  onClose: () => void;
  buyerGuids: string[];
  contextSource: BuyerContextSource;
};

export function UserCreateBuyerListModal(props: UserModalProps) {
  const { message } = App.useApp();
  const { isLoading, mutate } = useCreateBuyerList({
    options: {
      onSuccess: () => {
        void message.success("Buyer list created successfully!");
        props.onClose();
      },
      onError() {
        void message.error("Failed to create buyer list");
      },
    },
    contextSource: props.contextSource,
  });

  return <CreateBuyerListModal {...props} onSubmit={mutate} isLoading={isLoading} />;
}

type AdminProps = {
  isOpen: boolean;
  onClose: () => void;
  userGuid: string;
};

export function AdminCreateBuyerListModal(props: AdminProps) {
  const { message } = App.useApp();
  const { isLoading, mutate } = useAdminCreateBuyerList({
    options: {
      onSuccess: () => {
        void message.success("Buyer list created successfully!");
        props.onClose();
      },
      onError() {
        void message.error("Failed to create buyer list");
      },
    },
  });

  return (
    <CreateBuyerListModal
      {...props}
      buyerGuids={[]}
      onSubmit={(values) => mutate({ ...values, userGuid: props.userGuid })}
      isLoading={isLoading}
    />
  );
}

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  buyerGuids: string[];
  onSubmit: (values: BuyerListFormValues) => void;
  isLoading: boolean;
};

function CreateBuyerListModal({ isOpen, onClose, buyerGuids, onSubmit, isLoading }: ModalProps) {
  return (
    <Modal title="Create new buyer list" open={isOpen} onCancel={onClose} footer={null}>
      <CreateBuyerListForm
        onSubmit={onSubmit}
        entityIds={buyerGuids}
        footer={
          <div className={css.footer}>
            <Button onClick={onClose} disabled={isLoading}>
              Cancel
            </Button>
            <Button htmlType="submit" loading={isLoading} type="primary">
              {isLoading ? "Creating..." : "Create list"}
            </Button>
          </div>
        }
      />
    </Modal>
  );
}
