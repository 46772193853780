import React, { useEffect, useMemo, useState } from "react";
import { FieldValues, useController } from "react-hook-form";
import { CloseOutlined } from "@ant-design/icons";
import { CustomTagProps } from "rc-select/lib/BaseSelect";

import { useDebouncedValue } from "../../../../../lib/debounce";
import { useSearchOrganisations } from "../../../../../lib/hooks/api/organisations/useSearchOrganisations";
import {
  OrgSortBy,
  OrgSortOrder,
  SearchOrgPrimaryRole,
} from "../../../../../lib/types/graphQLEnums";
import InfiniteSuppliersChecklist from "../../../../form_components/dropdown_list_selects/new_supplier_selects/InfiniteSuppliersChecklist";
import { SupplierOption } from "../../../../form_components/dropdown_list_selects/new_supplier_selects/types";
import { Select, SelectProps } from "../../../../form_components/Inputs";
import { MatchCountMap } from "../../../../onboarding/SharedOnboarding";
import SkeletonText from "../../../../ui/skeleton/SkeletonText";
import Tag, { TagVariant } from "../../../../ui/tag/Tag";

import css from "./SimpleSupplierSelect.module.scss";

type SimpleSupplierSelectProps<T extends FieldValues> = Omit<SelectProps<T>, "options"> & {
  tagVariant?: TagVariant;
  counts?: MatchCountMap;
};

export default function SimpleSupplierSelect<T extends FieldValues>({
  tagVariant,
  counts,
  ...props
}: SimpleSupplierSelectProps<T>) {
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedText] = useDebouncedValue(searchText, 300);
  const [selectedSuppliers, setSelectedSuppliers] = useState<SupplierOption[]>([]);

  const { field } = useController(props);

  const {
    data: initialSuppliers,
    isInitialLoading: initialSuppliersLoading,
    isError: initialSuppliersError,
  } = useSearchOrganisations(
    {
      textSearch: "",
      ids: field.value,
      sortBy: OrgSortBy.Relevance,
      sortOrder: OrgSortOrder.Desc,
      primaryRole: SearchOrgPrimaryRole.Supplier,
      limit: field.value.length,
      page: 1,
    },
    true,
    true,
    {
      enabled: !field.value.every((id: string) =>
        selectedSuppliers.find((supplier) => supplier.value === id),
      ),
      keepPreviousData: true,
    },
  );

  const initialSuppliersOptions = useMemo(
    () =>
      initialSuppliers?.searchOrganisations.orgs.map((org) => ({
        label: org.name,
        value: org.id,
      })) ?? [],
    [initialSuppliers],
  );

  useEffect(() => {
    if (initialSuppliersOptions.length > 0) {
      setSelectedSuppliers((prevSuppliers) => {
        const newSuppliers = initialSuppliersOptions.filter(
          (supplier) => !prevSuppliers.some((prev) => prev.value === supplier.value),
        );
        return [...prevSuppliers, ...newSuppliers];
      });
    }
  }, [initialSuppliersOptions]);

  const handleDropdownChange = (suppliers: SupplierOption[]) => {
    setSelectedSuppliers(suppliers);
    field.onChange(suppliers.map((supplier) => supplier.value));
  };

  const getDropdownStyle = debouncedText === "" ? { display: "none" } : {};

  const TagRender = (tagProps: CustomTagProps) => {
    const supplier = selectedSuppliers.find((s) => s.value === tagProps.value);
    const label = counts?.[tagProps.value]
      ? `${supplier?.label} (${counts[tagProps.value]})`
      : supplier?.label || tagProps.value;

    const handleClick = () => {
      const updatedSuppliers = selectedSuppliers.filter((s) => s.value !== tagProps.value);
      setSelectedSuppliers(updatedSuppliers);
      field.onChange(updatedSuppliers.map((s) => s.value));
    };

    return (
      <Tag
        label={label}
        icon={<CloseOutlined />}
        variant={tagVariant}
        onClick={handleClick}
        isLoading={initialSuppliersLoading}
      />
    );
  };

  const dropdownRender = () => {
    return (
      <InfiniteSuppliersChecklist
        signalSuppliers={[]}
        selectedSuppliers={selectedSuppliers}
        excludeSignals
        textSearch={debouncedText}
        onChange={handleDropdownChange}
      />
    );
  };

  const options = useMemo(() => {
    const allOptions = [...initialSuppliersOptions, ...selectedSuppliers];
    const uniqueOptions = Array.from(
      new Map(allOptions.map((item) => [item.value, item])).values(),
    );
    return uniqueOptions;
  }, [initialSuppliersOptions, selectedSuppliers]);

  if (initialSuppliersLoading) {
    return <SkeletonText width="100%" />;
  }

  if (initialSuppliersError) {
    return <div>Error loading suppliers</div>;
  }

  return (
    <Select
      {...props}
      className={css.select}
      selectAll={!!debouncedText}
      options={options}
      value={field.value}
      dropdownRender={dropdownRender}
      onSearch={setSearchText}
      tagRender={TagRender}
      dropdownStyle={getDropdownStyle}
    />
  );
}
