import { UseMutationOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  UpdateCommentMutation,
  UpdateCommentMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const updateComment = graphql(`
  mutation updateComment($request: UpdateCommentRequest!) {
    updateComment(UpdateCommentRequest: $request) {
      id
      content
      companyId
      objectId
      createdAt
      updatedAt
    }
  }
`);

export function useUpdateComment(
  options?: UseMutationOptions<
    UpdateCommentMutation,
    unknown,
    UpdateCommentMutationVariables,
    unknown
  >,
) {
  const { data, ...rest } = useGraphQlMutation(updateComment, {
    ...options,
  });

  return { data: data?.updateComment, ...rest };
}
