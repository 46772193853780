import { MatchCountMap, SignalFilterCategoryData } from "components/onboarding/SharedOnboarding";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { sentenceCaseWithAcronyms } from "lib/utils";
import { PreviewGuidMatchMap, Signal, SignalCategory } from "../../lib/StotlesApi";

const extractCountsByProperty = (
  guidMatchMap: PreviewGuidMatchMap,
  signalMap: Map<string, Signal>,
  signalCategory: SignalCategory,
): MatchCountMap => {
  const signalCountMap: MatchCountMap = {};

  for (const signal of signalMap.values()) {
    if (signal.category !== signalCategory) continue;
    signalCountMap[signal.name] = 0;
  }

  for (const recordMatch of Object.values(guidMatchMap)) {
    for (const signalId of recordMatch.signal_ids) {
      const signal = signalMap.get(signalId);
      if (!signal || signal.category !== signalCategory) {
        continue;
      }
      signalCountMap[signal.name]++;
    }
  }

  return signalCountMap;
};

export type SignalFiltersData = Partial<Record<SignalCategory, SignalFilterCategoryData>>;

export type ResultCountsType = Partial<Record<SignalCategory, MatchCountMap>>;

const SIGNAL_CATEGORY_DEFINITIONS: {
  category: SignalCategory;
  title: string;
  colour: SignalFilterCategoryData["colour"];
}[] = [
  {
    category: SignalCategory.KEYWORD,
    title: "Keywords",
    colour: "blue",
  },
  {
    category: SignalCategory.COMPETITOR,
    title: "Competitors",
    colour: "red",
  },
  {
    category: SignalCategory.PARTNER,
    title: "Partners",
    colour: "yellow",
  },
  {
    category: SignalCategory.CPV_CODE,
    title: "CPV codes",
    colour: "blue",
  },
];

export function getSignalFiltersData(
  signals: Signal[],
  guidMatchMap: PreviewGuidMatchMap,
): SignalFiltersData {
  const signalFiltersData: SignalFiltersData = {};

  const signalMap = new Map(signals.map((s) => [s.id, s]));

  for (const { category, title, colour } of SIGNAL_CATEGORY_DEFINITIONS) {
    const categorySignals = signals.filter((s) => s.category === category);
    if (categorySignals.length === 0) {
      continue;
    }

    const counts = extractCountsByProperty(guidMatchMap, signalMap, category);
    signalFiltersData[category] = {
      title,
      colour,
      counts,
      signals: categorySignals,
    };
  }
  return signalFiltersData;
}

export function filterDuplicatesKeywords(values: string[]): string[] {
  const textSearchEnabled = FeatureToggles.isEnabled(Feature.USE_TEXT_SEARCH);

  // We don't allow empty strings as search terms. Could handle more cases here
  const filtered = values.filter((v) => v.trim() !== "");
  // format to sentence case but leave acronyms
  let formatted = filtered;
  if (textSearchEnabled) {
    formatted = formatted.map((v) => v.trim());
  }
  formatted = filtered.map((v) => sentenceCaseWithAcronyms(v));
  const deDuped: string[] = [];
  // We need to deduplicate so that users can't add both IT and It for example
  for (const keyword of formatted) {
    if (!deDuped.some((d) => d.toLowerCase() === keyword.toLowerCase())) deDuped.push(keyword);
  }

  return deDuped;
}
