import React, { useCallback } from "react";
import { hot } from "react-hot-loader/root";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { ConfigProvider, Empty, Spin, Tabs } from "antd5";
import { getDocument } from "pdfjs-dist/legacy/build/pdf";
import { Redirect, Route, Router } from "wouter";

import { withAppLayout } from "components/app_layout/AppLayout";
import DocumentHeader from "components/document_details/DocumentHeader";
import { DocumentViewerFilters } from "components/document_details/FilterForm";
import { PdfViewerContainer } from "components/document_details/PdfViewerContainer";
import {
  createTabItems,
  isMimeTypeSupported,
  TAB_KEYS,
  TabsConfig,
} from "components/document_details/utils";
import { FileViewerContextProvider } from "lib/core_components/FileViewer";
import { PdfViewer } from "lib/documents/DisplayPdf";
import { DOCUMENT_DETAILS_PAGE, VERTICAL_NAV } from "lib/featureFlags";
import { useDocument } from "lib/hooks/api/documents/useDocument";
import useAsync from "lib/hooks/useAsync";
import { useSubscription } from "lib/providers/Subscription";
import { TrackingProvider } from "lib/tracking";

import tabCss from "./../../components/document_details/utils.module.scss";
import css from "./DocumentDetailsPage.module.scss";

type Props = {
  document_id: string;
};

const DEFAULT_FILTERS: DocumentViewerFilters = {
  keywords: [],
};

function DocumentDetailsPageWrapper(props: Props) {
  const subscription = useSubscription();
  const hasDocumentsAccess = subscription.hasDataTypes("DOCUMENTS");

  if (!hasDocumentsAccess) {
    return <Redirect to="/documents" replace={true} />;
  }

  return (
    <Router base={`/documents/${props.document_id}`}>
      <Route>
        <DocumentDetailsPage documentId={props.document_id} />
      </Route>
    </Router>
  );
}

function DocumentDetailsPage({ documentId }: { documentId: string }) {
  const isDocumentDetailsPageEnabled = useVariableValue(DOCUMENT_DETAILS_PAGE, true);
  const isVerticalNavEnabled = useVariableValue(VERTICAL_NAV, false);

  const [activeTab, setActiveTab] = React.useState(TAB_KEYS.search);

  const { data, isLoading, isError } = useDocument(documentId);

  const documentTypeSupported = isMimeTypeSupported(data?.originalMimeType ?? "");

  const isSearchDisabled = !isLoading && (!data?.pdfDocumentUrl || !documentTypeSupported);

  const tabItems = createTabItems(documentId, isLoading, isSearchDisabled, DEFAULT_FILTERS, data);

  React.useEffect(() => {
    if (isSearchDisabled) {
      setActiveTab(TAB_KEYS.info);
    }
  }, [isSearchDisabled]);

  if (isLoading) {
    return <Spin className={css.page} />;
  }

  if (isError) {
    return <div className={css.page}>Sorry, something has gone wrong.</div>;
  }

  return (
    <div className={css.pageWrapper}>
      {isDocumentDetailsPageEnabled ? (
        <TrackingProvider
          data={{ "Page source": "Document detail page", "Document type": data?.category ?? "-" }}
        >
          <DocumentHeader
            documentId={documentId}
            documentOrgs={data?.buyers ?? []}
            title={data?.title ?? ""}
            downloadUrl={data?.downloadUrl ?? ""}
            disableTitleLink
          />
          <div
            className={css.container}
            style={{ height: isVerticalNavEnabled ? "100%" : undefined }}
          >
            <FileViewerContextProvider>
              <PdfViewerContainer
                documentUrl={data?.pdfDocumentUrl ?? ""}
                downloadUrl={data?.downloadUrl ?? ""}
                isLoading={isLoading}
                hasKeywordsFilter={!!DEFAULT_FILTERS.keywords.length} // no filters applied on initial load
                previewSupported={documentTypeSupported}
              />
              <ConfigProvider theme={TabsConfig}>
                <Tabs
                  activeKey={activeTab}
                  size="large"
                  items={tabItems}
                  onChange={(key: string) => setActiveTab(key)}
                  className={tabCss.toolbar}
                />
              </ConfigProvider>
            </FileViewerContextProvider>
          </div>
        </TrackingProvider>
      ) : (
        <div className={css.tempViewer}>
          <TempDocumentViewer documentUrl={data?.pdfDocumentUrl ?? ""} />
        </div>
      )}
    </div>
  );
}

function TempDocumentViewer({ documentUrl }: { documentUrl: string }) {
  const fetchDocument = useCallback(() => {
    if (!documentUrl) {
      return Promise.resolve(undefined);
    }
    return getDocument(documentUrl).promise;
  }, [documentUrl]);

  const pdf = useAsync(fetchDocument);

  if (!documentUrl) {
    <div>Unsupported document</div>;
  }

  if (pdf.status === "loading" || pdf.status === "reloading") {
    return <Spin />;
  }

  if (pdf.status === "error") {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Failed to load document" />;
  }

  if (!pdf.value) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No document selected" />;
  }

  return <PdfViewer pdf={pdf.value} />;
}

export default hot(
  withAppLayout(DocumentDetailsPageWrapper, () => ({
    pageName: "Document details",
  })),
);
