import * as React from "react";
import { Button, message, Typography } from "antd5";
import isEqual from "lodash.isequal";
import { DateTime } from "luxon";

import FormInputs from "components/account_management/feed_settings/Form";
import {
  DiscardQueryModal,
  FeedUpdateProgressModal,
  SaveQueryModal,
} from "components/account_management/feed_settings/modals";
import { ButtonLink } from "components/actions/Links";
import { ResultCountsType } from "components/onboarding/onboardingUtils";
import { FeedPreviewPageSources } from "components/onboarding/SharedOnboarding";
import { SelfServeQuery } from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";
import * as tracking from "lib/tracking";
import { CpvDimension, Status } from "lib/types/models";
import { assertDefined } from "lib/utils";
import { useVariableValue, VERTICAL_NAV } from "../../lib/featureFlags";
import { useDialogManager } from "../../lib/providers/DialogManager";
import { useSubscription } from "../../lib/providers/Subscription";
import { FeedSettingsPreview } from "./feed_settings/FeedSettingsPreview";
import SettingsContentContainer from "./SettingsContentContainer";

import css from "./FeedSettings.module.scss";

type Props = {
  query?: { self_serve_query: SelfServeQuery };
  company_id: number;
  cpvDimensions: CpvDimension[];
  useSupplierName: boolean;
  internationalDataAccess: boolean;
};

export type FormState = {
  countries: string[];
  languages: string[] | undefined;
  keywords: string[];
  keywordsFromDate: Date;
  status: Status[];
  excludeKeywords?: string[];
  keywordsCpvCodes?: string[];
  keywordsCpvCodesIncludeNull: boolean | undefined;

  competitors?: number[];
  competitorOrgIds?: string[];
  competitorNames?: string[];
  competitorsFromDate?: Date;

  partners?: number[];
  partnerOrgIds?: string[];
  partnerNames?: string[];
  partnersFromDate?: Date;

  cpvCodes: string[];
};

export type OnFieldChangeEvent = Partial<FormState>;

function queryToFormState(query: SelfServeQuery): FormState {
  return {
    countries: query.countries,
    languages: query.languages,
    keywords: query.keywords.keywords || [],
    keywordsFromDate: query.keywords?.from_date,
    excludeKeywords: query.keywords?.exclude_keywords,
    status: query.keywords.status || [],
    keywordsCpvCodes: query.keywords.cpv_codes,
    keywordsCpvCodesIncludeNull: query.keywords.cpv_codes_include_null,

    competitors: query.competitors?.supplier_ids || [],
    competitorNames: query.competitors?.supplier_names || [],
    competitorsFromDate: query.competitors?.from_date,
    competitorOrgIds: query.competitors?.org_ids || [],

    partners: query.partners?.supplier_ids || [],
    partnerNames: query.partners?.supplier_names || [],
    partnersFromDate: query.partners?.from_date,
    partnerOrgIds: query.partners?.org_ids || [],
    cpvCodes: query.cpv_codes?.codes || [],
  };
}

function formStateToQuery(formState: FormState): SelfServeQuery {
  return {
    countries: formState.countries,
    languages: formState.languages,
    keywords: {
      keywords: formState.keywords,
      from_date: formState.keywordsFromDate,
      status: formState.status,
      exclude_keywords: formState.excludeKeywords,
      cpv_codes: formState.keywordsCpvCodes,
      cpv_codes_include_null: formState.keywordsCpvCodesIncludeNull,
    },
    competitors:
      (formState.competitors || formState.competitorNames || formState.competitorOrgIds) &&
      formState.competitorsFromDate
        ? {
            supplier_ids: formState.competitors || [],
            supplier_names: formState.competitorNames || [],
            org_ids: formState.competitorOrgIds || [],
            from_date: formState.competitorsFromDate,
          }
        : undefined,
    partners:
      (formState.partners || formState.partnerNames || formState.partnerOrgIds) &&
      formState.partnersFromDate
        ? {
            supplier_ids: formState.partners || [],
            supplier_names: formState.partnerNames || [],
            org_ids: formState.partnerOrgIds || [],
            from_date: formState.partnersFromDate,
          }
        : undefined,
    cpv_codes: {
      codes:
        formState.cpvCodes?.map((c) => {
          if (c.length === 8) {
            return c;
          } else {
            let str = c;
            // right pad the rest of the zeros. The Implementation of the selector is
            // such that the top level codes are only 2 digits long (despite referring
            // to legitimate codes in themselves
            // eg. Agricultural Machinery has the value 16 in the front end, but in the
            // db a code exists for this as 16000000
            while (str.length !== 8) str += 0;

            return str;
          }
        }) || [],
    },
  };
}

const defaultKeywordsFrom = DateTime.now().minus({ months: 1 }).startOf("day").toJSDate();

const defaultSuppliersFrom = DateTime.now().minus({ months: 6 }).startOf("day").toJSDate();

const defaultBuyersFrom = DateTime.now().minus({ months: 3 }).startOf("day").toJSDate();

const defaultDates = {
  keywords: defaultKeywordsFrom,
  suppliers: defaultSuppliersFrom,
  buyers: defaultBuyersFrom,
};

const DEFAULT_SELF_SERVE_QUERY: SelfServeQuery = {
  countries: [],
  languages: undefined,
  keywords: {
    keywords: [],
    from_date: defaultKeywordsFrom,
    status: ["PRE_TENDER", "OPEN"],
  },
};

/**
 * @param {string} a keyword/partner name/competitor name
 * @param {string} a keyword/partner name/competitor name
 */
const sortFormStateField = (value1: string, value2: string) =>
  value1.trim().localeCompare(value2.trim());

function FeedSettings({
  query,
  company_id,
  useSupplierName,
  internationalDataAccess,
}: Props): JSX.Element {
  const subscription = useSubscription();

  const dialogManager = useDialogManager();

  const isVerticalNavEnabled = useVariableValue(VERTICAL_NAV, false);

  const [initialQueryState, setInitialQueryState] = React.useState<SelfServeQuery>(
    query?.self_serve_query || DEFAULT_SELF_SERVE_QUERY,
  );
  const api = useStotlesApi();

  const [formState, setFormState] = React.useState<FormState>(() => {
    const initialFormState = queryToFormState(initialQueryState);
    // on first load, sort alphabetically for ease of use.
    //
    // Doing this on first load as opposed to as-you-type leads to better UX from words not jumping
    // around
    return {
      ...initialFormState,
      keywords: initialFormState.keywords.sort(sortFormStateField),
      competitorNames: initialFormState.competitorNames?.sort(sortFormStateField),
      partnerNames: initialFormState.partnerNames?.sort(sortFormStateField),
    };
  });

  const [resultCounts, setResultCounts] = React.useState<ResultCountsType>();
  const [feedPreviewLoading, setFeedPreviewLoading] = React.useState<boolean>(false);

  const hasUnsavedChanges = React.useMemo(() => {
    const initialFormState = queryToFormState(initialQueryState);
    return !isEqual(initialFormState, formState);
  }, [formState, initialQueryState]);

  const [isFormValid, setIsFormValid] = React.useState<boolean>();

  const submitDisabled = React.useMemo(() => {
    // if the form hasn't changed, the buttons should always be disabled
    if (!hasUnsavedChanges) {
      return true;
    }
    return !isFormValid;
  }, [hasUnsavedChanges, isFormValid]);

  React.useEffect(() => {
    if (hasUnsavedChanges) {
      const handler = (e: BeforeUnloadEvent) => {
        // Try to handle most browsers based on
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
        e.preventDefault();
        // Most browsers won't show this message either way
        // but the modal should show up.
        const message = "You have unsaved changes, are you sure you want to leave this page?";
        e.returnValue = message;
        return message;
      };
      window.addEventListener("beforeunload", handler);
      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }
  }, [hasUnsavedChanges]);

  const handleFieldChange = React.useCallback(
    (change: OnFieldChangeEvent) => {
      setFormState((prevFormState) => {
        return { ...prevFormState, ...change };
      });
    },
    [setFormState],
  );

  const handleResetForm = React.useCallback(() => {
    const newFormState = queryToFormState(initialQueryState);
    setFormState(newFormState);
    void message.success("Successfully discarded your feed settings changes.");
  }, [setFormState, initialQueryState]);

  const handleSave = React.useCallback(async () => {
    assertDefined(query);

    const [, dialogResult] = dialogManager.openDialog(SaveQueryModal, {});
    const shouldSave = await dialogResult;
    if (!shouldSave) {
      return;
    }

    try {
      const [progressModalHandle] = dialogManager.openDialog(FeedUpdateProgressModal, {
        jobId: undefined,
        isPartialUpdate: true, // all updates process only 6 months first and the rest in the background
      });
      const newQuery = formStateToQuery(formState);
      const response = await api.saveSelfServeQuery(company_id, {
        query: newQuery,
      });
      dialogManager.updateProps(progressModalHandle, { jobId: response.job_id });

      setInitialQueryState(newQuery);
      // formStateToQuery resets some fields if not both date and input are set so we need
      // to make sure initialstate and state match after save
      setFormState(queryToFormState(newQuery));

      tracking.logEvent(tracking.EventNames.recordQueryUpdated, {});
    } catch (e) {
      void message.error(
        "Unable to update your profile; please contact us if the problem persists",
      );
      throw e;
    }
  }, [api, company_id, formState, query, dialogManager]);

  const previewFeedCriteria = React.useMemo(() => {
    const canSetBuyers = subscription.hasDataTypes("BUYERS");
    const canSetSuppliers = subscription.hasDataTypes("SUPPLIERS");
    const hasAwards = subscription.hasDataTypes("AWARDS");

    const criteria = formStateToQuery(formState);
    /** Remove anything that the user does not have access too.
     *  When saving the query all the previous data should still be saved,
     *  as it should also appear on their feed as a 'hidden' item */
    if (!hasAwards) {
      criteria.keywords.status = criteria.keywords.status.filter((x) => x !== "AWARDED");
    }
    if (!canSetSuppliers) {
      delete criteria["competitors"];
      delete criteria["partners"];
    }
    if (!canSetBuyers) {
      delete criteria["buyers"];
    }
    return criteria;
  }, [formState, subscription]);

  if (!query) {
    return (
      <SettingsContentContainer title="Your feed is not set up for you yet">
        <ButtonLink type="primary" to={"/onboarding"}>
          Get started
        </ButtonLink>
      </SettingsContentContainer>
    );
  }

  return (
    <div className={css.feedSettings}>
      <div className={css.feedSettingsContainer}>
        <div className={css.previewInputs}>
          <div className={css.previewInputsInner} data-vertical-nav={isVerticalNavEnabled}>
            <FormInputs
              handleFieldChange={handleFieldChange}
              formState={formState}
              defaultDates={defaultDates}
              resultCounts={resultCounts}
              feedPreviewLoading={feedPreviewLoading}
              onValidate={setIsFormValid}
              useSupplierName={useSupplierName}
              internationalDataAccess={internationalDataAccess}
            />
          </div>
        </div>
        <div className={css.previewOutputs}>
          <FeedSettingsPreview
            feedCriteria={previewFeedCriteria}
            pageSource={FeedPreviewPageSources.FEED_SETTINGS}
            onResultCountsChange={setResultCounts}
            setFeedPreviewLoading={setFeedPreviewLoading}
            feedPreviewLoading={feedPreviewLoading}
          />
        </div>
      </div>
      <div className={css.submissionWrapper}>
        <Typography.Text className={css.submissionLabel}>
          Would you like to save your changes?
        </Typography.Text>
        <div className={css.actionButtonWrapper}>
          <Button
            disabled={!hasUnsavedChanges}
            onClick={() => {
              void dialogManager.openDialog(DiscardQueryModal, { onReset: handleResetForm });
            }}
          >
            Discard
          </Button>
          <Button disabled={submitDisabled} onClick={handleSave} type="primary">
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

// The Route component doesn't accept the Form.create type so we have to wrap it before we export
export default FeedSettings;
