import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ClassNames } from "@emotion/react";
import styled from "@emotion/styled";
import { App } from "antd5";

import * as tracking from "lib/tracking";
import CentredSpinner from "../../../lib/core_components/CentredSpinner";
import { OpportunityUpdateInput } from "../../../lib/generated/app-service-gql/graphql";
import { OpportunityDetails } from "../../../lib/hooks/api/opportunities/useOpportunity";
import { useUpdateOpportunity } from "../../../lib/hooks/api/opportunities/useUpdateOpportunity";
import { Flex, Text } from "../../../styles/utility-components";
import { Currency, FormattedDate } from "../../app_layout/Typography";
import { getFormDirtyValues } from "../../form_components/formUtils";
import OpportunityAssignedSelect from "../opportunity_assigned_select/OpportunityAssignedSelect";
import { OpportunityStageSelect } from "../OpportunityStageSelect";
import { filterOpportunityDefaultValues } from "../utils";

type OpportunityDetailSidebarProps = {
  opportunity: OpportunityDetails;
};

export default function OpportunityDetailSidebar({ opportunity }: OpportunityDetailSidebarProps) {
  const { message } = App.useApp();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields },
  } = useForm<OpportunityUpdateInput>();

  useEffect(() => {
    if (opportunity) {
      const filteredDefaultValues = filterOpportunityDefaultValues(opportunity);
      reset(filteredDefaultValues);
    }
  }, [opportunity, reset]);

  const { mutate: updateOpportunity, isLoading: updateOpportunityLoading } = useUpdateOpportunity({
    onSuccess: () => {
      tracking.logEvent(tracking.EventNames.opportunityUpdated, {});
      message.success("Opportunity updated successfully");
    },
  });

  const onSubmit: SubmitHandler<OpportunityUpdateInput> = (values: OpportunityUpdateInput) => {
    const updatedValues = getFormDirtyValues(dirtyFields, values);
    const filteredPayload = { ...updatedValues, id: values.id };
    updateOpportunity({ opportunity: filteredPayload });
  };

  // Force form to update on change
  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  });

  if (updateOpportunityLoading) {
    return (
      <Sidebar>
        <CentredSpinner />
      </Sidebar>
    );
  }

  return (
    <ClassNames>
      {({ css }) => (
        <Sidebar>
          <SidebarContentItem>
            <Text h2>Details</Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex gap={16}>
                <Flex flexDirection="column" gap={16}>
                  <Flex alignItems="center" height={32}>
                    <SidebarContentItemKey>Stage</SidebarContentItemKey>
                  </Flex>
                  <Flex alignItems="center" height={32}>
                    <SidebarContentItemKey>Assigned</SidebarContentItemKey>
                  </Flex>
                  <Flex alignItems="center" height={32}>
                    <SidebarContentItemKey>Value</SidebarContentItemKey>
                  </Flex>
                  <Flex alignItems="center" height={32}>
                    <SidebarContentItemKey>Close</SidebarContentItemKey>
                  </Flex>
                  <Flex alignItems="center" height={32}>
                    <SidebarContentItemKey>Est. Close</SidebarContentItemKey>
                  </Flex>
                  <Flex alignItems="center" height={32}>
                    <SidebarContentItemKey>Location</SidebarContentItemKey>
                  </Flex>
                </Flex>
                {/* TODO: improve styling */}
                <Flex flexDirection="column" gap={16}>
                  <Flex alignItems="center" height={32}>
                    <OpportunityStageSelect
                      name="stageId"
                      control={control}
                      label=""
                      variant="borderless"
                      className={css({
                        minWidth: 250,
                      })}
                    />
                  </Flex>
                  <Flex alignItems="center" height={32}>
                    <OpportunityAssignedSelect
                      name="assignedToId"
                      label=""
                      control={control}
                      variant="borderless"
                      className={css({
                        minWidth: 250,
                      })}
                    />
                  </Flex>
                  <Flex alignItems="center" height={32}>
                    <Currency value={opportunity?.value} />
                  </Flex>
                  <Flex alignItems="center" height={32}>
                    <FormattedDate date={opportunity?.closeDate} />
                  </Flex>
                  <Flex alignItems="center" height={32}>
                    <Text>WIP</Text>
                  </Flex>
                  <Flex alignItems="center" height={32}>
                    <Text>WIP</Text>
                  </Flex>
                </Flex>
              </Flex>
            </form>
          </SidebarContentItem>
          <Divider />
          <SidebarContentItem>
            <Text h2>Actions</Text>
          </SidebarContentItem>
        </Sidebar>
      )}
    </ClassNames>
  );
}

const Sidebar = styled(Flex)({
  flexDirection: "column",

  height: "100vh",
});

const SidebarContentItem = styled(Flex)({
  flexDirection: "column",
  gap: 16,

  padding: "20px 24px",
});

const SidebarContentItemKey = styled(Text)(({ theme }) => ({
  width: 80,

  color: theme.colors.sysTextSecondary,
}));

// const SidebarContentItemValue = styled(Flex)({
//   flexGrow: 1,
//   alignItems: "center",
// });

// const SidebarContentItemRow = styled(Flex)({
//   alignItems: "center",
//   gap: 8,

//   height: 32,
// });

const Divider = styled.div(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.sysBorderPrimary}`,
}));
