import React from "react";
import styled from "@emotion/styled";
import { Flex, Text } from "styles/utility-components";

import { ButtonLink } from "components/actions/Links";
import { generateNoticeSearchUrl } from "components/notices/utils";
import { useLocalStorage } from "lib/hooks/useLocalStorage";
import UIcon from "lib/icons/untitled_ui/UIcon";
import { DEFAULT_VIEW_IDS } from "./Opportunities";
import { Box } from "./util";

function Banner() {
  const [homepageBannerDisplay, setHomepageBannerDisplay] = useLocalStorage(
    "homepageBannerDisplay",
    true,
  );

  if (!homepageBannerDisplay) {
    return null;
  }
  return (
    <BannerContainer>
      <BannerSideLeft>
        <Flex column gap={24}>
          <Flex column gap={4}>
            <Text h1>Build pipeline with expiring contracts</Text>
            <Text>
              Use expiring contracts to pre-empt when a buyer is preparing a renewal, before it goes
              live to tender.
            </Text>
          </Flex>
          <ButtonLink
            type="primary"
            to={generateNoticeSearchUrl({}, DEFAULT_VIEW_IDS.upcomingContractExpiries)}
          >
            Find expiring contracts
          </ButtonLink>
        </Flex>
      </BannerSideLeft>
      <BannerSideRight>
        <VideoContainer
          controls
          controlsList="nodownload"
          preload="metadata"
          src="https://5746318.fs1.hubspotusercontent-na1.net/hubfs/5746318/US%20AI%20Videos/Stotles%20AI%20for%20Bidding%20Launch.mp4"
        />
        <BackgroundContainer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="312"
            height="304" // Purposefully larger than the viewbox to stretch the SVG to match the design
            viewBox="0 0 312 250"
            fill="none"
          >
            <path d="M0 -39H330.885V291.885C148.142 291.885 0 143.743 0 -39Z" fill="#84AAFB" />
          </svg>
        </BackgroundContainer>
        <CloseButtonContainer onClick={() => setHomepageBannerDisplay(false)}>
          <UIcon icon="xClose" size={16} color="inherit" />
        </CloseButtonContainer>
      </BannerSideRight>
    </BannerContainer>
  );
}

const BannerContainer = styled(Box)({
  display: "flex",
  flexGrow: 0,
  flexWrap: "wrap",
  padding: 0,

  "@media screen and (min-width: 1280px)": {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  "@media screen and (max-width: 1279px)": {
    flexDirection: "column-reverse",
    height: "100%",
  },
});

const BannerSide = styled.div(() => ({
  display: "flex",
  flexGrow: 1,
  flexBasis: 0,
  minWidth: "min(260px, 100%)", // Need to force to 1 column to avoid text wrapping
}));

const BannerSideLeft = styled(BannerSide)({
  padding: "32px 24px",

  // Only other option with ButtonLink is to have a .scss file
  "& a": {
    width: "fit-content",
  },
});

const BannerSideRight = styled(BannerSide)(({ theme }) => ({
  backgroundColor: theme.colors.sysPrimarySubtle,
  padding: "42px",
  backgroundRepeat: "no-repeat",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",

  "@media screen and (min-width: 1280px)": {
    clipPath: "inset(0% 0% 0% 0% round 0 8px 8px 0)",
  },
  "@media screen and (max-width: 1279px)": {
    clipPath: "inset(0% 0% 0% 0% round 8px 8px 0 0)",
  },
}));

const VideoContainer = styled.video(() => ({
  zIndex: 1,
  width: "100%",
  borderRadius: "8px",
  overflow: "hidden",
}));

const BackgroundContainer = styled.div(() => ({
  position: "absolute" as const,
  top: "0px",
  right: "0px",
  maxWidth: "80%",
  "& svg": {
    overflow: "visible",
  },
}));

const CloseButtonContainer = styled.button(({ theme }) => ({
  position: "absolute",
  top: "0px",
  right: "0px",
  padding: "8px",
  color: theme.colors.sysTextSecondary,
  backgroundColor: "transparent",
  border: "none",
  lineHeight: 0,
  cursor: "pointer",
  zIndex: 2,

  "&:hover": {
    color: theme.colors.sysTextBlack,
  },
}));

export default Banner;
