import { useState } from "react";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import { useNoticeDocumentUploadUrl } from "./useNoticeDocumentUploadUrl";

type UploadDocument = {
  file: File;
  procurementStageId: string;
  // documentType: string;
};
export function useUploadNoticeDocument(
  options?: UseMutationOptions<{ documentId: string }, AxiosError, UploadDocument, unknown>,
) {
  const [uploadProgress, setUploadProgress] = useState(0);
  const { mutateAsync: getUrl } = useNoticeDocumentUploadUrl();
  const mutation = useMutation<{ documentId: string }, AxiosError, UploadDocument>(
    async ({ file, procurementStageId }) => {
      const {
        uploadNoticeDocument: { signedUrl, documentId },
      } = await getUrl({
        input: { procurementStageId, documentName: file.name, documentType: file.type },
      });

      await axios.put(signedUrl, file, {
        headers: {
          "Content-Type": "application/octet-stream",
          "Access-Control-Allow-Origin": "*",
        },
        onUploadProgress(progressEvent) {
          progressEvent.progress && setUploadProgress(Math.floor(progressEvent.progress * 100));
        },
      });

      return { documentId };
    },
    options,
  );

  return { ...mutation, uploadProgress };
}
