import { Theme } from "@emotion/react";
import styled from "@emotion/styled";

import { pulseOpacity } from "../../../styles/animations";

type SkeletonTextProps = {
  width: string;
  fontSize?: number;
  lineHeight?: number;
  backgroundColor?: keyof Theme["colors"];
};

/**
 * @param width - The width of the skeleton
 * @param fontSize - Set to the font size of the text you are replacing
 * @param lineHeight - Set to the line height of the text you are replacing (d)
 * @param backgroundColor - Colour from {@link Theme.colors}
 */
const SkeletonText = styled.div<SkeletonTextProps>(
  ({ theme, width, fontSize = 14, lineHeight = 1.57, backgroundColor = "grey50" }) => ({
    animation: `${pulseOpacity} 2s infinite`,
    backgroundColor: theme.colors[backgroundColor],
    borderRadius: "9999px", // Not using 100% as we just want rounded corners, not an oval
    height: fontSize,
    margin: `${(fontSize * lineHeight - fontSize) / 2}px 0`,
    width,
  }),
);

export default SkeletonText;
