import * as React from "react";
import classnames from "classnames";
import { v4 as uuidV4 } from "uuid";

import BigCheckButton from "components/form_components/BigCheckButton";

import css from "./SimplifiedCountriesField.module.scss";

import GB_FLAG from "flagpack/flags/1x1/gb.svg";
import IE_FLAG from "flagpack/flags/1x1/ie.svg";

const CountryFlag = (props: { src: string; title: string }) => (
  <img
    src={props.src}
    height="24px"
    title={props.title}
    alt={props.title}
    style={{ borderRadius: "50%" }}
  />
);

const randomizedID = uuidV4();

type countryOption = "United Kingdom" | "Ireland";
const COUNTRY_OPTIONS: { label: countryOption; value: countryOption; icon: React.ReactNode }[] = [
  {
    label: "United Kingdom",
    value: "United Kingdom",
    icon: <CountryFlag src={GB_FLAG} title="UK" />,
  },
  { label: "Ireland", value: "Ireland", icon: <CountryFlag src={IE_FLAG} title="IE" /> },
];

type Props = {
  values?: string[];
  onChange: (values: string[]) => void;
  label?: string;
  className?: string;
};

const SimplifiedCountriesField = React.forwardRef<HTMLDivElement, Props>(
  ({ values = [], onChange, label, className }: Props, ref) => {
    const handleChange = React.useCallback(
      (checked, value) => {
        const countries = checked ? [...values, value] : values.filter((v) => v !== value);
        onChange(countries);
      },
      [onChange, values],
    );

    return (
      <div className={classnames(css.countriesField, className)} ref={ref}>
        {label && (
          <label className={css.label} htmlFor={randomizedID}>
            {label}
          </label>
        )}
        <div className={css.optionsContainer}>
          {COUNTRY_OPTIONS.map((c) => (
            <BigCheckButton
              id={randomizedID}
              key={c.value}
              value={c.value}
              checked={values.includes(c.value)}
              onChange={(checked) => handleChange(checked, c.value)}
              label={c.label}
              icon={c.icon}
            />
          ))}
        </div>
      </div>
    );
  },
);

SimplifiedCountriesField.displayName = "SimplifiedCountriesField";

export default SimplifiedCountriesField;
