import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import {
  DeleteOpportunityMutation,
  DeleteOpportunityMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { graphql } from "../../../generated/app-service-gql";
import { useGraphQlMutation } from "../useGraphQlClient";

const DELETE_OPPORTUNITY_MUTATION = graphql(`
  mutation deleteOpportunity($id: String!) {
    deleteOpportunity(id: $id) {
      id
    }
  }
`);

export function useDeleteOpportunity(
  options: UseMutationOptions<
    DeleteOpportunityMutation,
    unknown,
    DeleteOpportunityMutationVariables,
    unknown
  >,
) {
  const queryClient = useQueryClient();
  const { data, ...rest } = useGraphQlMutation(DELETE_OPPORTUNITY_MUTATION, {
    ...options,
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries(["opportunities"]);
      options?.onSuccess?.(data, variables, context);
    },
  });
  return { data, ...rest };
}
