import * as React from "react";
import { useVariableValue } from "@devcycle/react-client-sdk";

import SupplierTagMultiselect, {
  SupplierTagMultiselectExplanation,
} from "components/form_components/SupplierTagMultiselect";
import { MatchCountMap } from "components/onboarding/SharedOnboarding";
import { NEW_SUPPLIER_FILTER } from "lib/featureFlags";
import { PaywallMessage } from "../Form";
import SupplierSignalSelect from "./SupplierSignalSelect";

import css from "../Form.module.scss";

type Props = {
  active: boolean;
  useSupplierName: boolean;
  countries?: string[];
  supplierIds: number[];
  supplierGuids: string[];
  supplierNames: string[];
  supplierCounts: MatchCountMap | undefined;

  // TODO: Delete when NEW_SUPPLIER_FILTER (Layer Cake) feature flag is removed
  onSupplierIdsChange: (newIds: number[]) => void;
  onSupplierGuidsChange: (newIds: string[]) => void;
  onSupplierNamesChange: (newNames: string[]) => void;
  supplierType: "partner" | "competitor";
};

export default function PartnersCompetitors({
  active,
  useSupplierName,
  countries,
  supplierCounts,
  supplierIds,
  supplierGuids,
  supplierNames,
  onSupplierIdsChange,
  onSupplierGuidsChange,
  onSupplierNamesChange,
  supplierType,
}: Props): JSX.Element {
  const enableNewSupplierFilter = useVariableValue(NEW_SUPPLIER_FILTER, false);
  if (active) {
    return (
      <>
        <label className={css.settingsLabel} htmlFor={`{supplierType}-multiselect`}>
          {`Which ${supplierType}s would you like to track in the public sector?`}{" "}
          <SupplierTagMultiselectExplanation supplierType={supplierType} />
        </label>
        <p>Use a comma, semicolon, or press Enter to separate organisations.</p>
        {!useSupplierName && enableNewSupplierFilter ? (
          <SupplierSignalSelect
            supplierType={supplierType}
            supplierGuids={supplierGuids}
            supplierCounts={supplierCounts}
            countries={countries}
            onSupplierGuidsChange={onSupplierGuidsChange}
          />
        ) : (
          <SupplierTagMultiselect
            supplierType={supplierType}
            id={`${supplierType}-multi-select`}
            countries={countries}
            supplierCounts={supplierCounts}
            supplierIds={supplierIds}
            supplierNames={supplierNames}
            selectionType={useSupplierName ? "NAME" : "ID"}
            onSupplierIdsChange={onSupplierIdsChange}
            onSupplierNamesChange={onSupplierNamesChange}
          />
        )}
      </>
    );
  } else {
    return (
      <PaywallMessage dataType="SUPPLIERS" benefit="to track competitor &amp; partner activity" />
    );
  }
}
