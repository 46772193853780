import React, { useState } from "react";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { App, Button, Input, Radio, Switch, Tooltip } from "antd5";

import { generateNoticeSearchUrl } from "components/notices/utils";
import CentredSpinner from "lib/core_components/CentredSpinner";
import { PersonalSubscriptionContent } from "lib/generated/app-api";
import { useDeleteLeadSubscriptionContent } from "lib/hooks/api/lead_subscriptions/useDeleteLeadSubscriptionContent";
import { useGetLeadSubscriptionContents } from "lib/hooks/api/lead_subscriptions/useGetLeadSubscriptionContents";
import { useUpdateLeadSubscriptionContent } from "lib/hooks/api/lead_subscriptions/useUpdateLeadSubscriptionContent";
import { useNotificationSettings } from "lib/hooks/api/notifications/useNotificationSettings";
import { useUpdateNotificationSettings } from "lib/hooks/api/notifications/useUpdateNotificationSettings";
import { ExternalLink } from "lib/icons/ExternalLink";
import { routes } from "lib/routes";
import { blue600, red600 } from "lib/themes/colors";

import css from "./EmailNotifications.module.scss";

import NotificationBell from "../../../../assets/images/notification_bell.svg";

type AlertDescriptions = {
  [key: string]: string;
};

const alertDescriptions: AlertDescriptions = {
  SavedView: "Notify me about new notices related to this view",
};

export function EmailNotifications() {
  return (
    <>
      <div className={css.tabTitle}>Email notifications</div>
      <div className={css.tabDescription}>Manage your email preferences and alerts.</div>
      <Mentions />
      <CustomAlerts />
    </>
  );
}

function Mentions() {
  const { message } = App.useApp();

  const { data, isLoading } = useNotificationSettings();

  const { mutate: updateNotificationSettings } = useUpdateNotificationSettings({
    onSuccess: () => message.success("Mentions updated successfully!"),
    onError: () => message.error("Failed to update mentions"),
  });

  return (
    <div className={css.card}>
      <div className={css.cardHeader}>
        <div>
          <div className={css.cardTitle}>Mentions</div>
          <div className={css.cardDescription}>
            Get an email every time someone mentions you in a comment or assigns a notice to you.
          </div>
        </div>
        <Switch
          loading={isLoading}
          checked={data?.sendAppActivityNotifications}
          onChange={(active) =>
            updateNotificationSettings({ sendAppActivityNotifications: active })
          }
        />
      </div>
    </div>
  );
}

type PersonalAlert = PersonalSubscriptionContent & {
  resourceName: string;
};

function CustomAlerts() {
  const [searchText, setSearchText] = useState("");
  const { modal, message } = App.useApp();

  const { data, isLoading } = useGetLeadSubscriptionContents();

  const { mutate: deleteAllAlerts, isLoading: isDeletingAllAlerts } =
    useDeleteLeadSubscriptionContent({
      onSuccess: () => message.success("Alerts deleted successfully!"),
      onError: () => message.error("Failed to delete alerts"),
    });

  const allPersonalAlerts: PersonalAlert[] = (data?.personalSubscriptions ?? [])
    // we no longer support RecordList subscriptions
    .filter((subscription) => subscription.resourceType !== "RecordList")
    .map((subscription) => ({ ...subscription, resourceName: subscription.resourceName ?? "-" }))
    .sort((a, b) => a.resourceName.localeCompare(b.resourceName));

  const allPersonalAlertIds = allPersonalAlerts.map((alert) => alert.id);

  const filteredAlerts = allPersonalAlerts.filter((alert) =>
    alert.resourceName.toLowerCase().includes(searchText.toLowerCase()),
  );

  return (
    <div className={css.card}>
      <div className={css.cardTitle}>Custom alerts</div>
      <CustomAlertsDescription />
      <div className={css.allAlertsActions}>
        <Input
          placeholder="Search alerts"
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          className={css.searchInput}
          prefix={<SearchOutlined style={{ color: blue600 }} />}
          allowClear
        />
        <Tooltip
          overlayStyle={{ maxWidth: "500px" }}
          title="Delete all alerts to stop receiving updates on new activity"
          placement="topRight"
        >
          <Button
            danger
            type="link"
            disabled={allPersonalAlerts.length === 0}
            onClick={() =>
              modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: "Are you sure you want to delete all alerts?",
                content:
                  "By doing this all alerts will be removed and you will not receive any updates on new notices appearing in your views. ",
                okText: "Delete all",
                okButtonProps: { loading: isDeletingAllAlerts, danger: true },
                onOk: () => {
                  deleteAllAlerts({ ids: allPersonalAlertIds });
                },
              })
            }
          >
            Delete all alerts
          </Button>
        </Tooltip>
      </div>
      <div className={css.customAlertsWrapper}>
        <AlertResultsContainer
          filteredAlerts={filteredAlerts}
          alertsPresent={allPersonalAlerts.length > 0}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

type AlertResultsContainerProps = {
  filteredAlerts: PersonalAlert[];
  alertsPresent: boolean;
  isLoading: boolean;
};

function AlertResultsContainer({
  filteredAlerts,
  alertsPresent,
  isLoading,
}: AlertResultsContainerProps) {
  if (isLoading) {
    return <CentredSpinner />;
  }

  if (!alertsPresent) {
    return (
      <div className={css.emptyState}>
        <img src={NotificationBell} />
        <div className={css.emptyStateTitle}>You currently have no alerts set</div>
        <div className={css.emptyStateDescription}>
          Create alerts on the views in your feed to get updates on new activity
        </div>
        <Button type="primary" href={routes.notices}>
          Go to feed
        </Button>
      </div>
    );
  }

  return (
    <>
      {filteredAlerts.map((alert) => (
        <CustomAlert alert={alert} key={alert.id} />
      ))}
    </>
  );
}

type CustomAlertProps = {
  alert: PersonalAlert;
};

function CustomAlert({ alert }: CustomAlertProps) {
  const { id, frequency } = alert;
  const { modal, message } = App.useApp();

  const { mutate: updateAlert } = useUpdateLeadSubscriptionContent({
    onSuccess: () => message.success("Alert updated successfully!"),
    onError: () => message.error("Failed to update alert"),
  });

  const { mutate: deleteAlert, isLoading: isDeletingAlert } = useDeleteLeadSubscriptionContent({
    onSuccess: () => message.success("Alert deleted successfully!"),
    onError: () => message.error("Failed to delete alert"),
  });

  const description = alertDescriptions[alert.resourceType];

  return (
    <div className={css.customAlert}>
      <div>
        <div className={css.alertName}>{alert.resourceName}</div>
        <div className={css.alertDescription}>{description}</div>
      </div>
      <div>
        <Radio.Group
          onChange={(e) => updateAlert({ id: alert.id, frequency: e.target.value })}
          defaultValue={frequency}
        >
          <Radio value="DAILY">Daily</Radio>
          <Radio value="WEEKLY">Weekly</Radio>
        </Radio.Group>
      </div>
      <div className={css.actionButtons}>
        <Button
          href={generateNoticeSearchUrl({}, alert.resourceId)}
          icon={<EyeOutlined style={{ color: blue600 }} />}
        >
          View
        </Button>
        <Button
          icon={<DeleteOutlined style={{ color: red600 }} />}
          onClick={() =>
            modal.confirm({
              icon: <ExclamationCircleOutlined />,
              title: "Are you sure you want to delete this alert?",
              content:
                "You will no longer receive emails and notifications about this alert. This action cannot be undone.",
              okText: "Delete",
              okButtonProps: { loading: isDeletingAlert },
              onOk: () => {
                deleteAlert({ ids: [id] });
              },
            })
          }
        >
          Delete Alert
        </Button>
      </div>
    </div>
  );
}

function CustomAlertsDescription() {
  return (
    <div>
      <span className={css.cardDescription}>
        Get notified about new activity set up in your alerts.
      </span>
      <a
        href="https://help.stotles.com/how-to-set-up-your-email-alerts"
        target="_blank"
        className={css.externalLink}
        rel="noreferrer"
      >
        {" "}
        View set up guidelines <ExternalLink fill={blue600} className={css.icon} />
      </a>
    </div>
  );
}
