import { useEffect, useState } from "react";

import { convertViewToNoticeFilters, useSavedViews } from "components/my_feed/useMyFeedPageState";
import { useFilterableNoticeTableState } from "components/notices/useFilterableNoticeTableState";
import { NoticeFilters } from "components/notices/utils";
import { NEW_HIGHLIGHT_LOGIC, useVariableValue } from "lib/featureFlags";
import {
  NoticeHighlightRequest,
  NoticeHighlightResponse,
} from "lib/generated/app-service-gql/graphql";
import { SupplierMentionType } from "lib/types/graphQLEnums";
import { useSignalSettingsGQL } from "../teams/useSignalSettingsGQL";
import { useNoticeHighlight } from "./useNoticeHighlight";

function convertNoticeFiltersToHighlightRequest(
  procStageId: string,
  filters: NoticeFilters | undefined,
): NoticeHighlightRequest {
  if (!filters) {
    return {
      noticeId: procStageId,
      orgIds: undefined,
      keywords: [],
      highlightSignals: false,
    };
  }
  return {
    noticeId: procStageId,
    // If supplier mention type is directly awarded, we don't want to highlight the suppliers
    orgIds:
      filters.supplierMentionType === SupplierMentionType.DirectlyAwarded
        ? undefined
        : filters.supplierGuids,
    keywords: [...filters.keywords, filters.text],
    highlightSignals: false,
  };
}

type NoticeHighlightingResponse = {
  highlightTerms: NoticeHighlightResponse | undefined;
  filters: NoticeFilters | undefined;
};
/**
 * Wrapper function for useNoticeHighlight. it gets the currently actioned
 * notice filters from the url params, converts that into a
 * {@link NoticeHighlightRequest} object to be used in the
 * {@link useNoticeHighlight} hook. Then hey presto we have highlights
 * @param procStageId procurement stage Id of the notice
 * @returns
 */
export function useNoticeHighlighting(procStageId: string): NoticeHighlightingResponse {
  const { noticeTableFilters } = useFilterableNoticeTableState();

  // Using a local state here so the url is not affected
  const [filters, setFilters] = useState<NoticeFilters | undefined>(noticeTableFilters);

  const isHighlightingEnabled = useVariableValue(NEW_HIGHLIGHT_LOGIC, false);

  const getFiltersFromSavedViews = !filters && isHighlightingEnabled;

  const { data: signals } = useSignalSettingsGQL(undefined, {
    enabled: getFiltersFromSavedViews,
  });

  const { selectedView } = useSavedViews(getFiltersFromSavedViews);

  // Use effect triggers if there are no filters to be read from url params
  // and instead gets params from saved view filters
  useEffect(() => {
    if (selectedView && !filters) {
      const savedViewFilters = convertViewToNoticeFilters(selectedView, true, signals);
      setFilters(savedViewFilters);
    }
  }, [filters, selectedView, setFilters, signals]);

  const highlightingFilters = convertNoticeFiltersToHighlightRequest(procStageId, filters);

  const { data: highlightTerms } = useNoticeHighlight(highlightingFilters, {
    enabled: isHighlightingEnabled && !!filters,
  });

  return { highlightTerms, filters };
}
