import { Theme } from "@emotion/react";

export const opportunitySavedCardContainer = (theme: Theme) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  width: "100%",
  padding: "16px 24px",

  cursor: "pointer",

  borderRadius: 4,
  border: `1px solid ${theme.colors.sysBorderPrimary}`,

  "&:hover": {
    border: `1px solid ${theme.colors.sysPrimaryDefault}`,
    background: theme.colors.sysPrimarySubtle,
  },

  transition: "all 0.2s ease-in-out",
});

export const opportunitySavedCardIcon = (theme: Theme) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  width: 24,
  height: 24,

  background: theme.colors.sysBackgroundAlternative,
  borderRadius: 4,
});
