import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { ClassNames } from "@emotion/react";
import { Input, Pagination } from "antd5";

import { useDebouncedValue } from "../../lib/debounce";
import {
  PaginationOptions,
  SearchOpportunitiesRequest,
  SearchOpportunitiesRequest_OrderByEnum_1,
  SearchOpportunitiesRequest_OrderEnum_1,
} from "../../lib/generated/app-service-gql/graphql";
import { useOpportunities } from "../../lib/hooks/api/opportunities/useOpportunities";
import { Flex } from "../../styles/utility-components";
import { OpportunitiesTable } from "./opportunities_table/OpportunitiesTable";

export function FilterableOpportunities() {
  const [filters, setFilters] = useState<SearchOpportunitiesRequest>({
    orderBy: SearchOpportunitiesRequest_OrderByEnum_1.CreatedAt,
    order: SearchOpportunitiesRequest_OrderEnum_1.Desc,
  });
  const [debouncedFilters] = useDebouncedValue(filters, 500);
  const [pagination, setPagination] = useState<PaginationOptions>({ page: 1, pageSize: 10 });
  const { data, isLoading, isError } = useOpportunities(debouncedFilters, pagination);

  return (
    <Flex column gap={16}>
      <ClassNames>
        {({ css }) => (
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            value={filters.searchText ?? ""}
            onChange={(e) => {
              setFilters({ ...filters, searchText: e.target.value });
            }}
            allowClear
            className={css({
              maxWidth: 350,
            })}
          />
        )}
      </ClassNames>
      <OpportunitiesTable opportunities={data?.results} isLoading={isLoading} isError={isError} />
      <Pagination
        current={pagination.page}
        pageSize={pagination.pageSize}
        onChange={(page, pageSize) => setPagination({ page, pageSize })}
        total={data?.total}
        showSizeChanger
        align="center"
      />
    </Flex>
  );
}
