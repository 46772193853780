import React, { useCallback, useState } from "react";
import { DeleteOutlined, EditOutlined, SearchOutlined, ShareAltOutlined } from "@ant-design/icons";
import { App, Button, Empty, Input, Modal, Tooltip } from "antd5";

import BookmarkIcon from "components/actions/Bookmark";
import { LimitedExportButton } from "components/actions/ExportButton";
import { BackToLink, TextLink } from "components/actions/Links";
import { DetailsHeader } from "components/app_layout/DetailsLayout";
import EditColumnsButton from "lib/core_components/EditColumnsButton";
import { createUseDebounce } from "lib/debounce";
import { BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters } from "lib/generated/app-api";
import { useBuyerList } from "lib/hooks/api/buyer_lists/useBuyerList";
import { useDeleteBuyerList } from "lib/hooks/api/buyer_lists/useDeleteBuyerList";
import { useRemoveBuyerEntries } from "lib/hooks/api/buyer_lists/useRemoveBuyerEntries";
import { useBuyerSignalStats } from "lib/hooks/api/useBuyerSignalStats";
import { useOpenApi } from "lib/openApiContext";
import { useDialogManager } from "lib/providers/DialogManager";
import { TypedColumnProps } from "lib/search/SearchTable";
import { PaginationState, SortState } from "lib/search/types";
import { EventDataTypes, EventNames, logEvent, TrackingProvider } from "lib/tracking";
import { BuyerColumn, ColumnSetting, TableSettings } from "lib/types/models";
import BulkSaveBuyer from "./BulkSaveBuyer";
import { BUYER_EXPORT_LIMIT } from "./BuyerFilters";
import BuyerListPermissionsModal, {
  BuyerListPermissionsModalProps,
} from "./BuyerListPermissionsModal";
import {
  BuyerListStats,
  BuyersTable,
  getBuyerListColumns,
  SkeletonBuyerListTable,
} from "./BuyersTable";
import RenameBuyerListModal, { RenameBuyerListModalProps } from "./RenameBuyerListModal";

import css from "./BuyerList.module.scss";

const DEFAULT_PAGE_SIZE = 20;

const useDebounceForSearch = createUseDebounce(400);

export const buyerListColumns: ColumnSetting<BuyerColumn>[] = [
  { title: "Buyer name", field: "buyer_name", disabled: true },
  { title: "Notices", field: "matching_records" },
  { title: "Open opportunities", field: "open_ops" },
  { title: "Contract expiries", field: "expiries" },
  { title: "My competitors", field: "competitors" },
  { title: "My partners", field: "partners" },
  { title: "Signals", field: "signals" },
];

export function BuyerList({ id }: { id: string }) {
  const { message } = App.useApp();
  const { data: list, isError, isLoading: isLoadingBuyerList } = useBuyerList(id);

  const dialogManager = useDialogManager();
  const openApi = useOpenApi();

  const [sortState, setSortState] = useState<SortState>({
    field: "matching_records",
    order: "DESC",
  });

  const [paginationState, setPaginationState] = useState<PaginationState>({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [selectedRowGuids, setSelectedRowGuids] = React.useState<string[]>([]);

  // This state is to keep track of column width and position which can be configured
  // with the edit column button in BuyerFilters component
  const [tableSettings, setTableSettings] = React.useState<TableSettings<BuyerColumn>>({
    columns: buyerListColumns,
  });

  const handleSortChange = useCallback((sortState: SortState) => {
    setSortState(sortState);
    setPaginationState((oldState) => ({ ...oldState, current: 1 }));
  }, []);

  const [filters, setFilters] =
    React.useState<BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters>({});

  const debouncedOnFiltersChange = useDebounceForSearch(
    (updateFilters: (oldValue: typeof filters) => typeof filters) => {
      setFilters(updateFilters);
    },
  );

  const [textSearch, setTextSearch] = React.useState<string>("");

  const onTextSearchChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // Keep the controlled value up to date
      // but for filters we apply a debounce so we don't search too often.
      const textSearch = e.target.value;
      setTextSearch(textSearch);
      debouncedOnFiltersChange((oldFilters) => ({ ...oldFilters, buyerName: textSearch }));
    },
    [debouncedOnFiltersChange],
  );

  const { mutate: deleteList } = useDeleteBuyerList({
    onSuccess: () => message.success("List successfully deleted!"),
    onError: () => message.error("Sorry, an error has occurred while deleting this list"),
  });

  const {
    data: statsResponse,
    isLoading: isLoadingStats,
    isLoadingError: isLoadingStatsError,
    isPreviousData, // For when we already have some data but are loading a different page, sort, etc.
  } = useBuyerSignalStats(
    {
      buyerListId: id || undefined,
      sort: sortState.field,
      sortOrder: sortState.order,
      offset: (paginationState.current - 1) * paginationState.pageSize,
      limit: paginationState.pageSize,
      filters: filters,
    },
    { enabled: !!list, keepPreviousData: true },
  );

  const { mutate: removeBuyers, isLoading: isRemoveLoading } = useRemoveBuyerEntries({
    onSuccess: (_data, variables, _context) => {
      logEvent(EventNames.buyerBulkRemove, {
        "Buyer GUIDs": selectedRowGuids,
        "Buyer list name": variables.buyerListName,
      });

      setSelectedRowGuids([]);
    },
    onError: () => {
      void message.error(
        `Failed to bulk remove buyers from ${
          list?.name ?? "buyer list"
        } , please contact an admin if this issue persists.`,
      );
    },
  });

  const columns = React.useMemo(
    () => getBuyerListColumns(sortState, tableSettings.columns),
    [sortState, tableSettings.columns],
  );

  const exportAction = React.useCallback(
    async (format: "xlsx" | "csv"): Promise<[string, Blob]> => {
      switch (format) {
        case "csv": {
          const response = await openApi.buyersRelationshipStatsCsv({
            buyersRelationshipStatsRequest: {
              buyerListId: id,
              filters: filters,
              buyerGuids: selectedRowGuids,
              limit: BUYER_EXPORT_LIMIT,
              offset: 0,
            },
          });
          return ["buyers_export", new Blob([response])];
        }
        case "xlsx": {
          const response = await openApi.buyersRelationshipStatsXlsx({
            buyersRelationshipStatsRequest: {
              buyerListId: id,
              filters: filters,
              buyerGuids: selectedRowGuids,
              limit: BUYER_EXPORT_LIMIT,
              offset: 0,
            },
          });
          return ["buyers_export", new Blob([response])];
        }
      }
    },
    [openApi, id, filters, selectedRowGuids],
  );

  return (
    <TrackingProvider data={{ "List id": id, "List name": list?.name }}>
      <DetailsHeader className={css.headerBar} borderBottom={false}>
        <div className={css.titleContainer}>
          <BackToLink to="/buyers/lists">Back to buyer lists</BackToLink>
          <h1 className={css.pageHeader}>{list?.name}</h1>
        </div>
        <div className={css.ctaContainer}>
          {list && (
            <>
              <Button
                onClick={() =>
                  Modal.confirm({
                    content: "Are you sure you want to delete this buyer list?",
                    onOk: () => {
                      deleteList({ id: list.id, name: list.name });
                      window.location.href = "/buyers/lists";
                    },
                  })
                }
                icon={<DeleteOutlined />}
                disabled={!list.permissionActions.includes("can_delete")}
              >
                Delete
              </Button>
              <Button
                onClick={() =>
                  dialogManager.openDialog(BuyerListPermissionsModal, {
                    list: list,
                  } as Omit<BuyerListPermissionsModalProps, "isOpen" | "onClose">)
                }
                icon={<ShareAltOutlined />}
                disabled={!list.permissionActions.includes("can_update")}
              >
                Share
              </Button>
              <Button
                onClick={() =>
                  dialogManager.openDialog(RenameBuyerListModal, {
                    list: list,
                  } as Omit<RenameBuyerListModalProps, "isOpen" | "onClose">)
                }
                icon={<EditOutlined />}
                disabled={!list.permissionActions.includes("can_update")}
              >
                Rename
              </Button>
            </>
          )}
        </div>
      </DetailsHeader>

      <div className={css.filterContainer}>
        {/* Search and edit columns are unecessary when there is an error */}
        {!(isError || isLoadingStatsError) && (
          <>
            <div>
              <Input
                placeholder="Search buyer name"
                onChange={onTextSearchChange}
                value={textSearch}
                className={css.searchInput}
                prefix={<SearchOutlined />}
                allowClear
              />
            </div>
            <div className={css.bulkActionBtns}>
              {selectedRowGuids.length > 0 ? (
                <>
                  <Tooltip
                    title={`Remove ${selectedRowGuids.length} buyers from ${list?.name ?? "list"}`}
                  >
                    <Button
                      loading={isRemoveLoading}
                      onClick={() =>
                        list &&
                        removeBuyers({
                          buyerListGuid: list.id,
                          buyerListName: list.name,
                          buyerGuids: selectedRowGuids,
                        })
                      }
                      icon={<BookmarkIcon colour="red" />}
                    >
                      Remove
                    </Button>
                  </Tooltip>
                  <BulkSaveBuyer buyerGuids={selectedRowGuids} />
                </>
              ) : (
                <EditColumnsButton
                  selectedColumns={tableSettings.columns}
                  onColumnSettingChange={setTableSettings}
                  allAvailableColumns={buyerListColumns}
                  dataType={EventDataTypes.buyer}
                />
              )}
              <LimitedExportButton
                exportAction={exportAction}
                exportLimit={BUYER_EXPORT_LIMIT}
                resultsCount={selectedRowGuids.length || statsResponse?.totalCount || 0}
                requiredDataType="BUYERS"
                exportedDataType={EventDataTypes.buyer}
              />
            </div>
          </>
        )}
      </div>
      <BuyerListPageContent
        isLoading={isLoadingBuyerList || (isLoadingStats && !statsResponse)}
        hasError={isError || isLoadingStatsError}
        stats={statsResponse ? (statsResponse.signalStats as BuyerListStats[]) : []}
        paginationState={paginationState}
        isLoadingPreviousData={isPreviousData}
        columns={columns}
        onSortChange={handleSortChange}
        onPaginationStateChange={setPaginationState}
        resultsCount={list?.entries.length || 0}
        selectedRowGuids={selectedRowGuids}
        setSelectedRowGuids={setSelectedRowGuids}
      />
    </TrackingProvider>
  );
}

type Props = {
  isLoading: boolean;
  hasError: boolean;
  stats: BuyerListStats[];
  paginationState: PaginationState;
  isLoadingPreviousData: boolean;
  resultsCount: number;
  columns: TypedColumnProps<BuyerListStats>[];
  onSortChange: (sort: SortState) => void;
  onPaginationStateChange: (newState: PaginationState) => void;
  selectedRowGuids: string[];
  setSelectedRowGuids: React.Dispatch<React.SetStateAction<string[]>>;
};

/**
 * Contains the logic used to determine whether to use a load state,
 * error state or display the buyer list table - depending on what the API returns
 * @param param0
 * @returns
 */
function BuyerListPageContent({
  isLoading,
  hasError,
  stats,
  paginationState,
  isLoadingPreviousData,
  resultsCount,
  columns,
  onSortChange,
  onPaginationStateChange,
  selectedRowGuids,
  setSelectedRowGuids,
}: Props) {
  if (isLoading) {
    return (
      <SkeletonBuyerListTable
        resultsCount={paginationState.pageSize}
        pageSize={paginationState.pageSize}
        columns={columns}
      />
    );
  }

  return (
    <TrackingProvider data={{ "Context source": "In-row" }}>
      <BuyersTable
        onSortChange={onSortChange}
        isLoading={isLoadingPreviousData}
        stats={stats}
        resultsCount={resultsCount}
        paginationState={paginationState}
        onPaginationStateChange={onPaginationStateChange}
        columns={columns}
        selectedRowGuids={selectedRowGuids}
        onSelectedRowsChange={setSelectedRowGuids}
        isError={hasError}
        emptyContent={
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={null}>
            <h2>No buyer lists yet</h2>
            <p className={css.noAccountsMessage}>
              When you save a buyer, they will appear here.
              <br /> You can save them directly from the{" "}
              <TextLink to="/buyers/search">buyer search</TextLink> or when viewing a buyer profile.
            </p>
          </Empty>
        }
      />
    </TrackingProvider>
  );
}
