import React, { ReactNode, useCallback, useState } from "react";
import { TooltipItem } from "chart.js";
import { DateTime } from "luxon";

import { Currency, Number } from "components/app_layout/Typography";

import css from "./SpendTooltip.module.scss";

type TooltipProps = {
  top: number;
  left: number;
  show: boolean;
  dataPoints?: TooltipItem<"line" | "bar">[];
  formatDataPointValue?: (dataPoint: TooltipItem<"line" | "bar">) => ReactNode;
  formatDataPointLabel?: (dataPoint: TooltipItem<"line" | "bar">) => ReactNode;
  clickMessage?: string;
  onClick?: (data: TooltipItem<"line" | "bar">[] | undefined) => void;
};

export function SpendTooltip({
  top,
  left,
  dataPoints,
  show,
  formatDataPointValue,
  formatDataPointLabel,
  clickMessage,
  onClick,
}: TooltipProps) {
  const [mouseEntered, setMouseEntered] = useState(false);

  const onMouseEnter = useCallback(() => {
    setMouseEntered(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setMouseEntered(false);
  }, []);

  if (!show && !mouseEntered) {
    return null;
  }

  return (
    <div
      className={onClick ? css.toolTipWithClick : css.toolTip}
      style={{
        top,
        left,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => onClick && onClick(dataPoints)}
    >
      <div className={css.toolTipContent}>
        <div className={css.toolTipTitle}>
          {dataPoints?.[0]?.parsed.x &&
            (formatDataPointLabel
              ? formatDataPointLabel(dataPoints[0])
              : DateTime.fromMillis(dataPoints[0].parsed.x).toFormat("Qq yyyy"))}
        </div>

        {dataPoints?.map((dataPoint, i) => (
          <div key={i} className={css.toolTipItem}>
            <span
              className={css.toolTipLine}
              style={{
                backgroundColor:
                  typeof dataPoint.dataset.borderColor === "string"
                    ? dataPoint.dataset.borderColor || dataPoint.dataset.borderColor
                    : typeof dataPoint.dataset.backgroundColor === "string"
                      ? dataPoint.dataset.backgroundColor
                      : "grey",
              }}
            />
            <span>
              <div>{dataPoint.dataset.label}</div>

              {formatDataPointValue ? (
                formatDataPointValue(dataPoint)
              ) : (
                <NumberDataPointValue dataPoint={dataPoint} />
              )}
            </span>
          </div>
        ))}
        <span className={css.clickMessage}>{clickMessage}</span>
      </div>
    </div>
  );
}

type NumberDataPointValueProps = {
  dataPoint: TooltipItem<"line" | "bar">;
  currency?: string;
};

export function NumberDataPointValue({ dataPoint, currency }: NumberDataPointValueProps) {
  if (currency) {
    return <Currency currency={currency} value={dataPoint.parsed.y} className={css.value} />;
  }

  return <Number shortFormat value={dataPoint.parsed.y} className={css.value} />;
}
