import * as React from "react";
import { useEffect, useState } from "react";

import { useBids } from "../../lib/hooks/api/bid/bid";
import { DocumentUploadPage } from "./DocumentUploadPage";
import { ScorecardPage } from "./ScorecardPage";

export function BidQualificationPage({
  noticeId,
  procurementStageId,
  noticeTitle,
  buyerName,
  portalUrl,
  bidId,
}: {
  noticeId: string;
  procurementStageId: string;
  noticeTitle: string;
  buyerName: string;
  portalUrl?: string;
  bidId?: string;
}) {
  const [currentPage, setCurrentPage] = useState<"UPLOAD" | "SCORECARD">(
    bidId ? "SCORECARD" : "UPLOAD",
  );
  const { data: bids, isLoading } = useBids({ procurementStageIds: [procurementStageId] });

  useEffect(() => {
    if (bids?.results && bids?.results?.length > 0) {
      setCurrentPage("SCORECARD");
    }
  }, [bids]);

  if (isLoading) {
    return null;
  }

  if (currentPage === "UPLOAD") {
    return (
      <DocumentUploadPage
        portalUrl={portalUrl}
        procurementStageId={procurementStageId}
        setCurrentPage={setCurrentPage}
      />
    );
  }

  return bids?.results?.[0] ? (
    <ScorecardPage
      noticeId={noticeId}
      bidId={bids.results[0].id}
      procurementStageId={procurementStageId}
      noticeTitle={noticeTitle}
      buyerName={buyerName}
    />
  ) : null;
}
